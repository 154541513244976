.banner {
  padding-top: 245px;
  padding-bottom: 215px;
  height: 100vh;
}
.banner img.bannerimg {
  position: absolute;
  top: 0;
  width: 100%;
}
.banner .container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: end;
  position: relative;
  z-index: 99;
}
.banner .container .banner-left {
  width: 100%;
  padding: 0 90px;
}
.banner .container .banner-left h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 50px;
  text-transform: capitalize;
  margin: 20px 0 15px;
  text-align: left;
}
.banner .container .banner-left h2 {
  font-size: 50px;
  font-weight: 400;
  line-height: 70px;
  text-transform: capitalize;
  margin: 20px 0 30px;
  text-align: left;
}
.banner .container .banner-left p {
  width: 50%;
  margin-bottom: 60px;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}
.banner .container .banner-left .button {
  color: var(--white);
  text-transform: capitalize;
  font-size: 20px;
  padding: 0px;
  font-weight: 800;
  cursor: pointer;
  transition: var(--transition);
  border: 0;
  box-shadow: none;
}
.banner .container .banner-left .button:hover {
  color: var(--secondary-color);
  background: transparent;
}
.banner .mobile-banner {
  display: none;
}

.content.introduction {
  padding-top: 90px;
  padding-bottom: 100px;
  background-position: center;
  position: relative;
}
.content.introduction .spacer-01 {
  min-height: 400px;
}
.content.introduction .container {
  position: relative;
}
.content.introduction .container .content-left {
  width: 55%;
}
.content.introduction .container .content-left p {
  font-weight: 200;
}
.content.introduction .container .content-left a:hover {
  color: var(--secondary-color) !important;
}
.content.introduction .container .mobile-show {
  display: none;
}

.content.our-story {
  padding-top: 150px;
  padding-bottom: 100px;
}
.content.our-story .container .content-left p {
  line-height: 45px;
  margin-top: 30px;
}
.content.our-story .container .content-left a {
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 30px;
  display: block;
  transition: var(--transition);
}
.content.our-story .container .content-left a:hover {
  color: var(--secondary-color);
}

.content.our-values {
  padding-top: 100px;
  padding-bottom: 100px;
}
.content.our-values .spacer-01 {
  margin-right: 50px;
}
.content.our-values .container .content-right p {
  line-height: 38px;
  margin-top: 30px;
}
.content.our-values .container .content-right a {
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 30px;
  display: block;
  transition: var(--transition);
}
.content.our-values .container .content-right a:hover {
  color: var(--secondary-color);
}

.call-to-action {
  background: var(--primary-color);
  padding: 150px 0;
}
.call-to-action .container {
  text-align: center;
}
.call-to-action .container .row {
  justify-content: center;
}
.call-to-action .container .row h3 {
  font-size: 50px;
  font-weight: 600;
}
.call-to-action .container .row h4 {
  font-size: 50px;
  font-weight: 300;
}
.call-to-action .container .row a {
  color: var(--white);
  transition: var(--transition);
  margin-top: 30px;
  font-size: 20px;
  width: auto;
}
.call-to-action .container .row a svg {
  margin-left: 15px;
}
.call-to-action .container .row a svg:hover {
  color: var(--secondary-color);
}
.call-to-action .container .row a:hover {
  color: var(--secondary-color);
}

.content.testimonials {
  padding-top: 70px;
  padding-bottom: 70px;
}
.content.testimonials .container .content-full {
  text-align: center;
}
.content.testimonials .container svg {
  font-size: 50px;
}
.content.testimonials .container h2 {
  text-align: center;
  border-bottom: 3px solid var(--primary-color);
  display: inline-block;
  padding: 0 15px 30px;
  margin-top: 40px;
}
.content.testimonials .container .custom-prev-arrow {
  position: absolute;
  top: 80px;
  left: 80px;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.content.testimonials .container .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}
.content.testimonials .container .custom-prev-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
}
.content.testimonials .container .custom-prev-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content.testimonials .container .custom-next-arrow {
  position: absolute;
  top: 80px;
  right: 80px;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.content.testimonials .container .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}
.content.testimonials .container .custom-next-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
}
.content.testimonials .container .custom-next-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content.testimonials .container .slick-list {
  max-width: 65%;
  margin: 0 auto;
}
.content.testimonials .container .slick-list .testimonial-data {
  margin-top: 40px;
  margin-bottom: 40px;
}
.content.testimonials .container .slick-list .iconbox {
  border-radius: 100px;
  width: 70px;
  height: 70px;
  margin: 0 auto 30px;
}
.content.testimonials .container .slick-list .testimonial-name {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 10px;
}
.content.testimonials .container .slick-list .testimonial-position {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.content .case-studies.container-fluid, .content .case-studies.container {
  padding: 80px 100px 80px;
}
.content .case-studies.container-fluid .content-left, .content .case-studies.container .content-left {
  width: 62%;
}
.content .case-studies.container-fluid .content-right, .content .case-studies.container .content-right {
  width: 38%;
}
.content .case-studies.container-fluid article.card, .content .case-studies.container article.card {
  background: transparent;
  border: 0;
}
.content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: unset;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 50px;
  right: -110px;
  transition: var(--transition);
}
.content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px #474576;
  border-radius: 100px;
  transition: var(--transition);
}
.content .case-studies.container-fluid .custom-prev-arrow:hover, .content .case-studies.container .custom-prev-arrow:hover {
  box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);
}
.content .case-studies.container-fluid .custom-prev-arrow:hover svg, .content .case-studies.container .custom-prev-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
  position: absolute;
  top: unset;
  right: -195px;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 50px;
  transition: var(--transition);
}
.content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px #474576;
  border-radius: 100px;
  transition: var(--transition);
}
.content .case-studies.container-fluid .custom-next-arrow:hover, .content .case-studies.container .custom-next-arrow:hover {
  box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);
}
.content .case-studies.container-fluid .custom-next-arrow:hover svg, .content .case-studies.container .custom-next-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
  margin: 0 auto;
  padding-bottom: 120px;
}
.content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
  background: var(--primary-color);
  border-radius: 30px;
  padding: 90px 55px 0px;
  margin: 60px 40px 0;
  box-shadow: inset -15px -15px 30px -20px var(--primary-box-shadow), -15px -15px 30px -20px var(--primary-box-shadow);
  transition: var(--transition);
  position: relative;
  overflow: hidden;
}
.content .case-studies.container-fluid .slick-list .slick-slide > div h4, .content .case-studies.container .slick-list .slick-slide > div h4 {
  font-size: 25px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}
.content .case-studies.container-fluid .slick-list .slick-slide > div p, .content .case-studies.container .slick-list .slick-slide > div p {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-weight: 500;
}
.content .case-studies.container-fluid .slick-list .slick-slide > div .link, .content .case-studies.container .slick-list .slick-slide > div .link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.content .case-studies.container-fluid .slick-list .slick-slide > div:hover, .content .case-studies.container .slick-list .slick-slide > div:hover {
  box-shadow: inset -15px -15px 30px -20px transparent, -5px -5px 20px 0px var(--primary-box-shadow);
}

.content .why-us.container-fluid {
  padding: 80px 100px 50px;
}
.content .why-us.container-fluid .spacer-01 {
  margin-right: 20px;
}
.content .why-us.container-fluid .content-left {
  width: 42%;
}
.content .why-us.container-fluid .content-right {
  width: 58%;
  padding: 0 0 0 80px;
}
.content .why-us.container-fluid .slick-slider {
  margin-top: 20px;
  margin-bottom: 70px;
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: 0;
  background: #d9edff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0;
  transition: var(--transition);
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
  color: #b6b6b6;
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: #cde6fc;
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow {
  position: absolute;
  top: unset;
  left: 80px;
  background: #d9edff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  transition: var(--transition);
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
  color: #b6b6b6;
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: #cde6fc;
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content .why-us.container-fluid .slick-slider .slick-list {
  margin: 0 auto;
  padding: 0 15px 115px;
}
.content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div {
  margin: 40px 40px 0 0;
  text-align: left;
}
.content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div img {
  margin: 0;
}
.content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div .iconbox {
  width: 65px;
  height: 65px;
}
.content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div h4 {
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
}
.content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div p {
  font-size: 20px;
  line-height: 35px;
  margin: 0;
  font-weight: 500;
}
.content .why-us.container-fluid a {
  padding-left: 180px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  color: var(--black);
  text-transform: capitalize;
  transition: var(--transition);
}
.content .why-us.container-fluid a:hover {
  color: var(--secondary-color);
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: 0;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0;
  transition: var(--transition);
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
  color: #b6b6b6;
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: #fff;
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow {
  position: absolute;
  top: unset;
  left: 80px;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  transition: var(--transition);
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
  color: #b6b6b6;
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: #fff;
}
.content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}

.services {
  padding: 80px 0 150px 0;
}
.services .slick-list {
  margin: 0 auto;
  display: flex;
}
.services .slick-list .slick-track {
  display: flex;
}
.services .slick-list .slick-track .slick-slide {
  display: flex;
}

.content.driven-by .container h3 span {
  font-weight: 700;
}
.content.driven-by .container .content-left {
  width: 60%;
}
.content.driven-by .container .content-right {
  width: 40%;
}
.content.driven-by .container .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: unset;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #919191;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  right: -110px;
  transition: var(--transition);
}
.content.driven-by .container .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px #474576;
  border-radius: 100px;
  transition: var(--transition);
}
.content.driven-by .container .custom-prev-arrow:hover {
  box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);
}
.content.driven-by .container .custom-prev-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.content.driven-by .container .custom-next-arrow {
  position: absolute;
  top: unset;
  right: -195px;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #919191;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  transition: var(--transition);
}
.content.driven-by .container .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px #474576;
  border-radius: 100px;
  transition: var(--transition);
}
.content.driven-by .container .custom-next-arrow:hover {
  box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);
}
.content.driven-by .container .custom-next-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.content.driven-by .container .slick-list {
  margin: 0 auto;
  display: flex;
  padding-bottom: 30px;
}
.content.driven-by .container .slick-list .slick-track {
  display: flex;
}
.content.driven-by .container .slick-list .slick-track .slick-slide {
  display: flex;
}
.content.driven-by .container .slick-list .slick-slide > div {
  border-radius: 30px;
  padding: 70px 55px 50px;
  margin: 10px 40px 40px;
  box-shadow: inset -5px -2px 30px 1px transparent, 20px 10px 50px -25px #c1c1c1;
  transition: var(--transition);
}
.content.driven-by .container .slick-list .slick-slide > div img {
  margin-bottom: 30px;
}
.content.driven-by .container .slick-list .slick-slide > div .iconbox {
  width: 65px;
  height: 65px;
  margin: 0 auto 20px;
}
.content.driven-by .container .slick-list .slick-slide > div h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.content.driven-by .container .slick-list .slick-slide > div p {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}
.content.driven-by .container .slick-list .slick-slide > div:hover {
  box-shadow: inset 8px 8px 30px -10px #9290a3, 0px 0px 0px 0px #c1c1c1;
}

@media (min-width: 1201px) and (max-width: 1600px) {
  .content .container h2, .content .container-fluid h2 {
    font-size: 40px;
    line-height: 45px;
  }
  .banner {
    padding-top: 100px;
    padding-bottom: 40px;
    height: 90vh;
    display: flex;
    margin-top: 0px;
  }
  .banner .container .banner-left {
    padding: 0 0px;
  }
  .banner .container .banner-left h1 {
    font-size: 40px;
    line-height: 45px;
    margin: 20px 0 5px;
  }
  .banner .container .banner-left h2 {
    font-size: 36px;
    line-height: 45px;
    margin: 0px 0 15px;
  }
  .banner .container .banner-left p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    max-width: 45%;
  }
  .banner .container .banner-left .button {
    font-size: 14px;
    font-weight: 600;
  }
  .content.introduction {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .content.introduction .container {
    position: relative;
  }
  .content.introduction .container .content-left {
    width: 55%;
  }
  .content.introduction .container .content-left h3 {
    margin-bottom: 0;
  }
  .content.introduction .container .content-left p {
    font-size: 17px;
  }
  .content .container h2 {
    font-size: 36px;
    line-height: 40px;
  }
  .call-to-action {
    padding: 110px 0;
  }
  .call-to-action .container .row h3 {
    font-size: 40px;
  }
  .call-to-action .container .row h4 {
    font-size: 40px;
  }
  .call-to-action .container .row a {
    margin-top: 20px;
    font-size: 18px;
  }
  .call-to-action .container .row a svg {
    margin-left: 10px;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 25px;
    margin-top: 40px;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: 80px;
    left: 60px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: 80px;
    right: 60px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .slick-list {
    max-width: 65%;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 30px 50px 80px;
  }
  .content .case-studies.container-fluid .content-left, .content .case-studies.container .content-left {
    width: 62%;
  }
  .content .case-studies.container-fluid .content-right, .content .case-studies.container .content-right {
    width: 38%;
  }
  .content .case-studies.container-fluid .content-right p, .content .case-studies.container .content-right p {
    margin-top: 30px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 10px;
    right: -110px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 10px;
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
    padding-bottom: 50px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    padding: 50px 20px 0px;
    margin: 40px 20px 0;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div h4, .content .case-studies.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div p, .content .case-studies.container .slick-list .slick-slide > div p {
    font-size: 14px;
    line-height: 26px;
  }
  .content .why-us.container-fluid {
    padding: 0px 50px 0px;
  }
  .content .why-us.container-fluid .content-left {
    width: 42%;
  }
  .content .why-us.container-fluid .content-right {
    width: 58%;
    padding: 0 0 0 20px;
  }
  .content .why-us.container-fluid .slick-slider {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div {
    margin: 40px 40px 0 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div img {
    margin: 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div p {
    font-size: 14px;
    line-height: 26px;
  }
  .content .why-us.container-fluid a {
    padding-left: 150px !important;
    font-size: 15px;
    line-height: 24px;
    top: -35px !important;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.our-values {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-values .container .content-right p {
    line-height: 35px;
    margin-top: 30px;
  }
  .content.our-values .container .content-right a {
    margin-top: 30px;
  }
  .content.our-story {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-story .container .content-left .spacer200 {
    height: 100px;
  }
  .content.our-story .container .content-left p {
    line-height: 35px;
    margin-top: 30px;
  }
  .content.our-story .container .content-left a {
    margin-top: 30px;
  }
  .content.driven-by .container .content-left {
    width: 60%;
  }
  .content.driven-by .container .content-right {
    width: 40%;
  }
  .content.driven-by .container .content-right h2 {
    font-size: 38px;
  }
  .content.driven-by .container .content-right p {
    margin-top: 20px;
  }
  .content.driven-by .container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: -20px;
    right: -110px;
  }
  .content.driven-by .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 14px;
  }
  .content.driven-by .container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: -20px;
  }
  .content.driven-by .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 14px;
  }
  .content.driven-by .container .slick-list .slick-slide > div {
    border-radius: 30px;
    padding: 50px 20px 0px;
    margin: 10px 20px 40px;
    margin: 10px 20px 40px;
    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
  }
  .content.driven-by .container .slick-list .slick-slide > div img {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
  }
  .content.driven-by .container .slick-list .slick-slide > div h4 {
    font-size: 18px;
  }
  .content.driven-by .container .slick-list .slick-slide > div p {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .banner {
    padding-top: 150px;
    padding-bottom: 60px;
    height: 70vh;
    display: flex;
  }
  .banner .container .banner-left {
    padding: 0px;
    width: 80%;
  }
  .banner .container .banner-left h1 {
    font-size: 40px;
    line-height: 45px;
    margin: 20px 0 5px;
  }
  .banner .container .banner-left h2 {
    font-size: 36px;
    line-height: 45px;
    margin: 0px 0 15px;
  }
  .banner .container .banner-left p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 32px;
    width: 100%;
  }
  .banner .container .banner-left .button {
    font-size: 14px;
    font-weight: 600;
  }
  .content.introduction {
    padding-top: 90px;
    padding-bottom: 100px;
    background-position: center right;
  }
  .content.introduction .container {
    position: relative;
  }
  .content.introduction .container .content-left {
    width: 55%;
  }
  .content.introduction .container .content-left p {
    font-weight: 200;
  }
  .content.introduction .container .mobile-show {
    display: none;
  }
  .content.introduction .container .mobile-show img {
    width: 100%;
    height: auto;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 20px;
    margin-top: 30px;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: 80px;
    left: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: 80px;
    right: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .slick-list {
    max-width: 80%;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .call-to-action {
    padding: 110px 0;
  }
  .call-to-action .container .row h3 {
    font-size: 36px;
  }
  .call-to-action .container .row h4 {
    font-size: 36px;
  }
  .call-to-action .container .row a {
    margin-top: 20px;
    font-size: 18px;
  }
  .call-to-action .container .row a svg {
    margin-left: 10px;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 30px 40px 100px;
  }
  .content .case-studies.container-fluid .content-left, .content .case-studies.container .content-left {
    width: 62%;
  }
  .content .case-studies.container-fluid .content-right, .content .case-studies.container .content-right {
    width: 38%;
  }
  .content .case-studies.container-fluid .content-right p, .content .case-studies.container .content-right p {
    margin-top: 30px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 50px;
    right: -110px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 50px;
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
    padding-bottom: 40px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    padding: 30px 20px 0px;
    margin: 40px 15px 0;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div h4, .content .case-studies.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin: 20px 0;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div p, .content .case-studies.container .slick-list .slick-slide > div p {
    font-size: 16px;
    line-height: 30px;
  }
  .content .why-us.container-fluid {
    padding: 80px 40px 50px;
  }
  .content .why-us.container-fluid .content-left {
    width: 42%;
  }
  .content .why-us.container-fluid .content-right {
    width: 58%;
    padding: 0 0 0 20px;
  }
  .content .why-us.container-fluid .slick-slider {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div {
    margin: 40px 40px 0 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div img {
    margin: 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin: 20px 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div p {
    font-size: 16px;
    line-height: 30px;
  }
  .content .why-us.container-fluid a {
    padding-left: 150px !important;
    font-size: 18px;
    line-height: 30px;
    position: relative;
    top: -35px !important;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.our-values {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-values .container .pr-60 {
    padding-right: 20px !important;
  }
  .content.our-values .container .content-right p {
    line-height: 35px;
  }
  .content.our-values .container .content-right a {
    margin-top: 30px;
  }
  .content.our-story {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .content.our-story .container .content-left .spacer200 {
    height: 100px;
  }
  .content.our-story .container .content-left p {
    line-height: 35px;
    margin-top: 30px;
  }
  .content.our-story .container .content-left a {
    margin-top: 30px;
  }
  .content.our-story .container .content-right .pl-60 {
    padding-left: 20px !important;
  }
  .content.driven-by .container .content-left {
    width: 58%;
  }
  .content.driven-by .container .content-right {
    width: 42%;
  }
  .content.driven-by .container .content-right h2 {
    font-size: 34px;
  }
  .content.driven-by .container .content-right p {
    margin-top: 20px;
  }
  .content.driven-by .container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
    right: -110px;
  }
  .content.driven-by .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.driven-by .container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content.driven-by .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.driven-by .container .slick-list .slick-slide > div {
    border-radius: 30px;
    padding: 50px 20px 40px;
    margin: 10px 20px 40px;
    margin: 10px 20px 40px;
    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
  }
  .content.driven-by .container .slick-list .slick-slide > div img {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
  }
  .content.driven-by .container .slick-list .slick-slide > div h4 {
    font-size: 18px;
  }
  .content.driven-by .container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 27px;
  }
}
@media screen and (max-width: 1024px) {
  .banner {
    position: relative;
  }
  .banner .container {
    gap: 50px;
    flex-direction: column-reverse;
    position: relative;
    z-index: 9;
  }
  .banner .container .banner-left {
    text-align: left;
    width: 100%;
    padding: 0 0px;
  }
  .banner .container .banner-left h1 {
    font-size: 36px;
    line-height: 45px;
    margin: 20px 0 10px;
  }
  .banner .container .banner-left h2 {
    font-size: 36px;
    line-height: 40px;
    margin: 20px 0 30px;
  }
  .banner .container .banner-left p {
    text-align: left;
    margin-bottom: 40px;
  }
  .banner .container .banner-left p br {
    display: none;
  }
  .banner .container .banner-left a.button {
    font-size: 16px;
  }
  .banner .container .banner-right {
    text-align: center;
    width: 100%;
  }
  .content.introduction {
    padding-top: 90px;
    padding-bottom: 100px;
    background-color: var(--primary-color);
    background-size: contain !important;
  }
  .content.introduction .container {
    position: relative;
  }
  .content.introduction .container .content-left {
    width: 55%;
  }
  .content.introduction .container .content-left p {
    font-weight: 200;
  }
  .content.introduction .container .mobile-show {
    display: none;
  }
  .content.introduction .container .mobile-show img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 20px;
    margin-top: 30px;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: 80px;
    left: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: 80px;
    right: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .slick-list {
    max-width: 80%;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .call-to-action {
    padding: 110px 0;
  }
  .call-to-action .container .row h3 {
    font-size: 30px;
  }
  .call-to-action .container .row h4 {
    font-size: 30px;
  }
  .call-to-action .container .row a {
    margin-top: 20px;
    font-size: 18px;
  }
  .call-to-action .container .row a svg {
    margin-left: 10px;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 0px 40px 30px;
  }
  .content .case-studies.container-fluid .content-left, .content .case-studies.container .content-left {
    width: 50%;
  }
  .content .case-studies.container-fluid .content-right, .content .case-studies.container .content-right {
    width: 50%;
  }
  .content .case-studies.container-fluid .content-right p, .content .case-studies.container .content-right p {
    margin-top: 30px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 20px;
    right: -110px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 20px;
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
    padding-bottom: 50px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    padding: 40px 20px 10px;
    margin: 40px 20px 0;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div h4, .content .case-studies.container .slick-list .slick-slide > div h4 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div p, .content .case-studies.container .slick-list .slick-slide > div p {
    font-size: 16px;
    line-height: 30px;
  }
  .content .why-us.container-fluid {
    padding: 40px 40px 50px;
  }
  .content .why-us.container-fluid .content-left {
    width: 50%;
  }
  .content .why-us.container-fluid .content-right {
    width: 50%;
    padding: 0 0 0 20px;
  }
  .content .why-us.container-fluid .slick-slider {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div {
    margin: 40px 40px 0 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div img {
    margin: 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .why-us.container-fluid a {
    padding-left: 140px !important;
    font-size: 16px;
    line-height: 30px;
    position: relative;
    top: -40px !important;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow {
    top: unset;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow {
    top: unset;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.our-values {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-values .container .pr-60 {
    padding-right: 0px !important;
  }
  .content.our-values .container .content-left img {
    height: auto;
  }
  .content.our-values .container .content-right p {
    line-height: 35px;
  }
  .content.our-values .container .content-right a {
    margin-top: 30px;
  }
  .content.our-story {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .content.our-story .container .content-left .spacer200 {
    height: 100px;
  }
  .content.our-story .container .content-right .pl-60 {
    padding-left: 0px !important;
  }
  .content.our-story .container .content-right img {
    height: auto;
  }
  .content.driven-by .container .content-left {
    width: 50%;
  }
  .content.driven-by .container .content-right {
    width: 50%;
  }
  .content.driven-by .container .content-right p {
    margin-top: 20px;
  }
  .content.driven-by .container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
    right: -110px;
  }
  .content.driven-by .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.driven-by .container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content.driven-by .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.driven-by .container .slick-list .slick-slide > div {
    border-radius: 30px;
    padding: 50px 20px 40px;
    margin: 10px 20px 40px;
    margin: 10px 20px 40px;
    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
  }
  .content.driven-by .container .slick-list .slick-slide > div img {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
  }
  .content.driven-by .container .slick-list .slick-slide > div h4 {
    font-size: 18px;
  }
  .content.driven-by .container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 27px;
  }
}
@media (min-width: 993px) and (max-width: 1024px) {
  .banner {
    padding-top: 150px;
    padding-bottom: 60px;
    height: auto;
  }
  .banner .container {
    gap: 50px;
  }
  .banner .container .banner-left {
    padding: 0 0px;
  }
  .banner .container .banner-left h1 {
    font-size: 30px;
    line-height: 36px;
    margin: 20px 0 0px;
  }
  .banner .container .banner-left h2 {
    font-size: 30px;
    line-height: 35px;
    margin: 10px 0 15px;
  }
  .banner .container .banner-left p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
  }
  .banner .container .banner-left a.button {
    font-size: 16px;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 20px;
    margin-top: 30px;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: 80px;
    left: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: 80px;
    right: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .slick-list {
    max-width: 80%;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 17px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15x;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
}
@media (max-width: 992px) {
  .banner {
    padding-top: 140px;
    padding-bottom: 60px;
    height: auto;
    min-height: 300px;
  }
  .banner:before {
    content: "";
    background: var(--primary-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 9;
  }
  .banner img.bannerimg {
    display: none !important;
  }
  .banner .container {
    gap: 50px;
  }
  .banner .container .banner-left {
    padding: 0 0px;
  }
  .banner .container .banner-left h1 {
    font-size: 30px;
    line-height: 35px;
    margin: 20px 0 10px;
  }
  .banner .container .banner-left h2 {
    font-size: 30px;
    line-height: 35px;
    margin: 0px 0 30px;
  }
  .banner .container .banner-left p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
    width: 100%;
  }
  .banner .container .banner-left a.button {
    font-size: 16px;
  }
  section {
    padding: 50px 0;
  }
  .content.introduction {
    padding-top: 50px;
    padding-bottom: 30px;
    background-image: none !important;
  }
  .content.introduction .container .content-left {
    width: 55%;
  }
  .content.introduction .container .mobile-show {
    display: block;
  }
  .content.introduction .container .mobile-show img {
    height: auto;
    aspect-ratio: 16/9;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 20px;
    margin-top: 30px;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: 80px;
    left: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: 80px;
    right: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.testimonials .container .slick-list {
    max-width: 80%;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 32px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .call-to-action {
    padding: 110px 0;
  }
  .call-to-action .container .row h3 {
    font-size: 28px;
  }
  .call-to-action .container .row h4 {
    font-size: 28px;
  }
  .call-to-action .container .row a {
    margin-top: 20px;
    font-size: 18px;
  }
  .call-to-action .container .row a svg {
    margin-left: 10px;
  }
  .content.our-values {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-values .container .pr-60 {
    padding-right: 20px !important;
  }
  .content.our-values .container .content-right p {
    line-height: 35px;
  }
  .content.our-values .container .content-right a {
    margin-top: 30px;
  }
  .content.our-story {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-story .container .content-left .spacer200 {
    height: 80px;
  }
  .content.driven-by .container .content-left {
    width: 50%;
  }
  .content.driven-by .container .content-right {
    width: 50%;
  }
  .content.driven-by .container .content-right p {
    margin-top: 20px;
    line-height: 28px;
  }
  .content.driven-by .container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
    right: -110px;
  }
  .content.driven-by .container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
    position: relative;
    top: -3px;
  }
  .content.driven-by .container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 0px;
  }
  .content.driven-by .container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
    position: relative;
    top: -3px;
  }
  .content.driven-by .container .slick-list .slick-slide > div {
    border-radius: 30px;
    padding: 50px 20px 40px;
    margin: 10px 20px 40px;
    margin: 10px 20px 40px;
    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
  }
  .content.driven-by .container .slick-list .slick-slide > div img {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
  }
  .content.driven-by .container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 27px;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 30px 0px 30px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 50px;
    right: -110px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: -175px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    bottom: 50px;
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    margin: 40px 20px 0;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div h4, .content .case-studies.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div p, .content .case-studies.container .slick-list .slick-slide > div p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .content .container .content-left, .content .container .content-right {
    padding: 0px !important;
  }
  .h-200 {
    height: auto;
    overflow-y: hidden;
    direction: ltr;
  }
  .banner {
    padding-top: 120px;
    padding-bottom: 70px;
    background-image: none !important;
    background: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
  .banner .container .banner-left {
    text-align: center;
  }
  .banner .container .banner-left h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0;
    text-align: center;
  }
  .banner .container .banner-left h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0 30px;
    text-align: center;
  }
  .banner .container .banner-left p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
  }
  .banner .container .banner-left .button {
    font-size: 18px;
  }
  .banner .mobile-banner {
    padding-top: 30px;
    display: none;
    width: 100%;
  }
  .content.introduction {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .content.introduction:before {
    display: none;
  }
  .content.introduction .container {
    flex-direction: column-reverse;
  }
  .content.introduction .container .content-left, .content.introduction .container .content-right {
    width: 100%;
    padding: 0;
  }
  .content.introduction .container .content-left p, .content.introduction .container .content-right p {
    text-align: center;
  }
  .content.introduction .container .content-left img, .content.introduction .container .content-right img {
    margin: 0 auto;
    height: auto;
    aspect-ratio: 16/9;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .content.introduction .container .content-left .h-200, .content.introduction .container .content-right .h-200 {
    height: auto;
    overflow: hidden;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    width: auto;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 20px 0px 60px;
    flex-direction: column-reverse;
  }
  .content .case-studies.container-fluid .content-left, .content .case-studies.container .content-left {
    width: 100%;
  }
  .content .case-studies.container-fluid .content-right, .content .case-studies.container .content-right {
    width: 100%;
  }
  .content .case-studies.container-fluid .content-right p, .content .case-studies.container .content-right p {
    text-align: center;
  }
  .content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
    padding-bottom: 50px;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .why-us.container-fluid {
    flex-direction: column;
    padding: 40px 0px 0px;
  }
  .content .why-us.container-fluid .content-left {
    width: 100%;
  }
  .content .why-us.container-fluid .content-right {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    margin-top: 20px;
  }
  .content .why-us.container-fluid .slick-slider {
    margin-bottom: 20px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list {
    padding-bottom: 60px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div {
    text-align: center;
    margin: 20px 15px 0;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div p, .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div img {
    text-align: center;
    margin: auto;
  }
  .content .why-us.container-fluid .slick-slider .slick-list .slick-slide > div .iconbox {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content .why-us.container-fluid.home-whyus .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content.testimonials .container .content-full {
    padding: 0;
  }
  .content.testimonials .container svg {
    font-size: 40px;
  }
  .content.testimonials .container h2 {
    padding: 0 15px 20px;
    margin: 30px 0;
  }
  .content.testimonials .container .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.testimonials .container .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.testimonials .container .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.testimonials .container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.testimonials .container .slick-list {
    max-width: 100%;
    padding-bottom: 60px;
  }
  .content.testimonials .container .slick-list .testimonial-data {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 32px;
  }
  .content.testimonials .container .slick-list .iconbox {
    width: 65px;
    height: 65px;
    margin-bottom: 15px;
  }
  .content.testimonials .container .slick-list .testimonial-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .content.testimonials .container .slick-list .testimonial-position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .content .why-us.container-fluid a {
    padding-left: 0 !important;
    font-size: 15px;
    line-height: 30px;
    top: 0 !important;
  }
  .content .why-us.container-fluid .content-left img {
    max-width: 50%;
  }
  .call-to-action {
    padding: 60px 0;
  }
  .call-to-action .container .row h3 {
    font-size: 24px;
  }
  .call-to-action .container .row h4 {
    font-size: 24px;
  }
  .call-to-action .container .row a {
    margin-top: 20px;
    font-size: 16px;
  }
  .call-to-action .container .row a svg {
    margin-left: 10px;
  }
  .content.our-values {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-values .container {
    flex-direction: column;
  }
  .content.our-values .container .pr-60 {
    padding-right: 0px !important;
  }
  .content.our-values .container .content-left img {
    max-width: 50%;
  }
  .content.our-values .container .content-right p {
    line-height: 35px;
  }
  .content.our-values .container .content-right a {
    margin-top: 30px;
    text-align: center;
  }
  .content.our-story {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .content.our-story .container {
    flex-direction: column-reverse;
  }
  .content.our-story .container .content-left .spacer200 {
    height: 80px;
  }
  .content.our-story .container .content-left p {
    line-height: 28px;
    font-size: 17px;
  }
  .content.our-story .container .content-left a {
    text-align: center;
  }
  .content.our-story .container .content-right img {
    max-width: 50%;
    margin-bottom: 20px;
  }
  .content.driven-by .container {
    flex-direction: column-reverse;
  }
  .content.driven-by .container .content-left {
    width: 100%;
  }
  .content.driven-by .container .content-right {
    width: 100%;
  }
  .content.driven-by .container .content-right p {
    margin-top: 20px;
    text-align: center;
  }
  .content.driven-by .container .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.driven-by .container .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.driven-by .container .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.driven-by .container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.driven-by .container .slick-list {
    padding-bottom: 70px !important;
  }
  .content.driven-by .container .slick-list .slick-slide > div {
    border-radius: 30px;
    padding: 0px;
    margin: 0px;
    box-shadow: unset;
  }
  .content.driven-by .container .slick-list .slick-slide > div img {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
  }
  .content.driven-by .container .slick-list .slick-slide > div h4 {
    font-size: 17px;
  }
  .content.driven-by .container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 27px;
  }
}
@media (max-width: 600px) {
  .banner {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .banner .container {
    gap: 50px;
  }
  .banner .container .banner-left {
    padding: 0;
  }
  .banner .container .banner-left h1, .banner .container .banner-left h2 {
    font-size: 30px;
    line-height: 35px;
    margin: 20px 0 10px;
  }
  .banner .mobile-banner {
    padding-top: 30px;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .content.introduction .container .content-left img, .content.introduction .container .content-right img {
    max-width: 85%;
    height: auto;
    aspect-ratio: 16/9;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .content .why-us.container-fluid .content-left img {
    max-width: 70%;
  }
  .content.our-values {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .content.our-values .container .content-left img {
    max-width: 70%;
  }
  .content.our-values .container .content-right p {
    line-height: 30px;
  }
  .content.our-story {
    padding-top: 50px !important;
  }
  .content.our-story .container .content-right img {
    max-width: 70%;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    margin: 40px 10px 0;
  }
  .content.testimonials .container svg {
    font-size: 30px;
  }
}
.scrolling-area {
  max-width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  padding: 1rem;
  background: white;
  direction: rtl;
}

.scrolling-element-inside {
  direction: ltr;
}

.scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #000 #bbb;
}
.scrollbar-2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar-2::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 33%, rgb(181, 181, 181) 33%, rgb(181, 181, 181) 66%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
.scrollbar-2::-webkit-scrollbar-thumb {
  background-color: #000;
}
.scrollbar-2::-webkit-scrollbar-track, .scrollbar-2::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.scrollbar-3 {
  scrollbar-width: thin;
  scrollbar-color: #fff #ccc;
}
.scrollbar-3::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar-3::-webkit-scrollbar-track {
  background: rgb(6, 3, 50);
  background: linear-gradient(90deg, rgb(6, 3, 50) 0%, rgb(6, 3, 50) 33%, rgb(255, 255, 255) 33%, rgb(255, 255, 255) 66%, rgb(6, 3, 50) 66%, rgb(6, 3, 50) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060332",endColorstr="#060332",GradientType=1);
}
.scrollbar-3::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.scrollbar-3::-webkit-scrollbar-track, .scrollbar-3::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

[class^=scrollbar] {
  height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
  direction: rtl;
}
[class^=scrollbar] div {
  width: 100%;
  padding: 0 0 0 0px;
  direction: ltr;
}

/* ////////////animation starts/////////////// */
.animate {
  opacity: 0;
  animation: fadeIn 0.8s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ////////animation ends////////////// *//*# sourceMappingURL=home.css.map */