footer {
    background: var(--primary-color);
    padding: 50px 0 0;
    .container {
        display: flex;
        gap: 20px;
        justify-content: space-around;
        .row {
            .col {
                width: 300px;
                position: relative;
                &.addresss {
                    &:after {
                        content: "";
                        height: 80%;
                        width: 3px;
                        background: #fff;
                        position: absolute;
                        right: 25px;
                        bottom: 0;
                        transform: translate(-50%, 0%);
                    }
                }
                &.address {
                    .address-wrapper {
                        display: flex;
                        svg {
                            color: var(--white);
                            font-size: 20px;
                            margin-top: 8px;
                            margin-right: 2px;
                        }
                    }
                }
            }
            h4 {
                color: var(--white);
                text-transform: uppercase;
                font-size: 24px;
                margin: 0 0 15px 40px;
                font-weight: 600;
            }
            a {
                color: var(--white);
                transition: var(--transition);
                &:hover {
                    color: var(--secondary-color);
                }
            }
            ul {
                padding: 0;
                li {
                    a {
                        line-height: 40px;
                        display: inline-block;
                        font-size: 18px;

                        span {
                            color: var(--black);
                            font-size: 15px;
                            background: var(--white);
                            border-radius: 100px;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            display: inline-block;
                            transition: var(--transition);
                        }
                        &:hover {
                            span {
                                background: var(--primary-color);
                                color: var(--white);
                            }
                        }
                    }
                }
            }
            p {
                color: var(--white);
                font-size: 18px;
                margin-bottom: 0;
            }
        }

        &.copyright {
            margin-top: 50px;
            .col {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color: var(--white);
                    border-top: 1px solid var(--card-border);
                    padding-top: 15px;
                    margin-bottom: 30px;
                    font-size: 18px;
                    display: flex;

                    b {
                        font-weight: 400;
                        margin: 0 15px;
    
                        a {
                            color: var(--white);
                            transition: var(--transition);

                            &:hover {
                                color: var(--secondary-color);
                            }
                        }
                    }
                    ul {
                        display: inline-flex;
                        padding: 0;
                        gap: 5px;

                        li {
                            a {
                                line-height: 20px;
                                font-size: 0;
                                
                                span {
                                    color: var(--white);
                                    display: inline-block;
                                    font-size: 15px;
                                    height: 20px;
                                    line-height: 20px;
                                    text-align: center;
                                    transition: var(--transition);
                                    width: 25px;
                                    img {
                                        border-radius: 5px;
                                    }
                                }
                                &:hover {
                                    span {
                                        color: var(--secondary-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {}

@media (min-width: 1201px) and (max-width: 1600px) {
    footer {
        padding: 50px 0 0;
        .container {
            gap: 20px;
            .row {
                .col {
                    width: 250px;
                    &.addresss {
                        &:after {
                        }
                    }
                }
                h4 {
                    font-size: 24px;
                    margin: 0 0 15px 40px;
                }
                a {
                    color: var(--white);
                }
                ul {
                    li {
                        a {
                            line-height: 32px;
                            font-size: 17px;
    
                            span {
                                font-size: 15px;
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                p {
                    font-size: 17px;
                    line-height: 32px;
                }
            }
            
            &.copyright {
                margin-top: 50px;
                .col {
    
                    p {
                        padding-top: 15px;
                        margin-bottom: 30px;
                        font-size: 17px;
    
                        b {
                            margin: 0 15px;
                        }
                        ul {
                            gap: 17px;
    
                            li {
                                a {
                                    line-height: 20px;
                                    font-size: 0;
                                    
                                    span {
                                        font-size: 15px;
                                        width: 30px;
                                        height: 30px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    footer {
        padding: 50px 0 0;
        .container {
            gap: 20px;
            .row {
                .col {
                    width: 250px;
                    &.address {
                        &:after {
                        }
                    }
                }
                h4 {
                    font-size: 24px;
                    margin: 0 0 15px 40px;
                }
                a {
                    color: var(--white);
                }
                ul {
                    li {
                        a {
                            line-height: 35px;
                            font-size: 17px;
    
                            span {
                                font-size: 15px;
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                p {
                    font-size: 17px;
                    line-height: 30px;
                }
            }
            
            &.copyright {
                margin-top: 50px;
                .col {
    
                    p {
                        padding-top: 15px;
                        margin-bottom: 30px;
                        font-size: 16px;
    
                        b {
                            margin: 0 15px;
                        }
                        ul {
                            gap: 5px;
    
                            li {
                                a {
                                    line-height: 20px;
                                    font-size: 0;
                                    
                                    span {
                                        font-size: 15px;
                                        width: 30px;
                                        height: 30px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    footer {
        padding: 50px 0 0;
        .container {
            gap: 20px;
            .row {
                .col {
                    width: 250px;
                    &.address {
                        &:after {
                        }
                    }
                }
                h4 {
                    font-size: 24px;
                    margin: 0 0 15px 40px;
                }
                a {
                    color: var(--white);
                }
                ul {
                    li {
                        a {
                            line-height: 35px;
                            font-size: 17px;
    
                            span {
                                font-size: 15px;
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                p {
                    font-size: 17px;
                    line-height: 30px;
                }
            }
            
            &.copyright {
                margin-top: 50px;
                .col {
    
                    p {
                        padding-top: 15px;
                        margin-bottom: 30px;
                        font-size: 16px;
                        flex-direction: column;
                        display: flex;
                        width: 100%;
                        text-align: center;
    
                        b {
                            margin: 0 15px;
                        }
                        ul {
                            gap: 5px;
                            justify-content: center;
    
                            li {
                                a {
                                    line-height: 20px;
                                    font-size: 0;
                                    
                                    span {
                                        font-size: 15px;
                                        width: 30px;
                                        height: 30px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 993px) and (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (min-width: 768px) and (max-width: 992px) {}

@media (max-width: 767px) {
    footer {
        padding: 50px 0 0;
        .container {
            .row {
                flex-direction: column;
                .col {
                    width: 100%;
                    text-align: center;
                    img {
                        margin-bottom: 20px;
                    }
                    &.addresss {
                        padding-bottom: 20px;
                        &:after {
                            height: 1px;
                            width: 70%;
                            left: 50%;
                            bottom: 0%;
                            transform: translate(-50%, -0%);
                        }
                    }
                }
                h4 {
                    font-size: 24px;
                    margin: 20px 0 15px 0px;
                }
                a {
                    color: var(--white);
                }
                ul {
                    padding: 0;
                    margin-bottom: 20px;
                    li {
                        a {
                            line-height: 40px;
                            font-size: 17px;
                            width: 100%;
                            text-align: center;
    
                            span {
                                font-size: 15px;
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                p {
                    font-size: 17px;
                }
            }
            
            &.copyright {
                margin-top: 20px;
                .col {
    
                    p {
                        padding-top: 15px;
                        margin-bottom: 30px;
                        font-size: 15px;
                        text-align: center;
                        display: flex;
                        flex-direction: column-reverse;
                        line-height: 24px;
    
                        b {
                            margin: 0 15px;
                            display: block;

                            svg {
                                display: none;
                            }
                        }

                        ul {
                            gap: 10px;
                            width: 100%;
                            text-align: center;
                            justify-content: center;
                            margin: 15px 0 10px;
    
                            li {
                                a {
                                    line-height: 20px;
                                    font-size: 0;
                                    
                                    span {
                                        font-size: 15px;
                                        width: 30px;
                                        height: 30px;
                                        line-height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .col.addresss h4 {
        display: none;
    }
}

@media (min-width: 571px) and (max-width: 767px) {}

@media (max-width: 570px) {}