section {
    &.blog, &.related-blog {
        padding: 60px 0 100px;

        .container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 60px 20px;
            background: #fff;
            border-radius: 35px;
            padding-top: 20px;

            .item {
                height: 100%;

                .link-whole{
                    text-decoration: none;
                    color: #000 !important;
                    // position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    cursor: pointer;
                    transition: var(--transition);
                    padding: 40px 25px 90px;
                    border-radius: 20px;
                    box-shadow: -10px -10px 30px -10px #c1c1c1;
                    position: relative;

                    .post-img {
                        position: relative;
                        width: 100%;
                        height: 275px;
                        margin-bottom: 10px;
                        border-radius: 15px;
                    }

                    h4{ 
                        font-size: 22px;
                        line-height: 32px;
                        color:  var(--primary-color)!important;
                    }

                    p{
                        margin-top: 20px!important;
                        margin-bottom: 0;
                    }

                    span {  
                        font-size: 14px;
                        font-weight: 600;
                        text-decoration: none; 
                        color: var(--primary-color)!important;
                        padding: 0px!important;
                        border-radius: 10px!important;
                        position: absolute;
                        bottom: 40px;
                        left: 25px;
                        width: auto;
                        
                        &:hover {
                            color: var(--secondary-color)!important;
                        }
                    }

                    &:hover {
                        box-shadow: inset -5px -2px 20px 1px #d9d9d9, -20px 20px 35px -25px #c1c1c1;
                    }
                }

                .link {
                    position: relative;
                    border-radius: 30px;
                    padding: 60px 25px 60px;
                    margin: 0px 20px 0;
                    box-shadow: -10px -10px 30px -10px #c1c1c1;
                    height: 100%;
                    display: table;
                    transition: var(--transition);
                    background: #fff;

                    .post-img {
                        position: relative;
                        width: 100%;
                        height: 275px;
                        margin-bottom: 10px;
                        border-radius: 15px;
                    }

                    h4{ 
                        font-size: 22px;
                        line-height: 32px;
                        color:  var(--primary-color)!important;
                    }

                    p{
                        margin-top: 20px!important;
                        margin-bottom: 0;
                    }

                    span {  
                        font-size: 14px;
                        font-weight: 600;
                        text-decoration: none; 
                        color: var(--primary-color)!important;
                        padding: 0px!important;
                        border-radius: 10px!important;
                        position: absolute;
                        bottom: 40px;
                        left: 25px;
                        width: auto;
                        
                        &:hover {
                            color: var(--secondary-color)!important;
                        }
                    }

                    &:hover {
                        box-shadow: inset -5px -2px 20px 1px #d9d9d9, -20px 20px 35px -25px #c1c1c1;
                    }
                }
            }
        }
    }

    &.related-blog {

        .container {
            margin-top: 0px;
            background: transparent;
            padding-top: 0;
        }

        .container-fluid {
            display: block;
            h5 {
                text-align: center;
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                font-family: var(--raleway);
            }
        }
    }
    
    &.single-blog-details {
        padding: 150px 0 60px;

        .container, .container-fluid {
            .content-full {
                img {
                    width: 100%;
                    max-width: 60%;
                }
                h2 {
                    text-align: center;
                }
                p {
                    
                }
            }
        }
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    section {
        &.blog {
            padding: 60px 0 100px;
    
            .container {
                grid-template-columns: repeat(3, 1fr);
                gap: 60px 20px;
    
                .item {
                    padding: 25px 0px 30px;
                    margin: 0px 0px 0;
    
                    img{
                        position: relative;
                    }
    
                    h4{ 
                        font-size: 20px;
                        line-height: 28px;
                    }
    
                    p{
                        margin-top: 15px!important;
                    }
    
                    span {  
                        font-size: 14px;
                        bottom: 40px;
                        left: 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1366px) {
    section {
        &.blog, &.related-blog {
    
            .container {
                gap: 60px 0px;
                margin-top: 0px;
                border-radius: 35px;
                padding-top: 50px;
    
                .item {
                    .link {
                        padding: 40px 30px 70px;
                        margin: 0px 20px 0;
        
                        img{
                            position: relative;
                        }
        
                        h4{ 
                            font-size: 22px;
                            line-height: 32px;
                        }
        
                        p{
                            margin-top: 20px!important;
                        }
        
                        span {  
                            font-size: 14px;
                            bottom: 30px;
                            left: 20px;
                        }
                    }
                }
            }
        }
    
        &.related-blog {

            .container {
                margin-top: 0px;
            }
    
            .container-fluid {
                h5 {
                    font-size: 30px;
                }
            }
        }
        
        &.single-blog-details {
            padding: 150px 0 60px;
    
            .container, .container-fluid {
                .content-full {
                    img {
    
                    }
                    h2 {
                        text-align: center;
                    }
                    p {
                        
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    section {
        &.blog {
            .container {
                grid-template-columns: repeat(3, 1fr);
                gap: 50px 20px;
                .item {
                    .link {
                        margin: 0px 5px 0;
                        padding: 40px 20px 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    section {
        &.blog, &.related-blog {
            .container {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 0px;
                padding-top: 0px;
                gap: 30px 0px;

                .item {
                    .link {
                        padding: 30px 20px 30px;
                        margin: 0px 10px 0px;
                        h4 {
                            font-size: 20px;
                            line-height: 28px;
                        }
                        p {
                            font-size: 17px;
                            line-height: 28px;
                        }
                        span {
                            bottom: 25px;
                            left: 10px;
                        }
                    }
                }
            }
        }
    
        &.single-blog-details {
            padding: 100px 0 60px;
    
            .container, .container-fluid {
                .content-full {
                    img {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1024px) {}

@media (max-width: 992px) {
    section {
        &.blog, &.related-blog {
            .container {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 0px;
                padding-top: 0px;
                gap: 30px 0px;

                .item {
                    .link {
                        padding: 60px 20px 60px;
                        margin: 0px 10px 0px;
                        h4 {
                            font-size: 20px;
                            line-height: 28px;
                        }
                        p {
                            line-height: 28px;
                        }
                        span {
                            bottom: 55px;
                            left: 20px;
                        }
                    }
                }
            }
        }
    
        &.single-blog-details {
            padding: 150px 0 60px;
    
            .container, .container-fluid {
                .content-full {
                    img {
                        width: 100% !important;
                        height: auto !important;
                        max-width: 90%;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {}

@media (max-width: 767px) {
    section {
        &.blog, &.related-blog {
            .container {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px 20px;

                .item {
                    .link {
                        padding: 60px 20px 60px;
                        margin: 0;
                        h4 {
                            font-size: 18px;
                            line-height: 24px;
                        }
                        p {
                            font-size: 17px;
                            line-height: 28px;
                        }
                        span {
                            bottom: 60px;
                            left: 20px;
                        }
                    }
                }
            }
        }
    
        &.single-blog-details {
            .container, .container-fluid {
                .content-full {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width: 571px) and (max-width: 767px) {}

@media (max-width: 600px) {
    section {
        &.blog, &.related-blog {
            .container {
                .item {
                    p {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}