.banner {
    padding-top: 245px;
    padding-bottom: 215px;
    height: 100vh;

    img.bannerimg {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .container {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        align-items: end;
        position: relative;
        z-index: 99;

        .banner-left {
            width: 100%;
            padding: 0 90px;

            h1 {
                font-size: 60px;
                font-weight: 600;
                line-height: 50px;
                text-transform: capitalize;
                margin: 20px 0 15px;
                text-align: left;
            }

            h2{
                font-size: 50px;
                font-weight: 400;
                line-height: 70px;
                text-transform: capitalize;
                margin: 20px 0 30px;
                text-align: left;
            }

            p {
                width: 50%;
                margin-bottom: 60px;
                font-size: 20px;
                font-weight: 500;
                line-height: 34px;
            }

            .button {
                color: var(--white);
                text-transform: capitalize;
                font-size: 20px;
                padding: 0px;
                font-weight: 800;
                cursor: pointer;
                transition: var(--transition);
                border: 0;
                box-shadow: none;

                &:hover {
                    color: var(--secondary-color);
                    background: transparent;
                }
            }
        }
    }

    .mobile-banner {
        display: none;
    }
}

.content.introduction {
    padding-top: 90px;
    padding-bottom: 100px;
    background-position: center;
    position: relative;
    
    // &:before {
    //     content: '';
    //     height: 100%;
    //     width: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background: rgb(6,3,50);
    //     background: -moz-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 50%, rgba(255,255,255,0) 100%);
    //     background: -webkit-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 50%, rgba(255,255,255,0) 100%);
    //     background: linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 50%, rgba(255,255,255,0) 100%);
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060332",endColorstr="#ffffff",GradientType=1);
    // }

    .spacer-01 {
        min-height: 400px;
    }

    .container {
        position: relative;

        .content-left {
            width: 55%;

            p {
                font-weight: 200;
            }

            a {
                &:hover {
                    color: var(--secondary-color) !important;
                }
            }
        }

        .mobile-show {
            display: none;
        }
    }
}

.content.our-story {
    padding-top: 150px;
    padding-bottom: 100px;

    .container {
        .content-left {
            p {
                line-height: 45px;
                margin-top: 30px;
            }

            a {
                color: var(--primary-color);
                font-weight: 600;
                margin-top: 30px;
                display: block;
                transition: var(--transition);

                &:hover {
                    color: var(--secondary-color);
                }
            }
        }
    }
}

.content.our-values {
    padding-top: 100px;
    padding-bottom: 100px;

    .spacer-01 {
        margin-right: 50px;
    }

    .container {
        .content-right {
            p {
                line-height: 38px;
                margin-top: 30px;
            }

            a {
                color: var(--primary-color);
                font-weight: 600;
                margin-top: 30px;
                display: block;
                transition: var(--transition);

                &:hover {
                    color: var(--secondary-color);
                }
            }
        }
    }
}

.call-to-action {
    background: var(--primary-color);
    padding: 150px 0;

    .container {
        text-align: center;

        .row {
            justify-content: center;

            h3 {
                font-size: 50px;
                font-weight: 600;
            }

            h4 {
                font-size: 50px;
                font-weight: 300;
            }

            a {
                color: var(--white);
                transition: var(--transition);
                margin-top: 30px;
                font-size: 20px;
                width: auto;

                svg {
                    margin-left: 15px;

                    &:hover {
                        color: var(--secondary-color);
                    }
                }

                &:hover {
                    color: var(--secondary-color);
                }
            }
        }
    }
}

.content.testimonials {
    padding-top: 70px;
    padding-bottom: 70px;

    .container {
        .content-full {
            text-align: center;
        }

        svg {
            font-size: 50px;
        }

        h2 {
            text-align: center;
            border-bottom: 3px solid var(--primary-color);
            display: inline-block;
            padding: 0 15px 30px;
            margin-top: 40px;
        }

        .custom-prev-arrow {
            position: absolute;
            top: 80px;
            left: 80px;
            background: #fff;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            box-shadow: 0px 14px 14px -4px #dbdbdb9e;
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 5px 14px 14px -4px #dbdbdb9e;

                svg {
                    color: #000;
                    box-shadow: unset;
                }
            }
        }

        .custom-next-arrow {
            position: absolute;
            top: 80px;
            right: 80px;
            background: #fff;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            box-shadow: 0px 14px 14px -4px #dbdbdb9e;
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 5px 14px 14px -4px #dbdbdb9e;

                svg {
                    color: #000;
                    box-shadow: unset;
                }
            }
        }

        .slick-list {
            max-width: 65%;
            margin: 0 auto;

            .testimonial-data {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .iconbox {
                border-radius: 100px;
                width: 70px;
                height: 70px;
                margin: 0 auto 30px;
            }

            .testimonial-name {
                margin: 0;
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
                margin-bottom: 10px;
            }

            .testimonial-position {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
            } 
        }
    }
}

.content {
    .case-studies.container-fluid, .case-studies.container {
        padding: 80px 100px 80px;

        .content-left {
            width: 62%;
        }  

        .content-right {
            width: 38%;
        }

        article.card {
            background: transparent;
            border: 0;
        }

        .custom-prev-arrow {
            position: absolute;
            top: unset;
            left: unset;
            background: var(--primary-color);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            // box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 50px;
            right: -110px;
            transition: var(--transition);

            svg {
                width: 60px;
                height: 60px;
                padding: 12px;
                box-shadow: inset 0px 3px 5px 1px #474576;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);

                svg {
                    color: var(--white);
                    box-shadow: unset;
                }
            }
        }

        .custom-next-arrow {
            position: absolute;
            top: unset;
            right: -195px;
            background: var(--primary-color);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            // box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 50px;
            transition: var(--transition);

            svg {
                width: 60px;
                height: 60px;
                padding: 12px;
                box-shadow: inset 0px 3px 5px 1px #474576;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);

                svg {
                    color: var(--white);
                    box-shadow: unset;
                }
            }
        }

        .slick-list {
            margin: 0 auto;
            padding-bottom: 120px;

            .slick-slide>div {
                // background: url(../../images/case-study-bg.webp) no-repeat center center;
                // background-size: cover;
                background: var(--primary-color);
                border-radius: 30px;
                padding: 90px 55px 0px;
                margin: 60px 40px 0;
                box-shadow: inset -15px -15px 30px -20px var(--primary-box-shadow), -15px -15px 30px -20px var(--primary-box-shadow);
                transition: var(--transition);
                position: relative;
                overflow: hidden;

                h4 {
                    font-size: 25px;
                    line-height: 34px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0;
                    font-weight: 500;
                }

                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    box-shadow: inset -15px -15px 30px -20px transparent, -5px -5px 20px 0px var(--primary-box-shadow);
                }
            }
        }
    }
}

.content {
    .why-us.container-fluid {
        padding: 80px 100px 50px;

        .spacer-01 {
            margin-right: 20px;
        }

        .content-left {
            width: 42%;
        }

        .content-right {
            width: 58%;
            padding: 0 0 0 80px;
        }

        .slick-slider {
            margin-top: 20px;
            margin-bottom: 70px;

            .custom-prev-arrow {
                position: absolute;
                top: unset;
                left: 0;
                background: #d9edff;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 100px;
                box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                color: #b6b6b6;
                font-size: 30px;
                cursor: pointer;
                z-index: 9;
                bottom: 0;
                transition: var(--transition);

                svg {
                    width: 60px;
                    height: 60px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    border-radius: 100px;
                    transition: var(--transition);
                    color: #b6b6b6;
                }

                &:hover {
                    box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                    background: #cde6fc;

                    svg {
                        color: #000;
                        box-shadow: unset;
                    }
                }
            }

            .custom-next-arrow {
                position: absolute;
                top: unset;
                left: 80px;
                background: #d9edff;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 100px;
                box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                color: #b6b6b6;
                font-size: 30px;
                cursor: pointer;
                z-index: 9;
                bottom: 0px;
                transition: var(--transition);

                svg {
                    width: 60px;
                    height: 60px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    border-radius: 100px;
                    transition: var(--transition);
                    color: #b6b6b6;
                }

                &:hover {
                    box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                    background: #cde6fc;

                    svg {
                        color: #000;
                        box-shadow: unset;
                    }
                }
            }

            .slick-list {
                margin: 0 auto;
                padding: 0 15px 115px;

                .slick-slide>div {
                    margin: 40px 40px 0 0;
                    text-align: left;

                    img {
                        margin: 0;
                    }

                    .iconbox {
                        width: 65px;
                        height: 65px;
                    }

                    h4 {
                        font-size: 25px;
                        line-height: 32px;
                        font-weight: 700;
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }

                    p {
                        font-size: 20px;
                        line-height: 35px;
                        margin: 0;
                        font-weight: 500;
                    }
                }
            }
        }

        a {
            padding-left: 180px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 800;
            color: var(--black);
            text-transform: capitalize;
            transition: var(--transition);

            &:hover {
                color: var(--secondary-color);
            }
        }
    }
    .why-us.container-fluid.home-whyus {

        .slick-slider {

            .custom-prev-arrow {
                position: absolute;
                top: unset;
                left: 0;
                background: #fff;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 100px;
                box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                color: #b6b6b6;
                font-size: 30px;
                cursor: pointer;
                z-index: 9;
                bottom: 0;
                transition: var(--transition);

                svg {
                    width: 60px;
                    height: 60px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    border-radius: 100px;
                    transition: var(--transition);
                    color: #b6b6b6;
                }

                &:hover {
                    box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                    background: #fff;

                    svg {
                        color: #000;
                        box-shadow: unset;
                    }
                }
            }

            .custom-next-arrow {
                position: absolute;
                top: unset;
                left: 80px;
                background: #fff;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 100px;
                box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                color: #b6b6b6;
                font-size: 30px;
                cursor: pointer;
                z-index: 9;
                bottom: 0px;
                transition: var(--transition);

                svg {
                    width: 60px;
                    height: 60px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    border-radius: 100px;
                    transition: var(--transition);
                    color: #b6b6b6;
                }

                &:hover {
                    box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                    background: #fff;

                    svg {
                        color: #000;
                        box-shadow: unset;
                    }
                }
            }
        }
    }
}

.services {
    padding: 80px 0 150px 0;

    .slick-list {
        margin: 0 auto;
        display: flex;

        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
            }
        }
    }
}

.content.driven-by {
    .container {

        h3 {
            span {
                font-weight: 700;
            }
        }

        .content-left {
            width: 60%;
        }

        .content-right {
            width: 40%;
        }

        .custom-prev-arrow {
            position: absolute;
            top: unset;
            left: unset;
            background: var(--primary-color);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            // box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #919191;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            right: -110px;
            transition: var(--transition);

            svg {
                width: 60px;
                height: 60px;
                padding: 12px;
                box-shadow: inset 0px 3px 5px 1px #474576;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);

                svg {
                    color: var(--white);
                    box-shadow: unset;
                }
            }
        }

        .custom-next-arrow {
            position: absolute;
            top: unset;
            right: -195px;
            background: var(--primary-color);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            // box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #919191;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            transition: var(--transition);

            svg {
                width: 60px;
                height: 60px;
                padding: 12px;
                box-shadow: inset 0px 3px 5px 1px #474576;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 3px 5px 10px -5px rgba(219, 219, 219, 0.6196078431);

                svg {
                    color: var(--white);
                    box-shadow: unset;
                }
            }
        }

        .slick-list {
            margin: 0 auto;
            display: flex;
            padding-bottom: 30px;

            .slick-track {
                display: flex;

                .slick-slide {
                    display: flex;
                }
            }

            .slick-slide>div {
                border-radius: 30px;
                padding: 70px 55px 50px;
                margin: 10px 40px 40px;
                box-shadow: inset -5px -2px 30px 1px transparent, 20px 10px 50px -25px #c1c1c1;
                transition: var(--transition);

                img {
                    margin-bottom: 30px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin: 0 auto 20px;
                }

                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0;
                    font-weight: 500;
                    text-align: center;
                }

                &:hover {
                    box-shadow: inset 8px 8px 30px -10px #9290a3, 0px 0px 0px 0px #c1c1c1;
                }
            }
        }
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {}

@media (min-width: 1201px) and (max-width: 1600px) {

    .content {
        .container, .container-fluid {
            h2 {
                font-size: 40px;
                line-height: 45px;
            }
        }
    }

    .banner {
        padding-top: 100px;
        padding-bottom: 40px;
        height: 90vh;
        display: flex;
        margin-top: 0px;

        .container {
            .banner-left {
                padding: 0 0px;

                h1 {
                    font-size: 40px;
                    line-height: 45px;
                    margin: 20px 0 5px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 45px;
                    margin: 0px 0 15px;
                }

                p {
                    margin-bottom: 40px;
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 45%;
                }

                .button {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .content.introduction {
        padding-top: 70px;
        padding-bottom: 70px;

        .container {
            position: relative;

            .content-left {
                width: 55%;

                h3 {
                    margin-bottom: 0;
                }

                p {
                    font-size: 17px;
                }
            }
        }
    }

    .content {
        .container {
            h2 {
                font-size: 36px;
                line-height: 40px;
            }
        }
    }

    .call-to-action {
        padding: 110px 0;

        .container {
            .row {
                h3 {
                    font-size: 40px;
                }

                h4 {
                    font-size: 40px;
                }

                a {
                    margin-top: 20px;
                    font-size: 18px;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            h2 {
                padding: 0 15px 25px;
                margin-top: 40px;
            }

            .custom-prev-arrow {
                top: 80px;
                left: 60px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .custom-next-arrow {
                top: 80px;
                right: 60px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .slick-list {
                max-width: 65%;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15px;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {
            padding: 30px 50px 80px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 10px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 10px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
                padding-bottom: 50px;
    
                .slick-slide>div {
                    padding: 50px 20px 0px;
                    margin: 40px 20px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 24px;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }
    
                    p {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .content {
        .why-us.container-fluid {
            padding: 0px 50px 0px;
    
            .content-left {
                width: 42%;
            }
    
            .content-right {
                width: 58%;
                padding: 0 0 0 20px;
            }
    
            .slick-slider {
                margin-top: 20px;
                margin-bottom: 0px;
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .slick-list {
    
                    .slick-slide>div {
                        margin: 40px 40px 0 0;
    
                        img {
                            margin: 0;
                        }
    
                        h4 {
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 15px;
                        }
    
                        p {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                }
            }

            a {
                padding-left: 150px !important;
                font-size: 15px;
                line-height: 24px;
                top: -35px !important;
            }
        }
        .why-us.container-fluid.home-whyus {
    
            .slick-slider {
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
            }
        }
    }

    .content.our-values {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .content-right {
                p {
                    line-height: 35px;
                    margin-top: 30px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
        }
    }

    .content.our-story {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .content-left {

                .spacer200 {
                    height: 100px;
                }

                p {
                    line-height: 35px;
                    margin-top: 30px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
        }
    }

    .content.driven-by {
        .container {
    
            .content-left {
                width: 60%;
            }
    
            .content-right {
                width: 40%;

                h2 {
                    font-size: 38px;
                }

                p {
                    margin-top: 20px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: -20px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 14px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: -20px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 14px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    border-radius: 30px;
                    padding: 50px 20px 0px;
                    margin: 10px 20px 40px;
                    margin: 10px 20px 40px;
                    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
    
                    img {
                        margin-bottom: 30px;
                        width: 75px;
                        height: 75px;
                    }

                    h4 {
                        font-size: 18px;
                    }
    
                    p {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {

    .banner {
        padding-top: 150px;
        padding-bottom: 60px;
        height: 70vh;
        display: flex;

        .container {
            .banner-left {
                padding: 0px;
                width: 80%;

                h1 {
                    font-size: 40px;
                    line-height: 45px;
                    margin: 20px 0 5px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 45px;
                    margin: 0px 0 15px;
                }

                p {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 32px;
                    width: 100%;
                }

                .button {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .content.introduction {
        padding-top: 90px;
        padding-bottom: 100px;
        background-position: center right;
    
        .container {
            position: relative;
    
            .content-left {
                width: 55%;
    
                p {
                    font-weight: 200;
                }
            }

            .mobile-show {
                display: none;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            h2 {
                padding: 0 15px 20px;
                margin-top: 30px;
            }

            .custom-prev-arrow {
                top: 80px;
                left: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .custom-next-arrow {
                top: 80px;
                right: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .slick-list {
                max-width: 80%;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15px;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .call-to-action {
        padding: 110px 0;

        .container {
            .row {
                h3 {
                    font-size: 36px;
                }

                h4 {
                    font-size: 36px;
                }

                a {
                    margin-top: 20px;
                    font-size: 18px;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {
            padding: 30px 40px 100px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 50px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 50px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
                padding-bottom: 40px;
    
                .slick-slide>div {
                    padding: 30px 20px 0px;
                    margin: 40px 15px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin: 20px 0;
                    }
    
                    p {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .content {
        .why-us.container-fluid {
            padding: 80px 40px 50px;
    
            .content-left {
                width: 42%;
            }
    
            .content-right {
                width: 58%;
                padding: 0 0 0 20px;
            }
    
            .slick-slider {
                margin-top: 20px;
                margin-bottom: 0px;
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .slick-list {
    
                    .slick-slide>div {
                        margin: 40px 40px 0 0;
    
                        img {
                            margin: 0;
                        }
    
                        h4 {
                            font-size: 18px;
                            line-height: 25px;
                            margin: 20px 0;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
    
            a {
                padding-left: 150px !important;
                font-size: 18px;
                line-height: 30px;
                position: relative;
                top: -35px !important;
            }
        }
        .why-us.container-fluid.home-whyus {
    
            .slick-slider {
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
            }
        }
    }

    .content.our-values {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .pr-60 {
                padding-right: 20px !important;
            }

            .content-right {
                p {
                    line-height: 35px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
        }
    }

    .content.our-story {
        padding-top: 150px;
        padding-bottom: 100px;
    
        .container {
            .content-left {

                .spacer200 {
                    height: 100px;
                }
                
                p {
                    line-height: 35px;
                    margin-top: 30px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
            .content-right {
                .pl-60 {
                    padding-left: 20px !important;
                }
            }
        }
    }

    .content.driven-by {
        .container {
    
            .content-left {
                width: 58%;
            }
    
            .content-right {
                width: 42%;

                h2 {
                    font-size: 34px;
                }

                p {
                    margin-top: 20px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    border-radius: 30px;
                    padding: 50px 20px 40px;
                    margin: 10px 20px 40px;
                    margin: 10px 20px 40px;
                    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
    
                    img {
                        margin-bottom: 30px;
                        width: 75px;
                        height: 75px;
                    }

                    h4 {
                        font-size: 18px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 1024px) {

    .banner {
        position: relative;

        .container {
            gap: 50px;
            flex-direction: column-reverse;
            position: relative;
            z-index: 9;

            .banner-left {
                text-align: left;
                width: 100%;
                padding: 0 0px;

                h1 {
                    font-size: 36px;
                    line-height: 45px;
                    margin: 20px 0 10px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 40px;
                    margin: 20px 0 30px;
                }

                p {
                    text-align: left;
                    margin-bottom: 40px;

                    br {
                        display: none;
                    }
                }

                a.button {
                    font-size: 16px;
                }
            }

            .banner-right {
                text-align: center;
                width: 100%;
            }
        }
    }

    .content.introduction {
        padding-top: 90px;
        padding-bottom: 100px;
        // background-image: none !important;
        background-color: var(--primary-color);
        background-size: contain !important;
    
        .container {
            position: relative;
    
            .content-left {
                width: 55%;
    
                p {
                    font-weight: 200;
                }
            }

            .mobile-show {
                display: none;
                img {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16/9;
                    object-fit: contain;
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            h2 {
                padding: 0 15px 20px;
                margin-top: 30px;
            }

            .custom-prev-arrow {
                top: 80px;
                left: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .custom-next-arrow {
                top: 80px;
                right: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .slick-list {
                max-width: 80%;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15px;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .call-to-action {
        padding: 110px 0;

        .container {
            .row {
                h3 {
                    font-size: 30px;
                }

                h4 {
                    font-size: 30px;
                }

                a {
                    margin-top: 20px;
                    font-size: 18px;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {
            padding: 0px 40px 30px;
    
            .content-left {
                width: 50%;
            }
    
            .content-right {
                width: 50%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 20px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 20px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
                padding-bottom: 50px;
    
                .slick-slide>div {
                    padding: 40px 20px 10px;
                    margin: 40px 20px 0;
    
                    h4 {
                        font-size: 20px;
                        line-height: 24px;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .content {
        .why-us.container-fluid {
            padding: 40px 40px 50px;
    
            .content-left {
                width: 50%;
            }
    
            .content-right {
                width: 50%;
                padding: 0 0 0 20px;
            }
    
            .slick-slider {
                margin-top: 20px;
                margin-bottom: 0px;
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .slick-list {
    
                    .slick-slide>div {
                        margin: 40px 40px 0 0;
    
                        img {
                            margin: 0;
                        }
    
                        h4 {
                            font-size: 20px;
                            line-height: 28px;
                        }
    
                        p {
                            font-size: 17px;
                            line-height: 30px;
                        }
                    }
                }
            }
    
            a {
                padding-left: 140px !important;
                font-size: 16px;
                line-height: 30px;
                position: relative;
                top: -40px !important;
            }
        }
        .why-us.container-fluid.home-whyus {
    
            .slick-slider {
    
                .custom-prev-arrow {
                    top: unset;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
    
                .custom-next-arrow {
                    top: unset;
                    left: 70px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    bottom: 0px;
    
                    svg {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                }
            }
        }
    }

    .content.our-values {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .pr-60 {
                padding-right: 0px !important;
            }

            .content-left {                
                img {
                    height: auto;
                }
            }

            .content-right {
                p {
                    line-height: 35px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
        }
    }

    .content.our-story {
        padding-top: 100px;
        padding-bottom: 80px;
    
        .container {
            .content-left {

                .spacer200 {
                    height: 100px;
                }
            }
            .content-right {
                .pl-60 {
                    padding-left: 0px !important;
                }
                img {
                    height: auto;
                }
            }
        }
    }

    .content.driven-by {
        .container {
    
            .content-left {
                width: 50%;
            }
    
            .content-right {
                width: 50%;

                p {
                    margin-top: 20px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    border-radius: 30px;
                    padding: 50px 20px 40px;
                    margin: 10px 20px 40px;
                    margin: 10px 20px 40px;
                    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
    
                    img {
                        margin-bottom: 30px;
                        width: 75px;
                        height: 75px;
                    }

                    h4 {
                        font-size: 18px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }
            }
        }
    }

}

@media (min-width: 993px) and (max-width: 1024px) {

    .banner {
        padding-top: 150px;
        padding-bottom: 60px;
        height: auto;

        .container {
            gap: 50px;

            .banner-left {
                padding: 0 0px;

                h1 {
                    font-size: 30px;
                    line-height: 36px;
                    margin: 20px 0 0px;
                }

                h2 {
                    font-size: 30px;
                    line-height: 35px;
                    margin: 10px 0 15px;
                }

                p {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 28px;
                }

                a.button {
                    font-size: 16px;
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            h2 {
                padding: 0 15px 20px;
                margin-top: 30px;
            }

            .custom-prev-arrow {
                top: 80px;
                left: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .custom-next-arrow {
                top: 80px;
                right: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .slick-list {
                max-width: 80%;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                    font-size: 17px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15x;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }

}

@media (max-width: 992px) {

    .banner {
        padding-top: 140px;
        padding-bottom: 60px;
        height: auto;
        min-height: 300px;

        &:before {
            content: '';
            background: var(--primary-color);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            z-index: 9;
        }

        img.bannerimg {
            display: none !important;
        }

        .container {
            gap: 50px;

            .banner-left {
                padding: 0 0px;

                h1 {
                    font-size: 30px;
                    line-height: 35px;
                    margin: 20px 0 10px;
                }

                h2 {
                    font-size: 30px;
                    line-height: 35px;
                    margin: 0px 0 30px;
                }

                p {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 28px;
                    width: 100%;
                }

                a.button {
                    font-size: 16px;
                }
            }
        }
    }

    section {
        padding: 50px 0;
    }
    
    .content.introduction {
        padding-top: 50px;
        padding-bottom: 30px;
        background-image: none !important;
    
        .container {
    
            .content-left {
                width: 55%;
            }


            .mobile-show {
                display: block;
                img {
                    height: auto;
                    aspect-ratio: 16/9;
                    object-fit: contain;
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            h2 {
                padding: 0 15px 20px;
                margin-top: 30px;
            }

            .custom-prev-arrow {
                top: 80px;
                left: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .custom-next-arrow {
                top: 80px;
                right: 15px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;

                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }

            .slick-list {
                max-width: 80%;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                    font-size: 17px;
                    line-height: 32px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15px;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .call-to-action {
        padding: 110px 0;

        .container {
            .row {
                h3 {
                    font-size: 28px;
                }

                h4 {
                    font-size: 28px;
                }

                a {
                    margin-top: 20px;
                    font-size: 18px;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .content.our-values {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .pr-60 {
                padding-right: 20px !important;
            }

            .content-right {
                p {
                    line-height: 35px;
                }
    
                a {
                    margin-top: 30px;
                }
            }
        }
    }

    .content.our-story {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            .content-left {

                .spacer200 {
                    height: 80px;
                }
            }
        }
    }

    .content.driven-by {
        .container {
    
            .content-left {
                width: 50%;
            }
    
            .content-right {
                width: 50%;

                p {
                    margin-top: 20px;
                    line-height: 28px;
                }
            }
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                    position: relative;
                    top: -3px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 0px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                    position: relative;
                    top: -3px;
                }
            }

            .slick-list {
    
                .slick-slide>div {
                    border-radius: 30px;
                    padding: 50px 20px 40px;
                    margin: 10px 20px 40px;
                    margin: 10px 20px 40px;
                    box-shadow: inset -5px -2px 30px 1px transparent, 10px 10px 35px -25px #c1c1c1;
    
                    img {
                        margin-bottom: 30px;
                        width: 75px;
                        height: 75px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {
            padding: 30px 0px 30px;
    
            .custom-prev-arrow {
                top: unset;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 50px;
                right: -110px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                top: unset;
                right: -175px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                bottom: 50px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    margin: 40px 20px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 24px;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .content {
        .container {
            .content-left, .content-right {
                padding: 0px !important;
            }
        }
    }

    .h-200 {
        height: auto;
        overflow-y: hidden;
        direction: ltr;
    }

    .banner {
        padding-top: 120px;
        padding-bottom: 70px;
        background-image: none !important;
        background: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
    
        .container {
    
            .banner-left {
                text-align: center;
    
                h1 {
                    font-size: 30px;
                    line-height: 40px;
                    margin: 20px 0;
                    text-align: center;
                }
    
                h2 {
                    font-size: 30px;
                    line-height: 40px;
                    margin: 20px 0 30px;
                    text-align: center;
                }
    
                p {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 27px;
                    text-align: center;
                }
    
                .button {
                    font-size: 18px;
                }
            }
        }
    
        .mobile-banner {
            padding-top: 30px;
            display: none;
            width: 100%;
        }
    }

    .content.introduction {
        padding-top: 50px;
        padding-bottom: 30px;
        
        &:before {
            display: none;
        }
    
        .container {
            flex-direction: column-reverse;

            .content-left, .content-right {
                width: 100%;
                padding: 0;

                p {
                    text-align: center;
                }

                img {
                    margin: 0 auto;
                    height: auto;
                    aspect-ratio: 16/9;
                    object-fit: contain;
                }

                .h-200 {
                    height: auto;
                    overflow: hidden;
                }
            }
        }
    }

    .industries {
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 30px;
                width: auto;
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {
            padding: 20px 0px 60px;
            flex-direction: column-reverse;
            .content-left {
                width: 100%;
            }
            .content-right {
                width: 100%;

                p {
                    text-align: center;
                }
            }
            
            .slick-list {
                padding-bottom: 50px;
            }

            .custom-prev-arrow {
                top: unset;
                left: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                bottom: 0;
                transform: translate(-100%, 0%);
                right: unset;
                z-index: 9;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
            .custom-next-arrow {
                top: unset;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                left: 50%;
                bottom: 0%;
                transform: translate(10%, 0%);

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
        }
    }

    .content {
        .why-us.container-fluid {
            flex-direction: column;
            padding: 40px 0px 0px;
            .content-left {
                width: 100%;
            }
            .content-right {
                width: 100%;
                text-align: center;
                padding: 0 15px;
                margin-top: 20px;
            }
            .slick-slider {
                margin-bottom: 20px;
                .slick-list {
                    padding-bottom: 60px;
                    .slick-slide > div {
                        text-align: center;
                        margin: 20px 15px 0;
                        p, img {
                            text-align: center;
                            margin: auto;
                        }

                        .iconbox {
                            width: 50px;
                            height: 50px;
                            margin: 0 auto;
                        }
                    }
                }

                .custom-prev-arrow {
                    top: unset;
                    left: 50%;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    bottom: 0;
                    transform: translate(-100%, 0%);
                    right: unset;
                    z-index: 9;
    
                    svg {
                        width: 40px;
                        height: 40px;
                        padding: 10px;
                    }
                }
                .custom-next-arrow {
                    top: unset;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    left: 50%;
                    bottom: 0%;
                    transform: translate(10%, 0%);
    
                    svg {
                        width: 40px;
                        height: 40px;
                        padding: 10px;
                    }
                }
            }
        }
        .why-us.container-fluid.home-whyus {
            .slick-slider {

                .custom-prev-arrow {
                    top: unset;
                    left: 50%;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    bottom: 0;
                    transform: translate(-100%, 0%);
                    right: unset;
                    z-index: 9;
    
                    svg {
                        width: 40px;
                        height: 40px;
                        padding: 10px;
                    }
                }
                .custom-next-arrow {
                    top: unset;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    left: 50%;
                    bottom: 0%;
                    transform: translate(10%, 0%);
    
                    svg {
                        width: 40px;
                        height: 40px;
                        padding: 10px;
                    }
                }
            }
        }
    }

    .content.testimonials {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            
            .content-full {
                padding: 0;
            }

            svg {
                font-size: 40px;
            }

            h2 {
                padding: 0 15px 20px;
                margin: 30px 0;
            }

            .custom-prev-arrow {
                top: unset;
                left: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                bottom: 0;
                transform: translate(-100%, -0%);
                right: unset;
                z-index: 9;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }

            .custom-next-arrow {
                top: unset;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                left: 50%;
                bottom: 0%;
                transform: translate(10%, -0%);

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }

            .slick-list {
                max-width: 100%;
                padding-bottom: 60px;

                .testimonial-data {
                    margin-top: 25px;
                    margin-bottom: 20px;
                    font-size: 17px;
                    line-height: 32px;
                }

                .iconbox {
                    width: 65px;
                    height: 65px;
                    margin-bottom: 15px;
                }

                .testimonial-name {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .testimonial-position {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }
    }
    
    .content {
        .why-us.container-fluid {
            a {
                padding-left: 0 !important;
                font-size: 15px;
                line-height: 30px;
                top: 0 !important;
            }

            .content-left {
                img {
                    max-width: 50%;
                }
            }
        }
    }

    .call-to-action {
        padding: 60px 0;

        .container {
            .row {
                h3 {
                    font-size: 24px;
                }

                h4 {
                    font-size: 24px;
                }

                a {
                    margin-top: 20px;
                    font-size: 16px;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .content.our-values {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            flex-direction: column;
            .pr-60 {
                padding-right: 0px !important;
            }

            .content-left {
                img {
                    max-width: 50%;
                }
            }

            .content-right {
                p {
                    line-height: 35px;
                }
    
                a {
                    margin-top: 30px;
                    text-align: center;
                }
            }
        }
    }

    .content.our-story {
        padding-top: 80px;
        padding-bottom: 80px;
    
        .container {
            flex-direction: column-reverse;
            .content-left {

                .spacer200 {
                    height: 80px;
                }

                p {
                    line-height: 28px;
                    font-size: 17px;
                }

                a {
                    text-align: center;
                }
            }
            .content-right {
                img {
                    max-width: 50%;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .content.driven-by {
        .container {
            flex-direction: column-reverse;
    
            .content-left {
                width: 100%;
            }
    
            .content-right {
                width: 100%;

                p {
                    margin-top: 20px;
                    text-align: center;
                }
            }

            .custom-prev-arrow {
                top: unset;
                left: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                bottom: 0;
                transform: translate(-100%, 0%);
                right: unset;
                z-index: 9;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
            .custom-next-arrow {
                top: unset;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                left: 50%;
                bottom: 0%;
                transform: translate(10%, 0%);

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
    
            .slick-list {
                padding-bottom: 70px !important;
    
                .slick-slide>div {
                    border-radius: 30px;
                    // padding: 50px 20px 20px;
                    // margin: 10px 20px 40px;
                    // box-shadow: inset 0px 0px 0px 0px transparent, 0px 0px 20px 0px rgba(204, 204, 204, 0.58);
                    padding: 0px;
                    margin: 0px;
                    box-shadow: unset;
    
                    img {
                        margin-bottom: 30px;
                        width: 75px;
                        height: 75px;
                    }

                    h4 {
                        font-size: 17px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
    
}

@media (max-width: 600px) {

    .banner {
        padding-top:80px;
        padding-bottom: 50px;
        // background-image: none !important;
        // background: var(--primary-color) !important;
        // background-color: var(--primary-color) !important;
        // min-height: 100vh;

        .container {
            gap: 50px;

            .banner-left {
                padding: 0;

                h1, h2 {
                    font-size: 30px;
                    line-height: 35px;
                    margin: 20px 0 10px;
                }
            }
        }
    
        .mobile-banner {
            padding-top: 30px;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .content.introduction {    
        .container {
            .content-left, .content-right {
                img {
                    max-width: 85%;
                    height: auto;
                    aspect-ratio: 16/9;
                    object-fit: contain;
                }
            }
        }
    }
    
    .content {
        .why-us.container-fluid {
            .content-left {
                img {
                    max-width: 70%;
                }
            }
        }
    }

    .content.our-values {
        padding-top: 50px;
        padding-bottom: 50px;
    
        .container {

            .content-left {
                img {
                    max-width: 70%;
                }
            }

            .content-right {
                p {
                    line-height: 30px;
                }
            }
        }
    }

    .content{
        &.our-story {
            padding-top: 50px !important;

            .container {
                .content-right {
                    img {
                        max-width: 70%;
                    }
                }
            }
        }
    }

    .content {
        .case-studies.container-fluid, .case-studies.container {    
            .slick-list {    
                .slick-slide>div {
                    margin: 40px 10px 0;
                }
            }
        }
    }

    .content.testimonials {
        .container {
            svg {
                font-size: 30px;
            }
        }
    }

}








.scrolling-area {
    max-width: 100%;
    max-height: 250px;
    overflow-x: hidden;
    padding: 1rem;
    background: white;
    direction: rtl;
  }
  
  .scrolling-element-inside {
    direction: ltr;
}




.scrollbar-2 {
	$scrollbar-2-thumb-width: 6px;
	$scrollbar-2-thumb-color: #000;
	$scrollbar-2-track-color: #bbb;
    // Chrome & Opera
    &::-webkit-scrollbar {
       width: $scrollbar-2-thumb-width;
		 height: $scrollbar-2-thumb-width;

       &-track {
        background: rgb(255,255,255);
        background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(181,181,181,1) 33%, rgba(181,181,181,1) 66%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(181,181,181,1) 33%, rgba(181,181,181,1) 66%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(181,181,181,1) 33%, rgba(181,181,181,1) 66%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
       }

       &-thumb {
          background-color: $scrollbar-2-thumb-color;
       }
		 
		 &-track,
		 &-thumb {
			 border-radius: 12px;
		 }
    }
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color;
}

.scrollbar-3 {
	$scrollbar-2-thumb-width: 6px;
	$scrollbar-2-thumb-color: #fff;
	$scrollbar-2-track-color: #ccc;
    // Chrome & Opera
    &::-webkit-scrollbar {
       width: $scrollbar-2-thumb-width;
		 height: $scrollbar-2-thumb-width;

       &-track {
        background: rgb(6,3,50);
        background: -moz-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 33%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 66%, rgba(6,3,50,1) 66%, rgba(6,3,50,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 33%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 66%, rgba(6,3,50,1) 66%, rgba(6,3,50,1) 100%);
        background: linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 33%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 66%, rgba(6,3,50,1) 66%, rgba(6,3,50,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060332",endColorstr="#060332",GradientType=1);
       }

       &-thumb {
          background-color: $scrollbar-2-thumb-color;
       }
		 
		 &-track,
		 &-thumb {
			 border-radius: 12px;
		 }
    }
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color;
}

[class^="scrollbar"] {
	height: 200px;
	overflow-y: scroll;
    margin-top: 20px;
    direction: rtl;
	
	div {
		width: 100%;
		padding: 0 0 0 0px;
        direction: ltr;
	}
}

/* ////////////animation starts/////////////// */

.animate {
  opacity: 0;
  animation: fadeIn .8s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ////////animation ends////////////// */