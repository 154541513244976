.pl-0 {
    padding-left: 0 !important;
}
section {
    &.contact {
        margin-top: 0px;
        position: relative;
        z-index: 99;

        form{
            width: 100% !important;

            .container {
                padding: 0px;
                background-color: #fff;
                overflow: hidden;
                align-items: stretch;
                max-width: 80%;
                gap: 0;

                .content-left{
                    width: 50%;
                    padding: 80px 50px;
                    background-color: #fff;                    
                }

                .content-right{
                    width: 50%;
                    padding: 80px 50px;
                    background: #ededed;

                    h2 {
                        margin-bottom: 40px;
                        margin-top: 40px;
                    }

                    .row {
                        flex-direction: column;
                    }

                    // .contact-box {
                    //     display: flex;
                    //     padding: 20px 0;

                    //     svg {
                    //         font-size: 55px;
                    //         color: #fff;
                    //         background-color: var(--secondary-color);
                    //         padding: 20px;
                    //         border-radius: 10px;
                    //         margin: 0 20px 0 0;
                    //     }

                    //     p {
                    //         font-size: 20px;
                    //         line-height: 30px;
                    //         font-family: 'poppins';
                    //         margin: 0;

                    //         small {
                    //             display: block;
                    //         }

                    //         a {
                    //             display: block;
                    //             font-weight: 500;
                    //             color:var(--black);
                    //             transition: var(--transition);

                    //             &:hover {
                    //                 color: var(--secondary-color);
                    //             }
                    //         }
                    //     }
                    // }
                }

                .form-control {
                    font-size: 15px;
                    border-radius: 0px !important;
                    padding: 2rem 0rem 0.5rem;
                    height: 50px;
                }

                textarea {
                    height: 50px;
                }

                .form-floating > label {
                    padding: 0rem 0em;
                    height: auto;
                    background: #ededed;

                    span.text-danger {
                        font-size: 20px;
                    }
                }

                .form-group button {
                    background: #137dbc;
                    color: var(--white);
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 600;
                    height: 50px;
                    border-radius: 5px;
                    min-width: 190px;
                    padding: 0 25px;
                    cursor: pointer;
                    transition: var(--transition);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 20px;
                        margin: 0 5px 0 0;
                    }

                    &:hover {
                        background-color: #0a6ca5;
                    }
                }

                .form-check .form-check-input {
                    float: left;
                    margin-left: -25px;
                    width: 15px;
                    height: 15px;
                    margin-top: 13px;
                }

                .col-form-label-sm {
                    padding-top: calc(0.25rem + 1px);
                    padding-bottom: calc(0.25rem + 1px);
                    font-size: 0.875rem;
                    line-height: 16px;
                }

                .information {
                    font-size: 14px;
                    line-height:20px;
                    margin: 10px 0;

                    &:last-child {
                        margin-bottom: 30px;
                    }
                }

                .form-check {
                    margin-bottom: 15px;
                    padding-left: 25px;
                }

                .enq-reason {
                    margin-bottom: 10px;
                }

                .spacer-contact {
                    height: 250px;
                    width: 100%;
                    max-width: 80%;
                    margin: 0 auto;
                }

            }
        }
    }

    &.location-wrapper {
        position: relative;
        margin-bottom: 50px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: #fafafa;
            width: 50%;
            height: 100%;
            z-index: -1;
        }

        .container {
            .d-flex.align-items-start {
                max-width: 80%;
                margin: 0 auto;
                padding: 30px 0 30px 40px;
                background: rgb(6,3,50);
                background: -moz-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
                background: linear-gradient(90deg, rgba(6,3,50,1) 0%, rgba(6,3,50,1) 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060332",endColorstr="#ffffff",GradientType=1);
                .nav {
                    button {
                        width: 170px;
                        background-color: transparent;
                        border: none;
                        text-align: left;
                        line-height: 20px;
                        font-size: 16px;
                        color: var(--secondary-color);
                        position: relative;
                        top: 50px;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            width: 40px;
                            height: 4px;
                            background-color: #fafafa;
                        }
                    }
                }
                .tab-content {
                    background: #fafafa;
                    width: 100%;
                    padding: 45px;
                    min-height: 400px;
                    display: flex;
                    align-items: center;
                    box-shadow: 10px 0px 10px -3px #e9e9e9;
                    font-family: 'Poppins', sans-serif;

                    b {
                        color: var(--black);
                        font-weight: 400;
                        font-size: 16px;
                    }
                    a {
                        color: var(--black);
                        svg {
                            color: red;
                            font-size: 14px;
                            margin-top: -5px;
                        }
                        &:hover {
                            color: #137dbc;
                            b {
                                color: #137dbc;
                            }
                        }
                    }
                }
            }
        }
    }
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    transform: scale(0.65) translateY(-0.5rem) translateX(0.15rem) !important;
    color: #023d75;
    opacity: 1 !important;
}
.form-floating.active .form-control {
    border-color: #023d75;
}

@media (min-width: 1367px) and (max-width: 1600px) {
    
}
@media (min-width: 1201px) and (max-width: 1600px) {
    section {
        &.contact {        
            .container{        
                .content-left{
                    padding: 80px 50px;
        
                    form{
        
                        .form-group .form-control {
                            height: 55px;
                        }
        
                        .form-group button {
                            height: 50px;
                            min-width: 150px;
                        }
                    }
                }
        
                .content-right{
                    padding: 80px 50px;
        
                    h2 {
                        margin-bottom: 30px;
                        margin-top: 35px;
                    }
        
                    .contact-box {
        
                        svg {
                            font-size: 50px;
                            padding: 15px;
                            margin: 0 15px 0 0;
                        }
        
                        p {
                            font-size: 17px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }  

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    max-width: 75%;
                }
            }
        }
    }                          

    section.contact form .container .form-check .form-check-input {
        margin-top: 8px;
    }
}
@media (min-width: 1025px) and (max-width: 1200px) {
    section {
        &.contact {
        
            .container{
                max-width: 100%;
        
                .content-left{
                    padding: 70px 40px;
        
                    form{
        
                        .form-group .form-control {
                            height: 55px;
                        }
        
                        .form-group button {
                            height: 50px;
                        }
                    }
                }
        
                .content-right{
                    padding: 70px 40px;
        
                    .contact-box {
        
                        svg {
                            font-size: 50px;
                            padding: 15px;
                        }
        
                        p {
                            font-size: 17px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    max-width: 100%;
                }
            }
        }
    }                         

    section.contact form .container .form-check .form-check-input {
        margin-top: 8px;
    }
}
@media (min-width: 993px) and (max-width: 1024px) {
    section {
        &.contact {
            margin-top: 0px;
        
            .container{
                max-width: 100%;
        
                .content-left{
                    width: 55%;
                    padding: 70px 40px;
        
                    form{
                        margin-top: 40px;
        
                        .form-group .form-control {
                            height: 55px;
                        }
        
                        .form-group button {
                            height: 50px;
                        }
                    }
                }
        
                .content-right{
                    width: 45%;
                    padding: 70px 40px;
        
                    h2 {
                        margin-bottom: 30px;
                        margin-top: 35px;
                    }
        
                    .contact-box {
        
                        svg {
                            font-size: 50px;
                            padding: 15px;
                            margin: 0 15px 0 0;
                        }
        
                        p {
                            font-size: 17px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    max-width: 100%;
                }
            }
        }
    }                         

    section.contact form .container .form-check .form-check-input {
        margin-top: 10px;
    }
}
@media (max-width: 992px) {
    section {
        &.contact {
            margin-top: 0px;
        
            form {
                .container{
                    max-width: 100%;
            
                    .content-left{
                        width: 50%;
                        padding: 70px 20px !important;
            
                        form{
                            margin-top: 40px;
            
                            .form-group .form-control {
                                height: 55px;
                            }
            
                            .form-group button {
                                height: 50px;
                            }
                        }
                    }
            
                    .content-right{
                        width: 50%;
                        padding: 70px 0px;
            
                        h2 {
                            margin-bottom: 30px;
                            margin-top: 35px;
                        }
            
                        .contact-box {
            
                            svg {
                                font-size: 50px;
                                padding: 15px;
                                margin: 0 15px 0 0;
                            }
            
                            p {
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
        }

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    max-width: 100%;
                }
            }
        }
    }                         

    section.contact form .container {
        .spacer-contact {
            max-width: 100%;
        }
        .form-check .form-check-input {
            margin-top: 9px;
        }
    }

}
@media (min-width: 768px) and (max-width: 992px) {
}
@media (max-width: 767px) {
    section {
        &.contact {
            margin-top: 0px;
            padding-bottom: 0;
            margin-bottom: 60px;

            form {
        
                .container{
                    flex-direction: column;
            
                    .content-left{
                        width: 100%;
                        padding: 0px 40px 0 !important;

                        .col.form-check-wrapper {
                            margin-top: 30px;
                        }
                    }
            
                    .content-right {
                        width: 100%;
                        padding: 20px 40px 40px !important;
            
                        h2 {
                            margin-bottom: 30px;
                            margin-top: 0px;
                        }
                    }

                    .spacer-contact {
                        display: none;
                    }
                }
            }
        }

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    max-width: 100%;
                    padding: 30px 30px 30px 30px;
                    flex-direction: column;
                    
                    .nav button {
                        width: 100%;
                        top: 0;
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
    section.contact form .container .form-check .form-check-input {
        margin-top: 6px;
    }
}
@media screen and (max-width: 600px) {
    section {
        &.contact {
            margin-bottom: 40px;

            form {
        
                .container{    
                    max-width: 85%;

                    .content-left {
                        padding: 20px 25px 20px !important;
                    }

                    .content-right {
                        padding: 20px 0px 40px !important;
                    }
                }
            }
        }

        &.location-wrapper {
            .container {
                .d-flex.align-items-start {
                    padding: 40px 30px;
                    .tab-content {
                        padding: 40px 20px;
                        min-height: auto;
                    }
                }
            }
        }
    }
}