

/* Your CSS */
.navbar {
  /* Default Navbar styles */
  background-color: #fff;
  /* ...other styles... */
}

.navbar.scrolled {
  /* Styles when scrolled */
  background-color: #333 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* ...other styles... */
}

nav.navbar.navbar-expand-lg.navbar-dark {
  // background: var(--primary-color);
  background: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
  transition: .5s;
  
  &.menufixed {
    background: var(--primary-color);
  }

  .container {

    a.navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
    }

    #a3-main-menu {
      .navbar-nav {
        .nav-item {
          margin: 0 25px;
          .nav-link {
            color: var(--white);
            margin: 0;
            text-transform: capitalize;
            transition: var(--transition);
            padding-right: 0rem !important;
            padding-left: 0rem !important;
            padding-top: 25px;
            padding-bottom: 5px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient( to right, var(--secondary-color), var(--secondary-color) 50%, #fff 50%);
            background-size: 200% 100%;
            background-position: -100%;
            transition: all 0.3s ease-in-out;
            position: relative;
            font-weight: 600;
            text-wrap: wrap;
            font-size: 20px;

            &:before, &.active:before {
              display: block;
              content: '';
              width: 0;
              height: 1px;
              bottom: 5px;
              left: 0;
              z-index: 0;
              position: absolute;
              background: var(--secondary-color);
              transition: all 0.3s ease-in-out;
            }
            &.active {
              background-position: 0%;
              &:before {
                width: 100%;
              }
            }
            .link {
              padding-right: 0rem !important;
              padding-left: 0rem !important;
              color: var(--secondary-color);
              transition: var(--transition);
              font-weight: bold;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-image: linear-gradient( to right, var(--secondary-color), var(--secondary-color) 50%, #fff 50%);
              background-size: 200% 100%;
              background-position: 0%;
              transition: all 0.3s ease-in-out;
              position: relative;
              text-wrap: wrap;
              line-height: 20px;

              &:hover {
                color: var(--white) !important;
              }
            }
            &:hover {
              color: var(--secondary-color) !important;
              background-position: 0%;
              &:before {
                width: 100%;
              }
              .link {
                color: var(--white) !important;
              }
            }
          }    
          ul.dropdown-menu {
            .nav-item {
              text-wrap: wrap;
              margin: 0px;
              padding-right: 0.2rem !important;
              padding-left: 0rem !important;
              white-space: initial;
            }
            
            .dropend {
              .dropdown-toggle {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 15px;
                white-space: initial;
                &:hover {
                  // background: var(--secondary-color);
                  // background: var(--bg-menu-level-1-hover);
                  background-color: transparent;
                  color: var(--secondary-color);
                  .link {
                    color: var(--white) !important;
                    .link img {
                      filter: grayscale(1) brightness(5);
                    }
                    &:hover {
                      color: var(--secondary-color);
                    }
                  }
                }
              }
            }

            .dropdown-item a {
              &:hover {
                color: var(--black) !important;
                .link {
                  color: var(--black) !important;
                }
              }
            }
          }
          &.ind_menu-items {
            .nav-link {
              .link {
                color: var(--white);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-image: linear-gradient(to right, var(--white), var(--white) 50%, #fff 50%);
                background-size: 200% 100%;
                background-position: -100%;
                transition: all 0.3s ease-in-out;
                &:hover {
                  color: var(--secondary-color) !important;
                  background-image: linear-gradient(to right, var(--secondary-color), var(--secondary-color) 50%, #fff 50%);
                  background-position: 0%;
                }
              }
            }
          }
        }
      }
    }
  }
}

li.level-1-list {
  width: 16.66%;
}

li.level-2-list.hasdropdown.dropend svg {
  display: block;
  color: #fff;
}

.dropdown-item {
  .link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

.dropdown-menu.nav-item .nav-link, .dropdown-menu.nav-item .nav-link .link {
  color: var(--black);
  &:hover {
    color: var(--black);
    background: var(--secondary-color);
  }
}

.dropdown .dropdown-menu {
  display: none;
  background: var(--primary-color);
  padding: 0;
  border: 0;
  // box-shadow: 0 15px 24px -16px var(--secondary-color);
  border-radius: 0;
  // min-width: 250px;

  .dropdown-item {
    color: var(--white);
    &:hover {
      background-color: transparent;
      color: var(--secondary-color);
    }
    .nav-item {
      .link, a {
        color: var(--white);
      }
    }
  }
}

nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item.dropend .nav-link:hover {
  background-color: var(--bg-menu-level-1-hover);
}

.dropend .dropdown-toggle::after,
.dropdown-toggle::after, button.toggleMenu {
  display: none !important;
}

.dropdown-item a, a.dropdown-item {
  padding: 0.5rem 1rem !important;
  text-transform: capitalize;
  color: var(--white);
}

.nav-item {
  .nav-link {
    text-transform: capitalize;
    
    .link, a {
      text-transform: capitalize;
    }
  }
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--white) !important;
  font-weight: 600;
}

.nav-link:hover {
  color: var(--secondary-color) !important;
}

.nav-link:focus {
  color: var(--secondary-color) !important;
}

.hasdropdown {
  svg {
    position: absolute;
    right: 8px;
    top: 20px;
    font-size: 10px;
    display: none;

    &.isOpened {
      transform: rotate(0deg);
    }
  }

  &.dropend svg {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    height: 35px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px;
    display: none;

    &.isOpened {
      transform: rotate(180deg);
    }
  }
}


.level-1-list {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  top: 5px;
}





@media (min-width: 1367px) and (max-width: 1600px) {
  nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item {
    margin: 0 15px;
    
    .nav-link {
      font-size: 18px;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1366px) {
  nav.navbar.navbar-expand-lg.navbar-dark {

    .container {
      #a3-main-menu {
        .navbar-nav {
          .nav-item {
            margin: 0 15px;
            .nav-link {
              font-size: 16px;
            }    
            ul.dropdown-menu {
              .nav-item {
                margin: 0px;
              }
            }
            ul {
              li {
                width: 16.666%;
                ul {
                  li {
                    width: 100%;
                    ul {
                      li {
                        width: 95%;
                      }
                    }
                  }
                }
              }
            }
            &.ind_menu-items {
              ul {
                li {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .hasdropdown.dropend svg {
    height: 30px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  nav.navbar.navbar-expand-lg.navbar-dark {
    .container {
      #a3-main-menu {
        .navbar-nav {
          .nav-item {
            margin: 0 15px;
            .nav-link {
              font-size: 16px;
            }    
            ul.dropdown-menu {
              .nav-item {
                margin: 0px;
                .nav-link {
                  font-size: 14px;
                  line-height: 28px;
                }   
              }
            }
            ul {
              li {
                width: 16.666%;
                ul {
                  li {
                    width: 100%;
                    ul {
                      li {
                        width: 95%;
                      }
                    }
                  }
                }
              }
            }
            &.ind_menu-items {
              ul {
                li {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .hasdropdown.dropend svg {
    height: 30px;
  }
}

@media (max-width: 1024px) {
  nav.navbar.navbar-expand-lg.navbar-dark {

    .container {
      #a3-main-menu {
        .navbar-nav {
          .nav-item {
            margin: 0 15px;
            .nav-link {
              font-size: 16px;
            }    
            ul.dropdown-menu {
              .nav-item {
                margin: 0px;
                .nav-link {
                  font-size: 14px;
                  line-height: 28px;
                }   
              }
            }
          }
        }
      }
    }
  }
  .hasdropdown.dropend svg {
    height: 30px;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {

}

@media screen and (min-width: 992px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    right: 100%;
  }

  // .dropdown:hover > .dropdown-menu,
  // .dropend:hover > .dropdown-menu {
  //   display: block;
  //   margin-top: 0;
  //   margin-left: 0;
  // }

  ul.dropdown-menu.dropdown-menu-level-3 {
    position: relative !important;
    left: 0px;
    top: 0px !important;
    flex-direction: column;
    background-color: transparent;
    border-radius: 0;
    overflow: hidden;
    li {
      margin-left: 10px !important;
    }
}

  // ul.dropdown-menu.dropdown-menu-level-2 {
  //   li.nav-item.dropend.hasdropdown {
  //     &:hover {
  //       ul.dropdown-menu.dropdown-menu-level-3 {
  //         display: flex !important;
  //       }
  //     }
  //   }
  // }
}

@media (max-width: 992px) {
  .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  button.toggleMenu {
    display: block !important;
  }

  nav.navbar.navbar-expand-lg.navbar-dark {
    background: var(--primary-color);
    position: fixed;
    height: 80px;
    top: 0;
    z-index: 9999;
    padding-top: 0;
    padding-bottom: 0;

    .container {
      background: var(--primary-color);
      margin: 0;
      width: 100%;
      padding: 0;

      a.navbar-brand {
        padding: 0.5rem;
        img {
          width: 140px;
          height: auto;
        }
      }
    }
  }

  .toggleMenu {
    .navbar-toggler-icon {
      padding: 10px;
      border-radius: 5px;
      filter: brightness(1);
    }
    .btn-close {
      width: 1.5em;
      height: 1.5em;
      color: var(--white);
    }
  }

  .dropdown .dropdown-menu.dropdown-menu-level-1 {
    box-shadow: unset;
  }

  .hasdropdown {
    svg {
      right: 0px;
      top: 0px;
      font-size: 36px;
      height: 48px;
      width: 40px;
      padding: 12px;
      display: block;
      color: #fff;
    }

    &.dropend svg {
      right: 0;
      top: 0;
      font-size: 20px;
      height: 48px;
      width: 40px;
      padding: 14px;
      display: block;
      color: var(--white);
    }

    .dropdown-item {
      font-size: 14px;
      line-height: 24px;
    }

    .dropdown-toggle {
      padding-right: 50px !important;
      padding-left: 0.5rem;

      &.firstparent {
        padding-right: 25px !important;
      }
    }
  }

  .dropend:hover > .dropdown-menu {
    position: sticky;
    top: 0;
    left: 0%;
  }

  .dropdown-menu-level-1 {
    padding-left: 0px;
  }

  .dropdown-menu-level-2,
  .dropdown-menu-level-3,
  .dropdown-menu-level-4 {
    padding-left: 15px !important;
  }

  li.nav-item {
    margin: 0px;
  }

  .dropdown .dropdown-menu {
    min-width: auto;
    width: 95%;
    margin: 0 auto;
    padding-left: 0 !important;
  }


  nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item .nav-link {
    background-image: linear-gradient(to right, #ffffff, #ffffff 50%, #fff 50%);
    background-position: 0%;
    &:before {
      display: none;
    }
  }
  nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item .nav-link .link {
    background-image: linear-gradient(to right, #ffffff, #ffffff 50%, #fff 50%);
    background-position: 0%;
  }
  .dropdown-item a, a.dropdown-item {
    padding: 0.5rem 0rem !important;
  }
  nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item .nav-link {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item .nav-link:before, nav.navbar.navbar-expand-lg.navbar-dark .container #a3-main-menu .navbar-nav .nav-item .nav-link.active:before {
    display: none;
  }
  
  .level-1-list {
    top: 0;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {

}

@media (max-width: 767px) {

}

@media (min-width: 571px) and (max-width: 767px) {

}

@media (max-width: 600px) {
}