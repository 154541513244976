footer {
  background: var(--primary-color);
  padding: 50px 0 0;
}
footer .container {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}
footer .container .row .col {
  width: 300px;
  position: relative;
}
footer .container .row .col.addresss:after {
  content: "";
  height: 80%;
  width: 3px;
  background: #fff;
  position: absolute;
  right: 25px;
  bottom: 0;
  transform: translate(-50%, 0%);
}
footer .container .row .col.address .address-wrapper {
  display: flex;
}
footer .container .row .col.address .address-wrapper svg {
  color: var(--white);
  font-size: 20px;
  margin-top: 8px;
  margin-right: 2px;
}
footer .container .row h4 {
  color: var(--white);
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 0 15px 40px;
  font-weight: 600;
}
footer .container .row a {
  color: var(--white);
  transition: var(--transition);
}
footer .container .row a:hover {
  color: var(--secondary-color);
}
footer .container .row ul {
  padding: 0;
}
footer .container .row ul li a {
  line-height: 40px;
  display: inline-block;
  font-size: 18px;
}
footer .container .row ul li a span {
  color: var(--black);
  font-size: 15px;
  background: var(--white);
  border-radius: 100px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  transition: var(--transition);
}
footer .container .row ul li a:hover span {
  background: var(--primary-color);
  color: var(--white);
}
footer .container .row p {
  color: var(--white);
  font-size: 18px;
  margin-bottom: 0;
}
footer .container.copyright {
  margin-top: 50px;
}
footer .container.copyright .col {
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .container.copyright .col p {
  color: var(--white);
  border-top: 1px solid var(--card-border);
  padding-top: 15px;
  margin-bottom: 30px;
  font-size: 18px;
  display: flex;
}
footer .container.copyright .col p b {
  font-weight: 400;
  margin: 0 15px;
}
footer .container.copyright .col p b a {
  color: var(--white);
  transition: var(--transition);
}
footer .container.copyright .col p b a:hover {
  color: var(--secondary-color);
}
footer .container.copyright .col p ul {
  display: inline-flex;
  padding: 0;
  gap: 5px;
}
footer .container.copyright .col p ul li a {
  line-height: 20px;
  font-size: 0;
}
footer .container.copyright .col p ul li a span {
  color: var(--white);
  display: inline-block;
  font-size: 15px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  transition: var(--transition);
  width: 25px;
}
footer .container.copyright .col p ul li a span img {
  border-radius: 5px;
}
footer .container.copyright .col p ul li a:hover span {
  color: var(--secondary-color);
}

@media (min-width: 1201px) and (max-width: 1600px) {
  footer {
    padding: 50px 0 0;
  }
  footer .container {
    gap: 20px;
  }
  footer .container .row .col {
    width: 250px;
  }
  footer .container .row h4 {
    font-size: 24px;
    margin: 0 0 15px 40px;
  }
  footer .container .row a {
    color: var(--white);
  }
  footer .container .row ul li a {
    line-height: 32px;
    font-size: 17px;
  }
  footer .container .row ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  footer .container .row p {
    font-size: 17px;
    line-height: 32px;
  }
  footer .container.copyright {
    margin-top: 50px;
  }
  footer .container.copyright .col p {
    padding-top: 15px;
    margin-bottom: 30px;
    font-size: 17px;
  }
  footer .container.copyright .col p b {
    margin: 0 15px;
  }
  footer .container.copyright .col p ul {
    gap: 17px;
  }
  footer .container.copyright .col p ul li a {
    line-height: 20px;
    font-size: 0;
  }
  footer .container.copyright .col p ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  footer {
    padding: 50px 0 0;
  }
  footer .container {
    gap: 20px;
  }
  footer .container .row .col {
    width: 250px;
  }
  footer .container .row h4 {
    font-size: 24px;
    margin: 0 0 15px 40px;
  }
  footer .container .row a {
    color: var(--white);
  }
  footer .container .row ul li a {
    line-height: 35px;
    font-size: 17px;
  }
  footer .container .row ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  footer .container .row p {
    font-size: 17px;
    line-height: 30px;
  }
  footer .container.copyright {
    margin-top: 50px;
  }
  footer .container.copyright .col p {
    padding-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
  }
  footer .container.copyright .col p b {
    margin: 0 15px;
  }
  footer .container.copyright .col p ul {
    gap: 5px;
  }
  footer .container.copyright .col p ul li a {
    line-height: 20px;
    font-size: 0;
  }
  footer .container.copyright .col p ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1024px) {
  footer {
    padding: 50px 0 0;
  }
  footer .container {
    gap: 20px;
  }
  footer .container .row .col {
    width: 250px;
  }
  footer .container .row h4 {
    font-size: 24px;
    margin: 0 0 15px 40px;
  }
  footer .container .row a {
    color: var(--white);
  }
  footer .container .row ul li a {
    line-height: 35px;
    font-size: 17px;
  }
  footer .container .row ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  footer .container .row p {
    font-size: 17px;
    line-height: 30px;
  }
  footer .container.copyright {
    margin-top: 50px;
  }
  footer .container.copyright .col p {
    padding-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    flex-direction: column;
    display: flex;
    width: 100%;
    text-align: center;
  }
  footer .container.copyright .col p b {
    margin: 0 15px;
  }
  footer .container.copyright .col p ul {
    gap: 5px;
    justify-content: center;
  }
  footer .container.copyright .col p ul li a {
    line-height: 20px;
    font-size: 0;
  }
  footer .container.copyright .col p ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  footer {
    padding: 50px 0 0;
  }
  footer .container .row {
    flex-direction: column;
  }
  footer .container .row .col {
    width: 100%;
    text-align: center;
  }
  footer .container .row .col img {
    margin-bottom: 20px;
  }
  footer .container .row .col.addresss {
    padding-bottom: 20px;
  }
  footer .container .row .col.addresss:after {
    height: 1px;
    width: 70%;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0%);
  }
  footer .container .row h4 {
    font-size: 24px;
    margin: 20px 0 15px 0px;
  }
  footer .container .row a {
    color: var(--white);
  }
  footer .container .row ul {
    padding: 0;
    margin-bottom: 20px;
  }
  footer .container .row ul li a {
    line-height: 40px;
    font-size: 17px;
    width: 100%;
    text-align: center;
  }
  footer .container .row ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  footer .container .row p {
    font-size: 17px;
  }
  footer .container.copyright {
    margin-top: 20px;
  }
  footer .container.copyright .col p {
    padding-top: 15px;
    margin-bottom: 30px;
    font-size: 15px;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    line-height: 24px;
  }
  footer .container.copyright .col p b {
    margin: 0 15px;
    display: block;
  }
  footer .container.copyright .col p b svg {
    display: none;
  }
  footer .container.copyright .col p ul {
    gap: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 15px 0 10px;
  }
  footer .container.copyright .col p ul li a {
    line-height: 20px;
    font-size: 0;
  }
  footer .container.copyright .col p ul li a span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .col.addresss h4 {
    display: none;
  }
}/*# sourceMappingURL=footer.css.map */