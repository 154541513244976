.pl-0 {
  padding-left: 0 !important;
}

section.contact {
  margin-top: 0px;
  position: relative;
  z-index: 99;
}
section.contact form {
  width: 100% !important;
}
section.contact form .container {
  padding: 0px;
  background-color: #fff;
  overflow: hidden;
  align-items: stretch;
  max-width: 80%;
  gap: 0;
}
section.contact form .container .content-left {
  width: 50%;
  padding: 80px 50px;
  background-color: #fff;
}
section.contact form .container .content-right {
  width: 50%;
  padding: 80px 50px;
  background: #ededed;
}
section.contact form .container .content-right h2 {
  margin-bottom: 40px;
  margin-top: 40px;
}
section.contact form .container .content-right .row {
  flex-direction: column;
}
section.contact form .container .form-control {
  font-size: 15px;
  border-radius: 0px !important;
  padding: 2rem 0rem 0.5rem;
  height: 50px;
}
section.contact form .container textarea {
  height: 50px;
}
section.contact form .container .form-floating > label {
  padding: 0rem 0em;
  height: auto;
  background: #ededed;
}
section.contact form .container .form-floating > label span.text-danger {
  font-size: 20px;
}
section.contact form .container .form-group button {
  background: #137dbc;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  border-radius: 5px;
  min-width: 190px;
  padding: 0 25px;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
}
section.contact form .container .form-group button img {
  width: 20px;
  margin: 0 5px 0 0;
}
section.contact form .container .form-group button:hover {
  background-color: #0a6ca5;
}
section.contact form .container .form-check .form-check-input {
  float: left;
  margin-left: -25px;
  width: 15px;
  height: 15px;
  margin-top: 13px;
}
section.contact form .container .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 16px;
}
section.contact form .container .information {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}
section.contact form .container .information:last-child {
  margin-bottom: 30px;
}
section.contact form .container .form-check {
  margin-bottom: 15px;
  padding-left: 25px;
}
section.contact form .container .enq-reason {
  margin-bottom: 10px;
}
section.contact form .container .spacer-contact {
  height: 250px;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}
section.location-wrapper {
  position: relative;
  margin-bottom: 50px;
}
section.location-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fafafa;
  width: 50%;
  height: 100%;
  z-index: -1;
}
section.location-wrapper .container .d-flex.align-items-start {
  max-width: 80%;
  margin: 0 auto;
  padding: 30px 0 30px 40px;
  background: rgb(6, 3, 50);
  background: linear-gradient(90deg, rgb(6, 3, 50) 0%, rgb(6, 3, 50) 80%, rgb(255, 255, 255) 80%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060332",endColorstr="#ffffff",GradientType=1);
}
section.location-wrapper .container .d-flex.align-items-start .nav button {
  width: 170px;
  background-color: transparent;
  border: none;
  text-align: left;
  line-height: 20px;
  font-size: 16px;
  color: var(--secondary-color);
  position: relative;
  top: 50px;
}
section.location-wrapper .container .d-flex.align-items-start .nav button:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 40px;
  height: 4px;
  background-color: #fafafa;
}
section.location-wrapper .container .d-flex.align-items-start .tab-content {
  background: #fafafa;
  width: 100%;
  padding: 45px;
  min-height: 400px;
  display: flex;
  align-items: center;
  box-shadow: 10px 0px 10px -3px #e9e9e9;
  font-family: "Poppins", sans-serif;
}
section.location-wrapper .container .d-flex.align-items-start .tab-content b {
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
}
section.location-wrapper .container .d-flex.align-items-start .tab-content a {
  color: var(--black);
}
section.location-wrapper .container .d-flex.align-items-start .tab-content a svg {
  color: red;
  font-size: 14px;
  margin-top: -5px;
}
section.location-wrapper .container .d-flex.align-items-start .tab-content a:hover {
  color: #137dbc;
}
section.location-wrapper .container .d-flex.align-items-start .tab-content a:hover b {
  color: #137dbc;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  transform: scale(0.65) translateY(-0.5rem) translateX(0.15rem) !important;
  color: #023d75;
  opacity: 1 !important;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  transform: scale(0.65) translateY(-0.5rem) translateX(0.15rem) !important;
  color: #023d75;
  opacity: 1 !important;
}

.form-floating.active .form-control {
  border-color: #023d75;
}

@media (min-width: 1201px) and (max-width: 1600px) {
  section.contact .container .content-left {
    padding: 80px 50px;
  }
  section.contact .container .content-left form .form-group .form-control {
    height: 55px;
  }
  section.contact .container .content-left form .form-group button {
    height: 50px;
    min-width: 150px;
  }
  section.contact .container .content-right {
    padding: 80px 50px;
  }
  section.contact .container .content-right h2 {
    margin-bottom: 30px;
    margin-top: 35px;
  }
  section.contact .container .content-right .contact-box svg {
    font-size: 50px;
    padding: 15px;
    margin: 0 15px 0 0;
  }
  section.contact .container .content-right .contact-box p {
    font-size: 17px;
    line-height: 25px;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    max-width: 75%;
  }
  section.contact form .container .form-check .form-check-input {
    margin-top: 8px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  section.contact .container {
    max-width: 100%;
  }
  section.contact .container .content-left {
    padding: 70px 40px;
  }
  section.contact .container .content-left form .form-group .form-control {
    height: 55px;
  }
  section.contact .container .content-left form .form-group button {
    height: 50px;
  }
  section.contact .container .content-right {
    padding: 70px 40px;
  }
  section.contact .container .content-right .contact-box svg {
    font-size: 50px;
    padding: 15px;
  }
  section.contact .container .content-right .contact-box p {
    font-size: 17px;
    line-height: 25px;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    max-width: 100%;
  }
  section.contact form .container .form-check .form-check-input {
    margin-top: 8px;
  }
}
@media (min-width: 993px) and (max-width: 1024px) {
  section.contact {
    margin-top: 0px;
  }
  section.contact .container {
    max-width: 100%;
  }
  section.contact .container .content-left {
    width: 55%;
    padding: 70px 40px;
  }
  section.contact .container .content-left form {
    margin-top: 40px;
  }
  section.contact .container .content-left form .form-group .form-control {
    height: 55px;
  }
  section.contact .container .content-left form .form-group button {
    height: 50px;
  }
  section.contact .container .content-right {
    width: 45%;
    padding: 70px 40px;
  }
  section.contact .container .content-right h2 {
    margin-bottom: 30px;
    margin-top: 35px;
  }
  section.contact .container .content-right .contact-box svg {
    font-size: 50px;
    padding: 15px;
    margin: 0 15px 0 0;
  }
  section.contact .container .content-right .contact-box p {
    font-size: 17px;
    line-height: 25px;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    max-width: 100%;
  }
  section.contact form .container .form-check .form-check-input {
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  section.contact {
    margin-top: 0px;
  }
  section.contact form .container {
    max-width: 100%;
  }
  section.contact form .container .content-left {
    width: 50%;
    padding: 70px 20px !important;
  }
  section.contact form .container .content-left form {
    margin-top: 40px;
  }
  section.contact form .container .content-left form .form-group .form-control {
    height: 55px;
  }
  section.contact form .container .content-left form .form-group button {
    height: 50px;
  }
  section.contact form .container .content-right {
    width: 50%;
    padding: 70px 0px;
  }
  section.contact form .container .content-right h2 {
    margin-bottom: 30px;
    margin-top: 35px;
  }
  section.contact form .container .content-right .contact-box svg {
    font-size: 50px;
    padding: 15px;
    margin: 0 15px 0 0;
  }
  section.contact form .container .content-right .contact-box p {
    font-size: 16px;
    line-height: 25px;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    max-width: 100%;
  }
  section.contact form .container .spacer-contact {
    max-width: 100%;
  }
  section.contact form .container .form-check .form-check-input {
    margin-top: 9px;
  }
}
@media (max-width: 767px) {
  section.contact {
    margin-top: 0px;
    padding-bottom: 0;
    margin-bottom: 60px;
  }
  section.contact form .container {
    flex-direction: column;
  }
  section.contact form .container .content-left {
    width: 100%;
    padding: 0px 40px 0 !important;
  }
  section.contact form .container .content-left .col.form-check-wrapper {
    margin-top: 30px;
  }
  section.contact form .container .content-right {
    width: 100%;
    padding: 20px 40px 40px !important;
  }
  section.contact form .container .content-right h2 {
    margin-bottom: 30px;
    margin-top: 0px;
  }
  section.contact form .container .spacer-contact {
    display: none;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    max-width: 100%;
    padding: 30px 30px 30px 30px;
    flex-direction: column;
  }
  section.location-wrapper .container .d-flex.align-items-start .nav button {
    width: 100%;
    top: 0;
    margin-bottom: 40px;
  }
  section.contact form .container .form-check .form-check-input {
    margin-top: 6px;
  }
}
@media screen and (max-width: 600px) {
  section.contact {
    margin-bottom: 40px;
  }
  section.contact form .container {
    max-width: 85%;
  }
  section.contact form .container .content-left {
    padding: 20px 25px 20px !important;
  }
  section.contact form .container .content-right {
    padding: 20px 0px 40px !important;
  }
  section.location-wrapper .container .d-flex.align-items-start {
    padding: 40px 30px;
  }
  section.location-wrapper .container .d-flex.align-items-start .tab-content {
    padding: 40px 20px;
    min-height: auto;
  }
}/*# sourceMappingURL=contact.css.map */