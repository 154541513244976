.industries {
  padding: 80px 0;
}
.industries .container .services-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px 10px;
  margin-top: 30px;
  width: 60%;
}
.industries .container .services-wrapper article {
  text-align: center;
  transition: var(--transition);
  background-color: transparent;
  border: 0;
}
.industries .container .services-wrapper article button {
  height: 100%;
}
.industries .container .services-wrapper article img {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: inline-block;
}
.industries .container .services-wrapper article h4 {
  margin-top: 10px;
  color: var(--white);
}
.industries .container .services-wrapper article small {
  width: 100%;
  display: block;
  line-height: 24px;
  margin: 10px 0;
  color: var(--white);
}
.industries .container .services-wrapper article .link {
  color: var(--secondary-color);
  font-size: 25px;
  text-decoration: none;
  padding: 30px 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  transition: var(--transition);
  cursor: pointer;
  font-weight: 600;
}
.industries .container .services-wrapper article .link small {
  color: var(--white);
}
.industries .container .services-wrapper article .link:hover {
  color: var(--white);
}
.industries .container .services-wrapper article:hover img {
  border: 2px solid var(--primary-color);
}
.industries .container .services-wrapper article:hover h4 {
  color: var(--black);
}
.industries .container .services-wrapper article:hover small {
  color: var(--white);
}
.industries .container .services-wrapper article:hover link {
  color: var(--white);
}
.industries .container .services-wrapper article:hover link:hover {
  color: var(--white);
}

.content .industry-features.container-fluid, .content .industry-features.container {
  padding: 80px 100px 10px;
}
.content .industry-features.container-fluid .content-left, .content .industry-features.container .content-left {
  width: 62%;
}
.content .industry-features.container-fluid .content-right, .content .industry-features.container .content-right {
  width: 38%;
}
.content .industry-features.container-fluid article.card, .content .industry-features.container article.card {
  background: transparent;
  border: 0;
}
.content .industry-features.container-fluid .custom-prev-arrow, .content .industry-features.container .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: 50%;
  background: var(--white);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  right: unset;
  transition: var(--transition);
  transform: translate(-120%, 0%);
}
.content .industry-features.container-fluid .custom-prev-arrow svg, .content .industry-features.container .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
}
.content .industry-features.container-fluid .custom-prev-arrow:hover, .content .industry-features.container .custom-prev-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: var(--white);
}
.content .industry-features.container-fluid .custom-prev-arrow:hover svg, .content .industry-features.container .custom-prev-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content .industry-features.container-fluid .custom-next-arrow, .content .industry-features.container .custom-next-arrow {
  position: absolute;
  top: unset;
  right: 50%;
  background: var(--white);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  left: unset;
  transition: var(--transition);
  transform: translate(120%, 0%);
}
.content .industry-features.container-fluid .custom-next-arrow svg, .content .industry-features.container .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  border-radius: 100px;
  transition: var(--transition);
}
.content .industry-features.container-fluid .custom-next-arrow:hover, .content .industry-features.container .custom-next-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  background: var(--white);
}
.content .industry-features.container-fluid .custom-next-arrow:hover svg, .content .industry-features.container .custom-next-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.content .industry-features.container-fluid .slick-list, .content .industry-features.container .slick-list {
  margin: 0 auto;
  padding-bottom: 150px;
}
.content .industry-features.container-fluid .slick-list .slick-slide > div, .content .industry-features.container .slick-list .slick-slide > div {
  background: var(--white);
  border-radius: 30px;
  padding: 90px 55px 50px;
  margin: 0px 40px 0;
  transition: var(--transition);
  box-shadow: inset -5px -2px 30px 1px transparent, 5px 0px 20px -5px #c1c1c1;
}
.content .industry-features.container-fluid .slick-list .slick-slide > div h4, .content .industry-features.container .slick-list .slick-slide > div h4 {
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
}
.content .industry-features.container-fluid .slick-list .slick-slide > div p, .content .industry-features.container .slick-list .slick-slide > div p {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-weight: 500;
}
.content .industry-features.container-fluid .slick-list .slick-slide > div:hover, .content .industry-features.container .slick-list .slick-slide > div:hover {
  box-shadow: inset 5px 5px 20px 1px #d9d9d9, 0px 0px 0px 0px #c1c1c1;
}
.content .features-with-border.container-fluid, .content .features-with-border.container {
  padding: 80px 100px 150px;
}
.content .features-with-border.container-fluid .content-left, .content .features-with-border.container .content-left {
  width: 62%;
}
.content .features-with-border.container-fluid .content-right, .content .features-with-border.container .content-right {
  width: 38%;
}
.content .features-with-border.container-fluid article.card, .content .features-with-border.container article.card {
  background: transparent;
  border: 0;
}
.content .features-with-border.container-fluid .custom-prev-arrow, .content .features-with-border.container .custom-prev-arrow {
  position: absolute;
  top: unset;
  left: 50%;
  background: transparent;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: var(--white);
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  right: unset;
  transition: var(--transition);
  transform: translate(-120%, 0%);
  border: 1px solid var(--secondary-color);
}
.content .features-with-border.container-fluid .custom-prev-arrow svg, .content .features-with-border.container .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 100px;
  transition: var(--transition);
}
.content .features-with-border.container-fluid .custom-prev-arrow:hover, .content .features-with-border.container .custom-prev-arrow:hover {
  background: var(--secondary-color);
}
.content .features-with-border.container-fluid .custom-prev-arrow:hover svg, .content .features-with-border.container .custom-prev-arrow:hover svg {
  color: var(--white);
}
.content .features-with-border.container-fluid .custom-next-arrow, .content .features-with-border.container .custom-next-arrow {
  position: absolute;
  top: unset;
  right: 50%;
  background: transparent;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: var(--white);
  font-size: 30px;
  cursor: pointer;
  z-index: 9;
  bottom: 0px;
  left: unset;
  transition: var(--transition);
  transform: translate(120%, 0%);
  border: 1px solid var(--secondary-color);
}
.content .features-with-border.container-fluid .custom-next-arrow svg, .content .features-with-border.container .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 100px;
  transition: var(--transition);
}
.content .features-with-border.container-fluid .custom-next-arrow:hover, .content .features-with-border.container .custom-next-arrow:hover {
  background: var(--secondary-color);
}
.content .features-with-border.container-fluid .custom-next-arrow:hover svg, .content .features-with-border.container .custom-next-arrow:hover svg {
  color: var(--white);
}
.content .features-with-border.container-fluid .slick-list, .content .features-with-border.container .slick-list {
  margin: 0 auto;
}
.content .features-with-border.container-fluid .slick-list .slick-slide > div, .content .features-with-border.container .slick-list .slick-slide > div {
  background: transparent;
  border-radius: 30px;
  padding: 90px 55px 40px;
  margin: 0px 40px 0;
  transition: var(--transition);
  border: 1px solid var(--secondary-color);
}
.content .features-with-border.container-fluid .slick-list .slick-slide > div h4, .content .features-with-border.container .slick-list .slick-slide > div h4 {
  font-size: 25px;
  line-height: 55px;
}
.content .features-with-border.container-fluid .slick-list .slick-slide > div p, .content .features-with-border.container .slick-list .slick-slide > div p {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-weight: 500;
}
.content .slider-center-arrow.container-fluid .custom-prev-arrow, .content .slider-center-arrow.container .custom-prev-arrow {
  left: 50%;
  bottom: 0px;
  right: unset;
  transform: translate(-120%, 0%);
}
.content .slider-center-arrow.container-fluid .custom-next-arrow, .content .slider-center-arrow.container .custom-next-arrow {
  top: unset;
  right: 50%;
  bottom: 0px;
  left: unset;
  transform: translate(120%, 0%);
}
.content .slider-center-arrow.container-fluid .slick-list, .content .slider-center-arrow.container .slick-list {
  padding-bottom: 130px;
}
.content .slider-center-arrow.container-fluid .slick-list .slick-slide > div, .content .slider-center-arrow.container .slick-list .slick-slide > div {
  padding: 60px 20px 0px;
}

.content .why-us.container-fluid .slick-slider .custom-prev-arrow {
  background: var(--primary-color);
  box-shadow: unset;
  color: #353535;
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover {
  background: var(--primary-color);
  box-shadow: unset;
}
.content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover svg {
  color: var(--white);
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow {
  background: var(--primary-color);
  box-shadow: unset;
  color: #353535;
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow:hover {
  background: var(--primary-color);
  box-shadow: unset;
}
.content .why-us.container-fluid .slick-slider .custom-next-arrow:hover svg {
  color: var(--white);
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
}
.content .why-us.container-fluid a {
  padding-left: 160px;
  position: relative;
  top: -120px;
}
.content .why-us.container-fluid a:hover {
  color: var(--secondary-color) !important;
}

.content.enquiry-form {
  padding: 100px 0;
}
.content.enquiry-form .container {
  max-width: 70%;
}
.content.enquiry-form .container .content-left {
  width: 60%;
}
.content.enquiry-form .container .content-left h2, .content.enquiry-form .container .content-left h3 {
  margin-bottom: 0;
}
.content.enquiry-form .container .content-left form {
  margin-top: 20px;
}
.content.enquiry-form .container .content-left form .col {
  width: 50%;
}
.content.enquiry-form .container .content-left form .col input, .content.enquiry-form .container .content-left form .col textarea, .content.enquiry-form .container .content-left form .col select {
  background: transparent;
  border: 1px solid #919191;
}
.content.enquiry-form .container .content-left form .col input:focus, .content.enquiry-form .container .content-left form .col textarea:focus, .content.enquiry-form .container .content-left form .col select:focus {
  background-color: transparent;
  border-color: var(--secondary-color);
  outline: 0;
  box-shadow: unset;
}
.content.enquiry-form .container .content-right {
  width: 40%;
}
.content.enquiry-form .container .content-right h2 {
  font-size: 28px;
  margin-bottom: 20px;
}
.content.enquiry-form .container .content-right .contact-box {
  font-family: "Poppins";
  font-weight: 400;
}
.content.enquiry-form .container .content-right .contact-box small {
  display: block;
}
.content.enquiry-form .container .content-right .contact-box a {
  color: var(--black);
  transition: var(--transition);
}
.content.enquiry-form .container .content-right .contact-box a:hover {
  color: var(--secondary-color);
}
.content.enquiry-form .container .content-right .contact-box p {
  margin-top: 0 !important;
}

.content .why-us.container-fluid.why-us-industry-details a {
  padding-left: 0;
  top: 0;
}

.content.introduction .content-left h3 span {
  font-weight: 700;
  color: var(--secondary-color);
}

.after {
  border: solid var(--secondary-color);
  padding: 25px 15px;
  background: transparent;
  border-radius: 0;
  color: var(--secondary-color);
  position: relative;
  border-width: 1px 0px 1px 1px;
  max-width: 275px;
  text-align: left;
}
.after svg {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(-50%, -50%);
}
.after:before {
  content: "";
  position: absolute;
  right: -16px;
  top: -1px;
  bottom: 16px;
  width: 16px;
  background: transparent;
  border: solid var(--secondary-color);
  border-width: 1px 1px 0px 0px;
}
.after:after {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 8px;
  width: 24px;
  height: 1.4px;
  transform: rotate(-50deg);
  background: var(--secondary-color);
}
.after:hover {
  color: var(--white);
}

@media (min-width: 1367px) and (max-width: 1600px) {
  .industries {
    padding: 80px 0;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 30px;
    margin-top: 30px;
    width: 55% !important;
  }
  .industries .container .services-wrapper article {
    background: transparent !important;
  }
  .industries .container .services-wrapper article .link {
    font-size: 16px;
    padding: 25px 15px;
    line-height: 24px;
  }
  .after {
    max-width: 190px;
  }
}
@media (min-width: 1201px) and (max-width: 1600px) {
  .industries {
    padding: 80px 0;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 20px;
    margin-top: 30px;
    width: 65%;
  }
  .industries .container .services-wrapper article {
    background: transparent !important;
  }
  .industries .container .services-wrapper article .after {
    padding: 20px 10px 20px 15px;
  }
  .industries .container .services-wrapper article .after:before {
    right: -16px;
    top: -1px;
    bottom: 18px;
    width: 16px;
  }
  .industries .container .services-wrapper article .after:after {
    right: -20px;
    bottom: 8px;
    width: 24px;
    height: 1.4px;
    transform: rotate(-50deg);
  }
  .industries .container .services-wrapper article .link {
    font-size: 16px;
    padding: 25px 15px;
    line-height: 24px;
  }
  .content .industry-features.container-fluid, .content .industry-features.container {
    padding: 30px 40px 30px;
  }
  .content .industry-features.container-fluid .content-left, .content .industry-features.container .content-left {
    width: 62%;
  }
  .content .industry-features.container-fluid .content-right, .content .industry-features.container .content-right {
    width: 38%;
  }
  .content .industry-features.container-fluid .content-right p, .content .industry-features.container .content-right p {
    margin-top: 30px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow, .content .industry-features.container .custom-prev-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow svg, .content .industry-features.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .industry-features.container-fluid .custom-next-arrow, .content .industry-features.container .custom-next-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .industry-features.container-fluid .custom-next-arrow svg, .content .industry-features.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .industry-features.container-fluid .slick-list, .content .industry-features.container .slick-list {
    padding-bottom: 80px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div, .content .industry-features.container .slick-list .slick-slide > div {
    padding: 40px 20px 40px;
    margin: 0px 20px 0;
    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div h4, .content .industry-features.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div p, .content .industry-features.container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div:hover, .content .industry-features.container .slick-list .slick-slide > div:hover {
    box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
  }
  .content .features-with-border.container-fluid, .content .features-with-border.container {
    padding: 30px 40px 30px;
  }
  .content .features-with-border.container-fluid .content-left, .content .features-with-border.container .content-left {
    width: 62%;
  }
  .content .features-with-border.container-fluid .content-right, .content .features-with-border.container .content-right {
    width: 38%;
  }
  .content .features-with-border.container-fluid .content-right p, .content .features-with-border.container .content-right p {
    margin-top: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow, .content .features-with-border.container .custom-prev-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow svg, .content .features-with-border.container .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow, .content .features-with-border.container .custom-next-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow svg, .content .features-with-border.container .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div, .content .features-with-border.container .slick-list .slick-slide > div {
    padding: 60px 20px 50px;
    margin: 0px 20px 0;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div h4, .content .features-with-border.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div p, .content .features-with-border.container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .slider-center-arrow.container-fluid, .content .slider-center-arrow.container {
    padding: 30px 50px 40px;
  }
  .content .slider-center-arrow.container-fluid .custom-prev-arrow, .content .slider-center-arrow.container .custom-prev-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .custom-next-arrow, .content .slider-center-arrow.container .custom-next-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .slick-list, .content .slider-center-arrow.container .slick-list {
    padding-bottom: 130px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .industries {
    padding: 80px 0;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 30px;
    margin-top: 30px;
    width: 50%;
  }
  .industries .container .services-wrapper article {
    background: transparent;
  }
  .industries .container .services-wrapper article .after {
    padding: 15px 10px;
    font-size: 16px;
    max-width: 175px;
  }
  .industries .container .services-wrapper article .after:before {
    right: -16px;
    top: -1px;
    bottom: 16px;
    width: 16px;
  }
  .industries .container .services-wrapper article .after:after {
    right: -20px;
    bottom: 8px;
    width: 24px;
    height: 1.4px;
    transform: rotate(-50deg);
  }
  .industries .container .services-wrapper article .link {
    font-size: 18px;
    padding: 25px 15px;
    line-height: 24px;
  }
  .content .industry-features.container-fluid, .content .industry-features.container {
    padding: 0px 0px 0px;
  }
  .content .industry-features.container-fluid .content-left, .content .industry-features.container .content-left {
    width: 62%;
  }
  .content .industry-features.container-fluid .content-right, .content .industry-features.container .content-right {
    width: 38%;
  }
  .content .industry-features.container-fluid .content-right p, .content .industry-features.container .content-right p {
    margin-top: 30px;
  }
  .content .industry-features.container-fluid .content-full h2, .content .industry-features.container .content-full h2 {
    margin-bottom: 40px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow, .content .industry-features.container .custom-prev-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow svg, .content .industry-features.container .custom-prev-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .industry-features.container-fluid .custom-next-arrow, .content .industry-features.container .custom-next-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .industry-features.container-fluid .custom-next-arrow svg, .content .industry-features.container .custom-next-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .industry-features.container-fluid .slick-list, .content .industry-features.container .slick-list {
    padding-bottom: 75px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div, .content .industry-features.container .slick-list .slick-slide > div {
    padding: 40px 20px 40px;
    margin: 0px 20px 0;
    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div h4, .content .industry-features.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div p, .content .industry-features.container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div:hover, .content .industry-features.container .slick-list .slick-slide > div:hover {
    box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
  }
  .content .features-with-border.container-fluid, .content .features-with-border.container {
    padding: 0px 40px 0px;
  }
  .content .features-with-border.container-fluid .content-left, .content .features-with-border.container .content-left {
    width: 62%;
  }
  .content .features-with-border.container-fluid .content-right, .content .features-with-border.container .content-right {
    width: 38%;
  }
  .content .features-with-border.container-fluid .content-right p, .content .features-with-border.container .content-right p {
    margin-top: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow, .content .features-with-border.container .custom-prev-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow svg, .content .features-with-border.container .custom-prev-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow, .content .features-with-border.container .custom-next-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow svg, .content .features-with-border.container .custom-next-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div, .content .features-with-border.container .slick-list .slick-slide > div {
    padding: 40px 20px 40px;
    margin: 0px 20px 0;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div h4, .content .features-with-border.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div p, .content .features-with-border.container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .slider-center-arrow.container-fluid, .content .slider-center-arrow.container {
    padding: 30px 0px 40px;
  }
  .content .slider-center-arrow.container-fluid .custom-prev-arrow, .content .slider-center-arrow.container .custom-prev-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .custom-next-arrow, .content .slider-center-arrow.container .custom-next-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .slick-list, .content .slider-center-arrow.container .slick-list {
    padding-bottom: 120px;
  }
  .content .why-us.container-fluid {
    padding: 0px 40px 0px;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow {
    background: var(--white);
    box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    color: #353535;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover {
    box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    background: var(--white);
  }
  .content .why-us.container-fluid .slick-slider .custom-prev-arrow:hover svg {
    color: #000;
    box-shadow: unset;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow {
    background: var(--white);
    box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    color: #353535;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow svg {
    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow:hover {
    box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    background: var(--white);
  }
  .content .why-us.container-fluid .slick-slider .custom-next-arrow:hover svg {
    color: #000;
    box-shadow: unset;
  }
  .content.enquiry-form {
    padding: 90px 0;
  }
  .content.enquiry-form .container {
    max-width: 80%;
  }
  .content.enquiry-form .container .content-left {
    width: 60%;
  }
  .content.enquiry-form .container .content-left form {
    margin-top: 20px;
  }
  .content.enquiry-form .container .content-left form .col {
    width: 50%;
  }
  .content.enquiry-form .container .content-right {
    width: 40%;
  }
  .content.enquiry-form .container .content-right h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .industries {
    padding: 50px 0;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    gap: 10px 25px;
    width: 50%;
  }
  .industries .container .services-wrapper article .after {
    padding: 15px 10px;
    font-size: 16px;
  }
  .industries .container .services-wrapper article .after:before {
    right: -16px;
    top: -1px;
    bottom: 16px;
    width: 16px;
  }
  .industries .container .services-wrapper article .after:after {
    right: -20px;
    bottom: 8px;
    width: 24px;
    height: 1.4px;
    transform: rotate(-50deg);
  }
  .industries .container .services-wrapper article span {
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 55px;
  }
  .industries .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .industries .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .industries .container .services-wrapper article .link {
    font-size: 20px;
    padding: 25px 15px;
    line-height: 24px;
  }
  .content .industry-features.container-fluid, .content .industry-features.container {
    padding: 0px 0px 0px;
  }
  .content .industry-features.container-fluid .content-left, .content .industry-features.container .content-left {
    width: 62%;
  }
  .content .industry-features.container-fluid .content-right, .content .industry-features.container .content-right {
    width: 38%;
  }
  .content .industry-features.container-fluid .content-right p, .content .industry-features.container .content-right p {
    margin-top: 30px;
  }
  .content .industry-features.container-fluid .content-full h2, .content .industry-features.container .content-full h2 {
    margin-bottom: 40px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow, .content .industry-features.container-fluid .custom-next-arrow, .content .industry-features.container .custom-prev-arrow, .content .industry-features.container .custom-next-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow svg, .content .industry-features.container-fluid .custom-next-arrow svg, .content .industry-features.container .custom-prev-arrow svg, .content .industry-features.container .custom-next-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .industry-features.container-fluid .slick-list, .content .industry-features.container .slick-list {
    padding-bottom: 75px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div, .content .industry-features.container .slick-list .slick-slide > div {
    padding: 40px 20px 40px;
    margin: 0px 20px 0;
    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div h4, .content .industry-features.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div p, .content .industry-features.container .slick-list .slick-slide > div p {
    font-size: 15px;
    line-height: 28px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div:hover, .content .industry-features.container .slick-list .slick-slide > div:hover {
    box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
  }
  .content .features-with-border.container-fluid, .content .features-with-border.container {
    padding: 0px 40px 30px;
  }
  .content .features-with-border.container-fluid .content-left, .content .features-with-border.container .content-left {
    width: 50%;
  }
  .content .features-with-border.container-fluid .content-right, .content .features-with-border.container .content-right {
    width: 50%;
  }
  .content .features-with-border.container-fluid .content-right p, .content .features-with-border.container .content-right p {
    margin-top: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow, .content .features-with-border.container .custom-prev-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow svg, .content .features-with-border.container .custom-prev-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow, .content .features-with-border.container .custom-next-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 30px;
  }
  .content .features-with-border.container-fluid .custom-next-arrow svg, .content .features-with-border.container .custom-next-arrow svg {
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .content .features-with-border.container-fluid .slick-list, .content .features-with-border.container .slick-list {
    padding-bottom: 70px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div, .content .features-with-border.container .slick-list .slick-slide > div {
    padding: 40px 20px 40px;
    margin: 0px 20px 0;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div h4, .content .features-with-border.container .slick-list .slick-slide > div h4 {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div p, .content .features-with-border.container .slick-list .slick-slide > div p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .slider-center-arrow.container-fluid .custom-prev-arrow, .content .slider-center-arrow.container .custom-prev-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .custom-next-arrow, .content .slider-center-arrow.container .custom-next-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content .slider-center-arrow.container-fluid .slick-list, .content .slider-center-arrow.container .slick-list {
    padding-bottom: 120px;
  }
  .content .ind.why-us.container-fluid {
    padding: 0px 0px 0px;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-prev-arrow {
    background: var(--white);
    box-shadow: 0px 8px 14px -4px rgba(219, 219, 219, 0.6196078431);
    color: #353535;
    line-height: 35px;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-prev-arrow svg {
    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-prev-arrow:hover {
    box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    background: var(--white);
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-prev-arrow:hover svg {
    color: #000;
    box-shadow: unset;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-next-arrow {
    background: var(--white);
    box-shadow: 0px 8px 14px -4px rgba(219, 219, 219, 0.6196078431);
    color: #353535;
    line-height: 35px;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-next-arrow svg {
    box-shadow: inset 0px 3px 5px 1px #c7c7c7;
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-next-arrow:hover {
    box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
    background: var(--white);
  }
  .content .ind.why-us.container-fluid .slick-slider .custom-next-arrow:hover svg {
    color: #000;
    box-shadow: unset;
  }
  .content.enquiry-form {
    padding: 80px 0;
  }
  .content.enquiry-form .container {
    max-width: 90%;
  }
  .content.enquiry-form .container .content-left {
    width: 60%;
  }
  .content.enquiry-form .container .content-left form {
    margin-top: 20px;
  }
  .content.enquiry-form .container .content-left form .col {
    width: 50%;
  }
  .content.enquiry-form .container .content-right {
    width: 40%;
  }
  .content.enquiry-form .container .content-right h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .after {
    max-width: 175px;
  }
}
@media (max-width: 992px) {
  .industries .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    gap: 10px 0px;
    width: 68%;
  }
  .industries .container .services-wrapper article .after {
    padding: 15px 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .industries .container .services-wrapper article .after:before {
    right: -16px;
    top: -1px;
    bottom: 16px;
    width: 16px;
  }
  .industries .container .services-wrapper article .after:after {
    right: -20px;
    bottom: 8px;
    width: 24px;
    height: 1.4px;
    transform: rotate(-50deg);
  }
  .content.enquiry-form {
    padding: 70px 0;
  }
  .content.enquiry-form .container {
    max-width: 90%;
  }
  .content.enquiry-form .container .content-left {
    width: 60%;
  }
  .content.enquiry-form .container .content-left form .col {
    width: 50%;
  }
  .content.enquiry-form .container .content-right {
    width: 40%;
  }
  .content.enquiry-form .container .content-right h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .content .why-us.container-fluid .slick-slider .slick-list {
    padding-bottom: 60px;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .industries {
    padding: 40px 0;
    position: relative;
  }
  .industries:before {
    content: "";
    background: #000;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .industries .container {
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
    gap: 10px 10px !important;
  }
  .industries .container p {
    text-align: center !important;
    line-height: 28px;
  }
  .industries .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    width: 100%;
  }
  .industries .container .services-wrapper article span {
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 55px;
  }
  .industries .container .services-wrapper article .link {
    font-size: 18px;
  }
  .content.enquiry-form {
    padding: 60px 0;
  }
  .content.enquiry-form .container {
    max-width: 100%;
    flex-direction: column;
  }
  .content.enquiry-form .container .content-left {
    width: 100%;
  }
  .content.enquiry-form .container .content-left form {
    margin-bottom: 50px;
  }
  .content.enquiry-form .container .content-left form .col {
    width: 50%;
  }
  .content.enquiry-form .container .content-left form .button-wrap {
    text-align: center;
  }
  .content.enquiry-form .container .content-right {
    width: 100%;
  }
  .content.enquiry-form .container .content-right h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .content .industry-features.container-fluid, .content .industry-features.container {
    padding: 0px 0px 0px;
    flex-direction: column-reverse;
  }
  .content .industry-features.container-fluid .content-left, .content .industry-features.container .content-left {
    width: 100%;
  }
  .content .industry-features.container-fluid .content-right, .content .industry-features.container .content-right {
    width: 100%;
  }
  .content .industry-features.container-fluid .content-right a.button, .content .industry-features.container .content-right a.button {
    display: block;
    text-align: center;
  }
  .content .industry-features.container-fluid .slick-list, .content .industry-features.container .slick-list {
    padding-bottom: 60px;
  }
  .content .industry-features.container-fluid .slick-list .slick-slide > div, .content .industry-features.container .slick-list .slick-slide > div {
    padding: 20px 0;
    margin: 0;
    box-shadow: unset;
  }
  .content .industry-features.container-fluid .custom-prev-arrow, .content .industry-features.container-fluid .custom-next-arrow, .content .industry-features.container .custom-prev-arrow, .content .industry-features.container .custom-next-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .content .industry-features.container-fluid .custom-prev-arrow svg, .content .industry-features.container-fluid .custom-next-arrow svg, .content .industry-features.container .custom-prev-arrow svg, .content .industry-features.container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .features-with-border.container-fluid, .content .features-with-border.container {
    gap: 0;
    padding: 0px 10px 30px;
  }
  .content .features-with-border.container-fluid .content-right h2, .content .features-with-border.container-fluid .content-right h3, .content .features-with-border.container .content-right h2, .content .features-with-border.container .content-right h3 {
    text-align: left;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow, .content .features-with-border.container-fluid .custom-next-arrow, .content .features-with-border.container .custom-prev-arrow, .content .features-with-border.container .custom-next-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .content .features-with-border.container-fluid .custom-prev-arrow svg, .content .features-with-border.container-fluid .custom-next-arrow svg, .content .features-with-border.container .custom-prev-arrow svg, .content .features-with-border.container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .features-with-border.container-fluid .slick-list .slick-slide > div, .content .features-with-border.container .slick-list .slick-slide > div {
    margin: 0px 10px 0;
    height: 100% !important;
  }
  .content .case-studies.container-fluid, .content .case-studies.container {
    padding: 20px 0px 20px;
    margin-bottom: 0px;
    flex-direction: column-reverse;
  }
  .content .case-studies.container-fluid .slick-list, .content .case-studies.container .slick-list {
    padding-bottom: 60px;
  }
  .content .case-studies.container-fluid .slick-list .slick-slide > div, .content .case-studies.container .slick-list .slick-slide > div {
    padding: 0;
    margin: 0;
    box-shadow: unset;
  }
  .content .case-studies.container-fluid .custom-prev-arrow, .content .case-studies.container .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content .case-studies.container-fluid .custom-prev-arrow svg, .content .case-studies.container .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content .case-studies.container-fluid .custom-next-arrow, .content .case-studies.container .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content .case-studies.container-fluid .custom-next-arrow svg, .content .case-studies.container .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}
@media (max-width: 600px) {
  .industries {
    padding: 40px 0;
  }
  .industries .container {
    flex-wrap: wrap;
  }
  .industries .container p {
    line-height: 24px;
  }
  .industries .container .content-full {
    padding: 0 15px 0 0 !important;
  }
  .industries .container .content-full .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    width: 100%;
  }
  .industries .container .content-full .services-wrapper article {
    display: inline-grid;
  }
  .industries .container .content-full .services-wrapper article .after {
    font-size: 14px;
    line-height: 20px;
    min-height: 55px;
    padding: 0 10px;
    text-align: left;
  }
  .industries .container .content-full .services-wrapper article .link {
    font-size: 14px;
    padding: 20px 10px;
  }
  .content.enquiry-form {
    padding: 60px 0 40px;
  }
  .content.enquiry-form .container .content-left form .col {
    width: 100%;
  }
  .content.enquiry-form .container .content-right h2 {
    font-size: 20px;
  }
  .content.enquiry-form .container .content-right .contact-box {
    text-align: center;
  }
  .content.enquiry-form .container .content-right .contact-box p {
    text-align: center;
  }
  .content .features-with-border.container-fluid, .content .features-with-border.container {
    flex-direction: column-reverse;
    padding: 0px 10px 30px;
  }
  .content .features-with-border.container-fluid .content-left, .content .features-with-border.container .content-left {
    width: 100%;
  }
  .content .features-with-border.container-fluid .content-right, .content .features-with-border.container .content-right {
    width: 100%;
    margin-bottom: 30px;
  }
  .content .features-with-border.container-fluid .content-right h2, .content .features-with-border.container-fluid .content-right h3, .content .features-with-border.container .content-right h2, .content .features-with-border.container .content-right h3 {
    text-align: center;
  }
  .content .features-with-border.container-fluid .content-right a, .content .features-with-border.container .content-right a {
    display: block;
    text-align: center;
  }
  .content .why-us.container-fluid {
    padding: 0px 10px 0px;
  }
  .content .why-us.container-fluid a.button {
    display: block;
    text-align: center;
  }
  .industries .container .services-wrapper {
    gap: 10px 25px;
  }
}/*# sourceMappingURL=industries.css.map */