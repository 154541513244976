.services {
    padding: 80px 0;

    .container {
        position: relative;
        .services-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-top: 30px;

            article {
                border: 1px solid #171e2e;
                border-radius: 15px;
                text-align: center;
                transition: var(--transition);
                background: var(--white);

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 100px;
                    display: inline-block;
                }

                h4 {
                    margin-top: 10px;
                    color: var(--black);
                }

                small {
                    width: 100%;
                    display: block;
                    line-height: 24px;
                    margin: 10px 0;
                    color: var(--black);
                }

                .link {
                    color: var(--secondary-color);
                    font-size: 16px;
                    text-decoration: none;
                    padding: 35px 30px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 24px;
                    transition: var(--transition);
                    flex-direction: column;
                    cursor: pointer;

                    small {
                        color: var(--black);
                    }

                    &:hover {
                        color: var(--black);
                    }
                }

                &:hover {
                    // background: var(--secondary-color);
                    border: 1px solid var(--secondary-color);

                    img {
                        border: 2px solid var(--primary-color);
                    }

                    h4 {
                        color: var(--black);
                    }

                    small {
                        color: var(--black);
                    }

                    link {
                        color: var(--black);

                        &:hover {
                            color: var(--black);
                        }
                    }
                }
            }
        }

        .content-left {
            padding-left: 115px !important;
        }
        .service_sec.service-page-service-slider {
            position: relative;
            .slick-slider {
                position: unset;
                
                .custom-prev-arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 30px;
                    background: #fff;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 100px;
                    box-shadow: 0px 14px 14px -4px #dbdbdb9e;
                    color: #b6b6b6;
                    font-size: 30px;
                    cursor: pointer;

                    svg {
                        width: 60px;
                        height: 60px;
                        padding: 15px;
                        box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                        border-radius: 100px;
                        transition: var(--transition);
                    }

                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }

                .custom-next-arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 110px;
                    background: #fff;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 100px;
                    box-shadow: 0px 14px 14px -4px #dbdbdb9e;
                    color: #b6b6b6;
                    font-size: 30px;
                    cursor: pointer;

                    svg {
                        width: 60px;
                        height: 60px;
                        padding: 15px;
                        box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                        border-radius: 100px;
                        transition: var(--transition);
                    }

                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }

                .slick-list {
                    padding-bottom: 120px;

                    .slick-slide > div {
                        border: 0;
                        margin: 30px;
                        box-shadow: 4px 5px 15px 0px rgba(204, 204, 204, 0.58);
                        padding: 40px 20px 0;
                        border-radius: 45px;
                        position: relative;
                        overflow: hidden;

                        &:hover {
                            box-shadow: inset 10px 10px 20px 0px rgb(204 204 204 / 58%);
                        }

                        .item {

                            .link {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
        
                            b {
                                font-size: 25px;
                                line-height: 35px;
                                font-weight: 800;
                                text-transform: capitalize;
                                margin: 15px 0;
                            }
        
                            p {
                                margin-top: 0;
                                text-align: center;
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
        .service_sec.home-page-service-slider {
            .slick-slider {
                position: unset;
                
                .custom-prev-arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 125px;
                    background: var(--primary-color);
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 100px;
                    color: #b6b6b6;
                    font-size: 30px;
                    cursor: pointer;

                    svg {
                        width: 60px;
                        height: 60px;
                        padding: 12px;
                        box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                        border-radius: 100px;
                        transition: var(--transition);
                    }

                    &:hover {
                        box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
                        svg {
                            color: var(--white);
                            box-shadow: unset;
                        }
                    }
                }

                .custom-next-arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 200px;
                    background: var(--primary-color);
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 100px;
                    color: #b6b6b6;
                    font-size: 30px;
                    cursor: pointer;

                    svg {
                        width: 60px;
                        height: 60px;
                        padding: 12px;
                        box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                        border-radius: 100px;
                        transition: var(--transition);
                    }

                    &:hover {
                        box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
                        svg {
                            color: var(--white);
                            box-shadow: unset;
                        }
                    }
                }

                .slick-list {
                    padding-bottom: 40px;

                    .slick-slide > div {
                        border: 0;
                        margin: 30px;
                        box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
                        padding: 40px 20px 0;
                        border-radius: 45px;
                        position: relative;
                        overflow: hidden;

                        &:hover {
                            box-shadow: inset 5px 5px 22px 0px var(--primary-box-shadow);
                        }

                        .item {

                            .link {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }

                            .iconbox {
                                width: 68px;
                                height: 68px;
                                margin: 0 auto;
                            }
        
                            b {
                                font-size: 25px;
                                line-height: 35px;
                                font-weight: 800;
                                text-transform: capitalize;
                                margin: 15px 0;
                            }
        
                            p {
                                margin-top: 0;
                                text-align: center;
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .service-page-case-studies {
        .custom-prev-arrow {
            top: unset;
            left: 50%;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            bottom: 0;
            right: unset;
            transform: translate(-110%, 0%);
        }
        .custom-next-arrow {
            top: unset;
            right: 50%;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            bottom: 0;
            left: unset;
            transform: translate(110%, 0%);
        }
    }
}

.content {
    .container.service-contact-box {
        padding: 0 90px;

        .content-left {
            width: 55%;

            h2, h3 {
                padding-left: 20px;
            }
        }

        .content-right {
            width: 45%;
            padding-left: 50px;

        }

        h2 {
            margin-bottom: 0px;
        }

        form {
            margin-top: 20px;
        }

        .contact-box {
            margin-top: 25px;
            font-family: 'Poppins', sans-serif;
        }
    }

    .case-studies.service-case-studies.slider-center-arrow {
        .slick-slider {
            .slick-list {
                .slick-slide > div {
                    border: 0;
                    margin: 60px 20px 20px;
                    box-shadow: 10px 10px 20px 0px rgba(204, 204, 204, 0.58);
                    padding: 40px 20px 0;
                    border-radius: 45px;
                    position: relative;
                    overflow: hidden;
                    background: #fff;
                    transition: var(--transition);

                    &:hover {
                        box-shadow: inset 0px 0px 25px 0px rgba(204, 204, 204, 0.58), 10px 10px 10px 0px transparent;
                    }
                }
            }
            .custom-prev-arrow {
                bottom: 0;
                left: 50%;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                transform: translate(-110%, 0%);
                right: unset;
                background: #fff;
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                    border-radius: 100px;
                    transition: var(--transition);
                }
            }
            .custom-next-arrow {
                bottom: 0;
                left: unset;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                right: 50%;
                transform: translate(110%, 0%);
                background: #fff;
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                    box-shadow: inset 0px 3px 5px 1px #dbdbdb;
                    border-radius: 100px;
                    transition: var(--transition);
                }
            }
        }
    }
}

.mb--120px {
    margin-bottom: -120px;
}

@media (min-width: 1367px) and (max-width: 1600px) {
}

@media (min-width: 1201px) and (max-width: 1600px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
    
            .content-left {
                width: 40%;
                padding-left: 60px !important;
                .servic_desc {
                    margin-bottom: 40px;
                }
            }
    
            .content-right {
                width: 60%;
            }

            .service_sec.service-page-service-slider {
                position: relative;
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0;
                        left: 50%;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        transform: translate(-110%, 0%);
                        right: unset;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0;
                        left: unset;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        right: 50%;
                        transform: translate(110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        padding-bottom: 100px;

                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px 0;
    
                            .item {
        
                                b {
                                    font-size: 18px;
                                    line-height: 24px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 14px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }

            .service_sec.home-page-service-slider {
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0px;
                        left: 70px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0px;
                        left: 130px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px 0;
    
                            .item {
        
                                b {
                                    font-size: 18px;
                                    line-height: 24px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 14px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // .content {
    //     .container-fluid.service-page-case-studies {
    //         padding-bottom: 50px;
    //         .custom-prev-arrow {
    //             top: unset;
    //             left: 50%;
    //             bottom: 0;
    //             right: unset;
    //             transform: translate(-110%, 0%);
    //         }
    //         .custom-next-arrow {
    //             top: unset;
    //             right: 50%;
    //             bottom: 0;
    //             left: unset;
    //             transform: translate(110%, 0%);
    //         }
    //     }
    // }

}

@media (min-width: 1025px) and (max-width: 1200px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
    
            .content-left {
                width: 40%;
                padding-left: 60px !important;
            }
    
            .content-right {
                width: 60%;
            }

            .service_sec.service-page-service-slider {
                position: relative;
                .slick-slider {

                    .slick-list {
                        padding-bottom: 100px;
                    }
                    
                    .custom-prev-arrow {
                        bottom: 0;
                        left: 50%;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        transform: translate(-110%, 0%);
                        right: unset;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0;
                        left: unset;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        right: 50%;
                        transform: translate(110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .service_sec.home-page-service-slider {
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0px;
                        left: 70px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0px;
                        left: 130px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
                            // box-shadow: 10px 10px 10px 0px rgba(204, 204, 204, 0.58);
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        .container-fluid.service-page-case-studies {
            padding-bottom: 50px;

            .slick-list {
                padding-bottom: 100px;
            }

            .custom-prev-arrow {
                top: unset;
                left: 50%;
                bottom: 0;
                right: unset;
                transform: translate(-110%, 0%);
            }
            .custom-next-arrow {
                top: unset;
                right: 50%;
                bottom: 0;
                left: unset;
                transform: translate(110%, 0%);
            }
        }
    }

    .content {
        .container.service-contact-box {
            padding: 0 50px;
    
            .content-left {
                width: 55%;
            }
    
            .content-right {
                width: 45%;
                padding-left: 30px;    
            }
    
            form {
                margin-top: 20px;
            }
    
            .contact-box {
                margin-top: 25px;
            }
        }
    }
}
 
@media screen and (max-width: 1024px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
    
            .content-left {
                width: 50%;
                padding-left: 60px !important;
            }
    
            .content-right {
                width: 50%;
            }

            .service_sec.service-page-service-slider {
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0px;
                        left: 50%;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        right: unset;
                        transform: translate(-110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0px;
                        left: unset;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        right: 50%;
                        transform: translate(110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        padding-bottom: 90px;

                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        .container.service-contact-box {
            padding: 0 50px;
    
            .content-left {
                width: 55%;
            }
    
            .content-right {
                width: 45%;
                padding-left: 30px;    
            }
    
            form {
                margin-top: 20px;
            }
    
            .contact-box {
                margin-top: 25px;
            }
        }
    }

}

@media (min-width: 993px) and (max-width: 1024px) {
}

@media (max-width: 992px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
    
            .content-left {
                width: 50%;
                padding-left: 60px !important;
                a {
                    display: block;
                    text-align: center;
                }
            }
    
            .content-right {
                width: 50%;
            }

            .service_sec.service-page-service-slider {
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0px;
                        left: 50%;
                        right: unset;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        transform: translate(-110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0px;
                        left: unset;
                        right: 50%;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
                        transform: translate(110%, 0%);
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        padding-bottom: 90px;

                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .service_sec.home-page-service-slider {
                .slick-slider {
                    
                    .custom-prev-arrow {
                        bottom: 0px;
                        left: 70px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .custom-next-arrow {
                        bottom: 0px;
                        left: 130px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 30px;
    
                        svg {
                            width: 50px;
                            height: 50px;
                            padding: 15px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
                            box-shadow: 10px 10px 10px 0px rgba(204, 204, 204, 0.58);
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        .container.service-contact-box {
            padding: 0 0px;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (max-width: 767px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            flex-direction: column;

            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
    
            .content-left {
                width: 100%;
                padding-left: 0px !important;

                p {
                    text-align: center;
                }
            }
    
            .content-right {
                width: 100%;
            }

            .service_sec.service-page-service-slider {
                .slick-slider {

                    .slick-list {
                        padding-bottom: 80px;
                    }

                    .custom-prev-arrow {
                        top: unset;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        bottom: 0;
                        transform: translate(-100%, 0%);
                        right: unset;
                        z-index: 9;

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
                    .custom-next-arrow {
                        top: unset;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        left: 50%;
                        bottom: 0%;
                        transform: translate(10%, 0%);

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px;
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .service_sec.home-page-service-slider {
                .slick-slider {

                    .slick-list {
                        padding-bottom: 90px;
                    }

                    .custom-prev-arrow {
                        top: unset;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        bottom: 0;
                        transform: translate(-100%, 0%);
                        right: unset;
                        z-index: 9;

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
                    .custom-next-arrow {
                        top: unset;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        left: 50%;
                        bottom: 0%;
                        transform: translate(10%, 0%);

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 20px;
                            padding: 40px 20px 0;
                            box-shadow: 10px 10px 10px 0px rgba(204, 204, 204, 0.58);
        
                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }
        
                                p {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .service-contact-box {
        flex-direction: column;

        form {
            margin-bottom: 50px;
        }
    
        .contact-box {
            text-align: center;
        }
    }

    .content {
        .container.service-contact-box {
    
            .content-left {
                width: 100%;
            }
    
            .content-right {
                width: 100%;
                padding-left: 0px;    
            }
        }
    }

    .content {
        .container.service-contact-box {
            padding: 0 0px;
    
            .content-left {
                width: 100%;
            }
    
            .content-right {
                width: 100%;
                padding-left: 0px;   
            }
    
            form {
                button {
                    display: flex;
                    margin: 0 auto;
                }
            }
    
            .contact-box {
                margin-top: 20px;
            }
        }
    }

}

@media (min-width: 571px) and (max-width: 767px) {
}

@media (max-width: 600px) {

    .content.services {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-top: 30px;
    
                article {
    
                    img {
                        width: 70px;
                        height: 70px;
                    }
    
                    h4 {
                        margin-top: 10px;
                    }
    
                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 35px 30px;
                        line-height: 24px;
                    }
                }
            }
            .content-left {
                a {
                    display: block;
                    text-align: center;
                }
            }

            .service_sec.service-page-service-slider {
                .slick-slider {

                    .slick-list {
                        padding-bottom: 90px;
                    }

                    .custom-prev-arrow {
                        top: unset;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        bottom: 0;
                        transform: translate(-100%, 0%);
                        right: unset;
                        z-index: 9;

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
                    .custom-next-arrow {
                        top: unset;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        left: 50%;
                        bottom: 0%;
                        transform: translate(10%, 0%);

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            margin: 0px;
                            padding: 0px;
                            box-shadow: unset;

                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }

                                p {
                                    font-size: 16px;
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                }
            }

            .service_sec.home-page-service-slider {
                .slick-slider {

                    .slick-list {
                        padding-bottom: 60px;
                    }

                    .custom-prev-arrow {
                        top: unset;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        bottom: 0;
                        transform: translate(-100%, 0%);
                        right: unset;
                        z-index: 9;

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
                    .custom-next-arrow {
                        top: unset;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        left: 50%;
                        bottom: 0%;
                        transform: translate(10%, 0%);

                        svg {
                            width: 40px;
                            height: 40px;
                            padding: 10px;
                        }
                    }
    
                    .slick-list {
                        .slick-slide > div {
                            // margin: 20px;
                            // padding: 40px 20px 20px;
                            // box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.58);
                            margin: 0px;
                            padding: 20px 0px;
                            box-shadow: unset;

                            .item {
        
                                b {
                                    font-size: 20px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                }

                                p {
                                    font-size: 16px;
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}