.readmore {
  width: 20%;
  color: #000;
  text-decoration: underline;
}

.readmore:hover {
  color: #000;
}

.blog-wrapr .breadcrumb {
  background-color: transparent;
  justify-content: center;
}

.blog-wrapr .breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">" !important;
}

.breadcrumb-item a {
  color: #6c757d;
}

.breadcrumb-item.active {
  color: #0b0b0c;
  font-weight: 600;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: black !important;
}

.box {
  width: 40%;
  margin: 0 auto;
  padding: 35px;
  border: 1px solid rgb(253, 255, 251);
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}
/* ////////////readmore for related pages//////////// */

.expandMore {
  width: 10%;
  color: #332e2e;
  text-decoration: underline;
}

/* ///////////////////////// */
.board {
  display: inline-block;
  width: 100px;
  height: 100px;
  padding: 5px;
  border: 1px solid rgb(78, 78, 78);    
  background-color: rgb(233, 233, 226); 
}
/* /////////////////////// */

/* ////////////animation starts/////////////// */

.animate {
  opacity: 0;
  animation: fadeIn .8s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ////////animation ends////////////// */