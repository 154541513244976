.services {
  padding: 80px 0;
}
.services .container {
  position: relative;
}
.services .container .services-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}
.services .container .services-wrapper article {
  border: 1px solid #171e2e;
  border-radius: 15px;
  text-align: center;
  transition: var(--transition);
  background: var(--white);
}
.services .container .services-wrapper article img {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: inline-block;
}
.services .container .services-wrapper article h4 {
  margin-top: 10px;
  color: var(--black);
}
.services .container .services-wrapper article small {
  width: 100%;
  display: block;
  line-height: 24px;
  margin: 10px 0;
  color: var(--black);
}
.services .container .services-wrapper article .link {
  color: var(--secondary-color);
  font-size: 16px;
  text-decoration: none;
  padding: 35px 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  transition: var(--transition);
  flex-direction: column;
  cursor: pointer;
}
.services .container .services-wrapper article .link small {
  color: var(--black);
}
.services .container .services-wrapper article .link:hover {
  color: var(--black);
}
.services .container .services-wrapper article:hover {
  border: 1px solid var(--secondary-color);
}
.services .container .services-wrapper article:hover img {
  border: 2px solid var(--primary-color);
}
.services .container .services-wrapper article:hover h4 {
  color: var(--black);
}
.services .container .services-wrapper article:hover small {
  color: var(--black);
}
.services .container .services-wrapper article:hover link {
  color: var(--black);
}
.services .container .services-wrapper article:hover link:hover {
  color: var(--black);
}
.services .container .content-left {
  padding-left: 115px !important;
}
.services .container .service_sec.service-page-service-slider {
  position: relative;
}
.services .container .service_sec.service-page-service-slider .slick-slider {
  position: unset;
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
  position: absolute;
  bottom: 0px;
  left: 30px;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
  position: absolute;
  bottom: 0px;
  left: 110px;
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow:hover {
  box-shadow: 5px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
}
.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow:hover svg {
  color: #000;
  box-shadow: unset;
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
  padding-bottom: 120px;
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
  border: 0;
  margin: 30px;
  box-shadow: 4px 5px 15px 0px rgba(204, 204, 204, 0.58);
  padding: 40px 20px 0;
  border-radius: 45px;
  position: relative;
  overflow: hidden;
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div:hover {
  box-shadow: inset 10px 10px 20px 0px rgba(204, 204, 204, 0.58);
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item .link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
  font-size: 25px;
  line-height: 35px;
  font-weight: 800;
  text-transform: capitalize;
  margin: 15px 0;
}
.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
  margin-top: 0;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}
.services .container .service_sec.home-page-service-slider .slick-slider {
  position: unset;
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
  position: absolute;
  bottom: 0px;
  left: 125px;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
  border-radius: 100px;
  transition: var(--transition);
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow:hover {
  box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
  position: absolute;
  bottom: 0px;
  left: 200px;
  background: var(--primary-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  color: #b6b6b6;
  font-size: 30px;
  cursor: pointer;
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
  width: 60px;
  height: 60px;
  padding: 12px;
  box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
  border-radius: 100px;
  transition: var(--transition);
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow:hover {
  box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
}
.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow:hover svg {
  color: var(--white);
  box-shadow: unset;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list {
  padding-bottom: 40px;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
  border: 0;
  margin: 30px;
  box-shadow: 5px 5px 15px -5px var(--primary-box-shadow);
  padding: 40px 20px 0;
  border-radius: 45px;
  position: relative;
  overflow: hidden;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div:hover {
  box-shadow: inset 5px 5px 22px 0px var(--primary-box-shadow);
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item .link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item .iconbox {
  width: 68px;
  height: 68px;
  margin: 0 auto;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
  font-size: 25px;
  line-height: 35px;
  font-weight: 800;
  text-transform: capitalize;
  margin: 15px 0;
}
.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
  margin-top: 0;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}
.services .service-page-case-studies .custom-prev-arrow {
  top: unset;
  left: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  bottom: 0;
  right: unset;
  transform: translate(-110%, 0%);
}
.services .service-page-case-studies .custom-next-arrow {
  top: unset;
  right: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  bottom: 0;
  left: unset;
  transform: translate(110%, 0%);
}

.content .container.service-contact-box {
  padding: 0 90px;
}
.content .container.service-contact-box .content-left {
  width: 55%;
}
.content .container.service-contact-box .content-left h2, .content .container.service-contact-box .content-left h3 {
  padding-left: 20px;
}
.content .container.service-contact-box .content-right {
  width: 45%;
  padding-left: 50px;
}
.content .container.service-contact-box h2 {
  margin-bottom: 0px;
}
.content .container.service-contact-box form {
  margin-top: 20px;
}
.content .container.service-contact-box .contact-box {
  margin-top: 25px;
  font-family: "Poppins", sans-serif;
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .slick-list .slick-slide > div {
  border: 0;
  margin: 60px 20px 20px;
  box-shadow: 10px 10px 20px 0px rgba(204, 204, 204, 0.58);
  padding: 40px 20px 0;
  border-radius: 45px;
  position: relative;
  overflow: hidden;
  background: #fff;
  transition: var(--transition);
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .slick-list .slick-slide > div:hover {
  box-shadow: inset 0px 0px 25px 0px rgba(204, 204, 204, 0.58), 10px 10px 10px 0px transparent;
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .custom-prev-arrow {
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  transform: translate(-110%, 0%);
  right: unset;
  background: #fff;
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .custom-prev-arrow svg {
  width: 50px;
  height: 50px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .custom-next-arrow {
  bottom: 0;
  left: unset;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  right: 50%;
  transform: translate(110%, 0%);
  background: #fff;
}
.content .case-studies.service-case-studies.slider-center-arrow .slick-slider .custom-next-arrow svg {
  width: 50px;
  height: 50px;
  padding: 15px;
  box-shadow: inset 0px 3px 5px 1px #dbdbdb;
  border-radius: 100px;
  transition: var(--transition);
}

.mb--120px {
  margin-bottom: -120px;
}

@media (min-width: 1201px) and (max-width: 1600px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left {
    width: 40%;
    padding-left: 60px !important;
  }
  .content.services .container .content-left .servic_desc {
    margin-bottom: 40px;
  }
  .content.services .container .content-right {
    width: 60%;
  }
  .content.services .container .service_sec.service-page-service-slider {
    position: relative;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    transform: translate(-110%, 0%);
    right: unset;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    right: 50%;
    transform: translate(110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 100px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 14px;
    line-height: 26px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0px;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0px;
    left: 130px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left {
    width: 40%;
    padding-left: 60px !important;
  }
  .content.services .container .content-right {
    width: 60%;
  }
  .content.services .container .service_sec.service-page-service-slider {
    position: relative;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 100px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    transform: translate(-110%, 0%);
    right: unset;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    right: 50%;
    transform: translate(110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0px;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0px;
    left: 130px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .container-fluid.service-page-case-studies {
    padding-bottom: 50px;
  }
  .content .container-fluid.service-page-case-studies .slick-list {
    padding-bottom: 100px;
  }
  .content .container-fluid.service-page-case-studies .custom-prev-arrow {
    top: unset;
    left: 50%;
    bottom: 0;
    right: unset;
    transform: translate(-110%, 0%);
  }
  .content .container-fluid.service-page-case-studies .custom-next-arrow {
    top: unset;
    right: 50%;
    bottom: 0;
    left: unset;
    transform: translate(110%, 0%);
  }
  .content .container.service-contact-box {
    padding: 0 50px;
  }
  .content .container.service-contact-box .content-left {
    width: 55%;
  }
  .content .container.service-contact-box .content-right {
    width: 45%;
    padding-left: 30px;
  }
  .content .container.service-contact-box form {
    margin-top: 20px;
  }
  .content .container.service-contact-box .contact-box {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left {
    width: 50%;
    padding-left: 60px !important;
  }
  .content.services .container .content-right {
    width: 50%;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0px;
    left: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    right: unset;
    transform: translate(-110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0px;
    left: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    right: 50%;
    transform: translate(110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 90px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .container.service-contact-box {
    padding: 0 50px;
  }
  .content .container.service-contact-box .content-left {
    width: 55%;
  }
  .content .container.service-contact-box .content-right {
    width: 45%;
    padding-left: 30px;
  }
  .content .container.service-contact-box form {
    margin-top: 20px;
  }
  .content .container.service-contact-box .contact-box {
    margin-top: 25px;
  }
}
@media (max-width: 992px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left {
    width: 50%;
    padding-left: 60px !important;
  }
  .content.services .container .content-left a {
    display: block;
    text-align: center;
  }
  .content.services .container .content-right {
    width: 50%;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0px;
    left: 50%;
    right: unset;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    transform: translate(-110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0px;
    left: unset;
    right: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    transform: translate(110%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 90px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
    bottom: 0px;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
    bottom: 0px;
    left: 130px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
    box-shadow: 10px 10px 10px 0px rgba(204, 204, 204, 0.58);
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content .container.service-contact-box {
    padding: 0 0px;
  }
}
@media (max-width: 767px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container {
    flex-direction: column;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left {
    width: 100%;
    padding-left: 0px !important;
  }
  .content.services .container .content-left p {
    text-align: center;
  }
  .content.services .container .content-right {
    width: 100%;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 80px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list {
    padding-bottom: 90px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 20px;
    padding: 40px 20px 0;
    box-shadow: 10px 10px 10px 0px rgba(204, 204, 204, 0.58);
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 17px;
    line-height: 30px;
  }
  .service-contact-box {
    flex-direction: column;
  }
  .service-contact-box form {
    margin-bottom: 50px;
  }
  .service-contact-box .contact-box {
    text-align: center;
  }
  .content .container.service-contact-box .content-left {
    width: 100%;
  }
  .content .container.service-contact-box .content-right {
    width: 100%;
    padding-left: 0px;
  }
  .content .container.service-contact-box {
    padding: 0 0px;
  }
  .content .container.service-contact-box .content-left {
    width: 100%;
  }
  .content .container.service-contact-box .content-right {
    width: 100%;
    padding-left: 0px;
  }
  .content .container.service-contact-box form button {
    display: flex;
    margin: 0 auto;
  }
  .content .container.service-contact-box .contact-box {
    margin-top: 20px;
  }
}
@media (max-width: 600px) {
  .content.services {
    padding: 80px 0;
  }
  .content.services .container .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .content.services .container .services-wrapper article img {
    width: 70px;
    height: 70px;
  }
  .content.services .container .services-wrapper article h4 {
    margin-top: 10px;
  }
  .content.services .container .services-wrapper article small {
    line-height: 24px;
    margin: 10px 0;
  }
  .content.services .container .services-wrapper article .link {
    font-size: 16px;
    padding: 35px 30px;
    line-height: 24px;
  }
  .content.services .container .content-left a {
    display: block;
    text-align: center;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list {
    padding-bottom: 90px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 0px;
    padding: 0px;
    box-shadow: unset;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.service-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 16px;
    line-height: 28px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list {
    padding-bottom: 60px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow {
    top: unset;
    left: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translate(-100%, 0%);
    right: unset;
    z-index: 9;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-prev-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow {
    top: unset;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    left: 50%;
    bottom: 0%;
    transform: translate(10%, 0%);
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .custom-next-arrow svg {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div {
    margin: 0px;
    padding: 20px 0px;
    box-shadow: unset;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item b {
    font-size: 20px;
    line-height: 28px;
    margin: 15px 0;
  }
  .content.services .container .service_sec.home-page-service-slider .slick-slider .slick-list .slick-slide > div .item p {
    font-size: 16px;
    line-height: 28px;
  }
}/*# sourceMappingURL=services.css.map */