// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Raleway', sans-serif;
    src: url('./fonts/Raleway-Light.ttf') format('truetype');
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --container-width-lg: 87%;
    --container-width-md: 90%;
    --container-width-sm: 95%;
    --primary-color: #060332;
    --secondary-color: #2cedff;

    --bg-menu-level-1: #596eb1;
    --bg-menu-level-2: #7a8ecd;
    --bg-menu-level-1-hover: #4d61a1;

    --white: #fff;
    --black: #000;
    --grey: #2c2c2c;
    --light-gray: #F2F2F2;
    --light-blue: #A7D5EC;
    --card-border: #cbcbcb;
    --primary-box-shadow: #474576;
    --transition: all 500ms ease;
    --exo: 'Exo 2', sans-serif;
    --raleway: 'Raleway', sans-serif;
    --cr_white: 0, 0%, 100%;
    --cr_shuttle-gray: 215, 15%, 39%;
    --cr_puerto-rico: 171, 76%, 45%;
    --cr_big-stone: 216, 33%, 18%;
    --ft-wt-500: 500;
    --ft-se-400: 0.9rem;
    --size-200: 0.5em;
    --size-300: 0.8em;
    --size-400: 1.5em;
    --tn-1: 180ms linear;
    --page_cr: hsl(var(--cr_white));
    --page_bd: hsl(var(--cr_big-stone));
    --page_font: var(--ft-fam-primary);
    --form__input_border-cr: hsl(var(--cr_shuttle-gray));
    --form__input_border-cr_active: hsl(var(--cr_puerto-rico));
    --form__input_txt-cr: hsl(var(--cr_white));

}

body {
    background: var(--white);
    color: var(--black);
    font-size: 20px;
    font-family: var(--raleway);
    font-weight: 400;
    line-height: 38px;
    overflow-x: hidden;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

.policy-banner {
    ul {
        li {
            list-style-type: disc;
        }
    }
}

img {
    display: block;
    // object-fit: cover;
    margin: 0 auto;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

section {
    padding: 60px 0;
    background: var(--white);
}

.no-filter {
    filter: brightness(0) !important;
}

.d-none-img {
    display: none !important;
}

.img-invert {
    filter: invert(1);
}

div#enquiry {
    position: relative;
    top: -100px;
}

.pl-0 {
    padding-left: 0 !important;
}

a {
    &.button {
        color: var(--primary-color);
    }
}

.title {
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.sub-title {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
}

.text-center {
    text-align: center;
}

.text-light {
    color: var(--white);
}

.text-dark {
    color: var(--black) !important;
}

body .text-primary {
    color: var(--primary-color) !important;
}

.text-blue {
    color: var(--secondary-color) !important;
}

body .bgc-primary {
    background-color: var(--primary-color);
}

.bg-light-grey {
    background-color: var(--light-gray);
}

.bg-light-blue {
    background-color: var(--light-blue);
}

.bg-light {
    background: var(--white) !important;
    background-color: var(--white) !important;
}

.justify-content-center {
    justify-content: center;
}

.btn.link {
    background: var(--primary-color);
    color: var(--white);
    border-radius: 100px;
    border: none;
    height: 50px;
    line-height: 50px;
    padding: 0 25px;
    display: inline-block;
    transition: var(--transition);

    &:hover {
        background: var(--black);
    }
}

.link {
    cursor: pointer;
}

.nav-item {
    .link {
        &:hover {
            color: var(--secondary-color);
        }
    }
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    z-index: 99999;
    width: 100%;

    .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-top: 4px solid #007bff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bg-gradient-01 {
    background: rgb(174, 216, 254);
    background: -moz-linear-gradient(129deg, rgba(174, 216, 254, 1) 0%, rgba(188, 219, 247, 1) 11%, rgba(221, 238, 254, 1) 22%, rgba(230, 243, 255, 1) 73%, rgba(243, 251, 255, 1) 83%, rgba(237, 248, 250, 1) 90%, rgba(237, 247, 251, 1) 100%);
    background: -webkit-linear-gradient(129deg, rgba(174, 216, 254, 1) 0%, rgba(188, 219, 247, 1) 11%, rgba(221, 238, 254, 1) 22%, rgba(230, 243, 255, 1) 73%, rgba(243, 251, 255, 1) 83%, rgba(237, 248, 250, 1) 90%, rgba(237, 247, 251, 1) 100%);
    background: linear-gradient(129deg, rgba(174, 216, 254, 1) 0%, rgba(188, 219, 247, 1) 11%, rgba(221, 238, 254, 1) 22%, rgba(230, 243, 255, 1) 73%, rgba(243, 251, 255, 1) 83%, rgba(237, 248, 250, 1) 90%, rgba(237, 247, 251, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#aed8fe", endColorstr="#edf7fb", GradientType=1);
}

.innerbanner {
    min-height: 80vh;

    .container {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        align-items: center;

        .banner-left {
            width: 60%;

            h1 {
                font-size: 70px;
                color: var(--black);
                font-weight: 700;
                line-height: 70px;
                text-transform: uppercase;
                margin: 20px 0;
            }

            p {
                margin-bottom: 20px;
            }

            .button {
                background: var(--primary-color);
                border-radius: 100px;
                color: var(--white);
                text-transform: uppercase;
                font-size: 14px;
                padding: 15px 30px;
                font-weight: 600;
                cursor: pointer;
                transition: var(--transition);

                &:hover {
                    background: var(--black);
                }
            }
        }

        .banner-right {
            width: 40%;

            img {
                width: 100%;
            }
        }
    }
}

.pagebanner {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: end;
    background-position: center right;
    position: relative;
    // margin-top: 95px;
    margin-top: 0;
    // background-size: 100% !important;
    background-image: none !important;
    // background: none !important;

    &:before {
        content: '';
        // background: var(--primary-color);
        // background: linear-gradient(94deg, #02102F -4.7%, rgba(2, 16, 47, 0.00) 100.29%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: .4;
        z-index: 9;
    }

    img.banner-img {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        height: 100%;
        object-fit: none;
    }

    .container,
    .container-fluid {
        position: relative;
        z-index: 9;
        // position: absolute;
        bottom: 150px;

        .pagebanner-left {
            width: 50%;

            h1 {
                font-size: 57px;
                line-height: 62px;
                font-weight: 700;
            }

            h3 {
                font-size: 50px;
                line-height: 60px;
                font-weight: 300;
            }

            p {
                font-size: 20px;
                margin-bottom: 20px;
            }

            a {
                font-size: 16px;
                font-weight: 700;
                border: 0;
                padding: 0;
                color: var(--secondary-color) !important;

                svg {
                    color: var(--white);
                }

                &:hover {
                    background-color: transparent;
                    color: var(--secondary-color) !important;

                    svg {
                        color: var(--secondary-color) !important;
                    }
                }
            }
        }
    }

    .mobile-banner {
        display: none;
    }
}

.content .slick-list .slick-slide>div h4 {
    text-transform: capitalize;
}

.content .slick-slider .custom-prev-arrow,
.content .slick-slider .custom-next-arrow {
    z-index: 9;
}

.content {

    .container,
    .container-fluid {
        display: flex;
        gap: 20px;
        align-items: center;

        .content-left {
            width: 50%;
            padding: 0 15px;
            // direction: rtl;
        }

        .content-right {
            width: 50%;
            padding: 0 15px;
            // direction: rtl;
        }

        .content-full {
            width: 100%;
            padding: 0 15px;
        }

        h3 {
            font-size: 50px;
            line-height: 60px;
            font-weight: 200;
            text-transform: capitalize;
        }

        h2 {
            font-size: 50px;
            line-height: 60px;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 20px;

            span {
                font-weight: 400;
            }
        }

        p {
            margin-top: 30px;
            text-align: left;
        }
    }
}

.spacer-01 {
    min-height: 600px;
    display: block;
}

.h-200 {
    height: 200px;
    overflow-y: auto;
    direction: rtl;
}

.h-270 {
    height: 270px;
    overflow-y: auto;
    direction: rtl;
}

.pl-60 {
    padding-left: 60px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.ml--60 {
    margin-left: -60px !important;
}

.spacer200 {
    height: 200px;
}

.white-scroll::-webkit-scrollbar {
    width: 5px;
}

.white-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 33%, rgba(59, 60, 62, 1) 33%, rgba(59, 60, 62, 1) 66%, rgba(0, 0, 0, 1) 66%, rgba(0, 0, 0, 1) 100%);
}

.white-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 50px;
}

.introduction {
    background-color: var(--primary-color);
}

.white-scroll {
    scrollbar-width: thin;
    scrollbar-color: #fff #ccc;
}

.white-scroll::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.white-scroll::-webkit-scrollbar-track {
    background-color: #ccc;
}

.white-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
}

.white-scroll::-webkit-scrollbar-track,
.white-scroll::-webkit-scrollbar-thumb {
    border-radius: 12px;
}

.black-scroll::-webkit-scrollbar {
    width: 5px;
}

.black-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, #fff 0%, #fff 33%, #a1a1a1 33%, #a1a1a1 66%, #fff 66%, #fff 100%);
}

.black-scroll::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 50px;
}



.h-300 {
    height: 300px;
    overflow-y: auto;
    direction: ltr;
}

.primary-scroll::-webkit-scrollbar {
    width: 5px;
}

.primary-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgb(6 3 50) 0%, rgb(6 3 50) 33%, rgb(6 3 50) 33%, rgb(6 3 50) 66%, rgb(6 3 50) 66%, rgb(6 3 50) 100%);
}

.primary-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 50px;
}

.primary-scroll {
    scrollbar-width: thin;
    scrollbar-color: #fff #ccc;
}

.primary-scroll::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.primary-scroll::-webkit-scrollbar-track {
    background-color: #ccc;
}

.primary-scroll::-webkit-scrollbar-thumb {
    background-color: #46446e;
}

.primary-scroll::-webkit-scrollbar-track,
.primary-scroll::-webkit-scrollbar-thumb {
    border-radius: 12px;
}

// ttttttttt

.primary-white-scroll::-webkit-scrollbar {
    width: 5px;
}

.primary-white-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 33%, rgb(255, 255, 255) 33%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
}

.primary-white-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 50px;
}

.primary-white-scroll {
    scrollbar-width: thin;
    scrollbar-color: #fff #ccc;
}

.primary-white-scroll::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.primary-white-scroll::-webkit-scrollbar-track {
    background-color: #ccc;
}

.primary-white-scroll::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}

.primary-white-scroll::-webkit-scrollbar-track,
.primary-white-scroll::-webkit-scrollbar-thumb {
    border-radius: 12px;
}


.dir-ltr {
    direction: ltr;
}

form {
    // width: 500px;

    .form-control {
        width: 100%;
        height: 50px;
        font-size: 15px;
        color: var(--black);
        margin-bottom: 20px;
        padding: 0 15px;
        font-family: var(--raleway);
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #9d9d9d;
        border-radius: 0;

        &:focus {
            box-shadow: unset;
            border-color: var(--secondary-color);
            background: transparent;
        }
    }

    .form-floating>.form-control,
    .form-floating>.form-control-plaintext {
        padding: 1rem 0rem;
    }

    textarea {
        &.form-control {
            padding: 15px;
            height: 115px !important;
        }
    }

    button {
        // background: #137dbc;
        // color: var(--white);
        // text-transform: capitalize;
        // font-size: 15px;
        // font-weight: 600;
        // height: 50px;
        // border-radius: 2px;
        // min-width: 150px;
        // padding: 0 25px;
        // cursor: pointer;
        // transition: var(--transition);

        background: #137dbc;
        color: var(--white);
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        height: 50px;
        border-radius: 5px;
        min-width: 190px;
        padding: 0 25px;
        cursor: pointer;
        transition: var(--transition);
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: var(--black);
        }
    }

    .form-floating>label {
        font-size: 15px;
        line-height: 28px;
        padding: 0rem 0rem;
        height: auto;
        background: var(--white);
    }

    select.form-select.form-control {
        padding-right: 30px;
    }

    .row {
        margin-right: 5px;
        margin-left: 5px;
    }

    .col {
        position: relative;

        ul.service-list {
            display: none;
            list-style-type: none;
            padding-left: 0;
            margin: 0;
            background: var(--primary-color);
            position: absolute;
            left: 0;
            right: 0;
            top: 60px;
            z-index: 9;
            max-height: 200px;
            overflow-y: scroll;

            li {
                list-style-type: none;
                margin: 0;

                span {
                    padding: 5px 10px;
                    display: block;
                    cursor: pointer;
                    color: var(--white);
                    font-size: 14px;

                    &:hover {
                        background: #137dbc;
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding-left: 0px;
                    display: block;

                    li {
                        span {
                            padding-left: 20px;
                        }

                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding-left: 0px;
                            display: block;

                            li {
                                span {
                                    padding-left: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.slick-slider {
    .slick-track {
        display: flex !important;

        .slick-slide {
            height: inherit !important;
            padding-top: 10px;

            .item {
                position: relative;
                // max-height: 280px;
                // overflow-y: scroll;
            }
        }

        .slick-slide>div {
            height: 100%;

            // &:after {
            //     content: '';
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 60px;
            //     background: rgb(255,255,255);
            //     background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(0,212,255,0) 100%);
            // }
        }
    }
}

@media (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
        max-width: 1000px;
    }

    .innerbanner {
        .container {
            gap: 50px;

            .banner-left {
                width: 60%;

                h1 {
                    font-size: 40px;
                    line-height: 40px;
                    margin: 20px 0 10px;
                }
            }

            .banner-right {
                text-align: center;
                width: 40%;
            }
        }
    }
}

@media (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
        max-width: 590px;
    }

    .innerbanner {
        .container {
            gap: 50px;
            flex-direction: column-reverse;

            .banner-left {
                text-align: center;
                width: 100%;

                h1 {
                    font-size: 40px;
                    line-height: 40px;
                    margin: 20px 0 10px;
                }
            }

            .banner-right {
                text-align: center;
                width: 100%;
            }
        }
    }

    form {
        width: 100%;

        .form-group {
            .form-control {
                height: 50px;
                margin-bottom: 20px;
            }
        }
    }

    nav {
        .nav__container {
            ul {
                min-width: 100%;
            }
        }
    }
}

#testimonials {
    .carousel-root {
        width: 90% !important;
        margin: 0 auto;

        .carousel {

            .slide {
                background: #fff !important;
                color: black;
                height: 100%;

                .myCarousel {
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 50px;
                    padding-bottom: 0%;
                    padding-left: 5%;
                    padding-right: 5%;

                    p {
                        font-weight: 100 !important;
                        line-height: 29px !important;
                        color: var(--black);
                        font-family: sans-serif;
                        max-height: 67px;
                        text-align: center;
                    }

                    img {
                        width: 80px !important;
                        border-radius: 100%;
                    }

                    h3 {
                        color: #222;
                        font-weight: 100;
                        letter-spacing: 0.2px;
                        margin-bottom: 4px;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 17px;
                    }

                    h4 {
                        text-transform: uppercase;
                        margin-top: 0;
                        padding-top: 0;
                        font-weight: 500;
                        color: #787878;
                        font-size: 14px;
                    }
                }
            }

            .control-dots {
                padding-left: 5px !important;
                outline: 0;
                bottom: 5% !important;

                .dot {
                    box-shadow: none !important;
                    background: #454545 !important;
                    outline: 0;
                }
            }

            &.carousel-slider {
                .control-arrow {
                    background: #000 !important;
                    height: 50px !important;
                    position: absolute;
                    top: 35% !important;
                    border-radius: 100px;
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}

p.error {
    margin: 0 !important;
    font-size: 13px !important;
    color: #f00 !important;
    position: relative;
    top: -20px;
}

.scroll-to-top {
    background: var(--secondary-color);
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    border-radius: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    z-index: 99999;
    cursor: pointer;

    svg {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 100px;
        padding: 10px;
        font-size: 25px;
        color: var(--black);
    }
}

.content {
    p {
        ul {
            list-style-type: disc;
            padding-left: 20px;
            margin-top: 10px;
            direction: ltr;

            li {
                list-style-type: disc;
                line-height: 24px;
                font-size: 16px;
                margin-bottom: 10px;
                direction: ltr;
            }
        }
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin-top: 10px;
        direction: ltr;

        li {
            list-style-type: disc;
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 10px;
            direction: ltr;
        }
    }
}

.toggleMenu {
    display: none;
}

.faq-accordion {
    .accordion {
        margin: 30px 0 0;
    }

    .accordion-item {
        border: 0;
        margin-bottom: 10px;
    }

    .accordion-header {
        background-color: var(--white);
        border: none;
        padding: 20px 15px;
        width: 100%;
        text-align: left;
        cursor: pointer;
        box-shadow: inset 0px 0px 30px -20px #939393, 0px 0px 0px 0px #000;
        border-radius: 10px;
        transition: var(--transition);

        &:hover {
            background-color: var(--primary-color);
            color: var(--white);
        }

        &.active {
            background-color: var(--primary-color);
            color: var(--white);
        }
    }

    .accordion-content {
        border: 0;
        padding: 10px;
        display: block;
    }
}

.cookie-consent {
    background: #fff;
    width: auto;
    display: inline-block;
    max-width: 400px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 12px;
    left: 80px;
    box-shadow: 0px 0px 10px 0px #ccc;
    z-index: 99999;

    p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
        margin-top: 0 !important;
    }

    div {
        width: 230px;
    }

    button {
        padding: 0px 15px;
        border-radius: 50px;
        font-size: 12px;
        line-height: 12px;
        height: 28px;
        margin: 0 2px;

        &.accept {
            background: #0000ff;
            color: #fff;

            &:hover {
                background: #0000ff;
            }
        }

        &.decline {
            background: #ebebeb;
            color: #000;

            &:hover {
                background: #ebebeb;
            }
        }
    }
}

.form-group button img {
    width: 20px;
    margin: 0 5px 0 0;
}

footer {
    .col.address {
        font-family: "Poppins", sans-serif;
    }

    .ft-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.btn-loader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 3px;
}

.mobile-banner-img,
.mobilebannerimg {
    display: none;
}

// MEDIA QUERY
@media (min-width: 1367px) and (max-width: 1600px) {

    body {
        font-size: 20px;
        line-height: 32px;
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 50%;
                padding: 0 15px;
            }

            .content-right {
                width: 50%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h3 {
                font-size: 45px;
                line-height: 55px;
            }

            h2 {
                font-size: 45px;
                line-height: 55px;
            }

            p {
                margin-top: 40px;
                font-size: 18px;
            }
        }
    }

    .pagebanner {

        .container,
        .container-fluid {
            .pagebanner-left {
                width: 55%;
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1600px) {

    .h-270 {
        height: 200px;
    }

    .pl-60 {
        padding-left: 40px !important;
    }

    .ml--60 {
        margin-left: -45px !important;
    }

    body {
        font-size: 17px;
        line-height: 28px;
    }

    header {
        top: 20px;

        .nav-link {

            a,
            .link {
                line-height: 3rem;
                padding: 0 25px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 50%;
                padding: 0 15px;
            }

            .content-right {
                width: 50%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h3 {
                font-size: 36px;
                line-height: 45px;
            }

            h2 {
                font-size: 36px;
                line-height: 45px;
            }

            p {
                margin-top: 30px;
                font-size: 17px;
                line-height: 28px;
            }
        }
    }

    .pagebanner {
        // min-height: 80vh;
        background-position: center right !important;

        .container,
        .container-fluid {
            bottom: 80px;

            .pagebanner-left {
                width: 55%;

                h1 {
                    font-size: 45px;
                    line-height: 55px;
                }

                h3 {
                    font-size: 36px;
                    line-height: 40px;
                }

                p {
                    font-size: 17px;
                    margin-bottom: 28px;
                }

                a {
                    font-size: 16px;
                }
            }
        }
    }

    .content .slick-slider .custom-prev-arrow svg,
    .content .slick-slider .custom-next-arrow svg {
        position: relative;
        bottom: 3px;
    }

}

@media (min-width: 1025px) and (max-width: 1200px) {

    .h-270 {
        height: 150px;
    }

    .pl-60 {
        padding-left: 30px !important;
    }

    .ml--60 {
        margin-left: -30px !important;
    }

    body {
        font-size: 18px;
        line-height: 32px;
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 50%;
                padding: 0 15px;
            }

            .content-right {
                width: 50%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h3 {
                font-size: 34px;
                line-height: 40px;
            }

            h2 {
                font-size: 34px;
                line-height: 40px;
            }

            p {
                margin-top: 30px;
                font-size: 18px;
            }
        }
    }

    .pagebanner {
        // min-height: 40vh;
        background-position: center right !important;

        .container,
        .container-fluid {
            bottom: 60px;

            .pagebanner-left {
                h1 {
                    font-size: 35px;
                    line-height: 40px;
                    font-weight: 700;
                }

                h3 {
                    font-size: 30px;
                    line-height: 36px;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 20px;
                    line-height: 25px;
                }
            }
        }
    }

}

@media (max-width: 1024px) {

    body {
        font-size: 18px;
        line-height: 32px;
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 50%;
                padding: 0 15px;
            }

            .content-right {
                width: 50%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h3 {
                font-size: 32px;
                line-height: 40px;
            }

            h2 {
                font-size: 32px;
                line-height: 40px;
            }

            p {
                margin-top: 30px;
                font-size: 17px;
                line-height: 30px;
            }
        }
    }

    .pagebanner {
        // min-height: 40vh;
        background-position: center right !important;

        .container,
        .container-fluid {
            bottom: 50px;

            .pagebanner-left {
                width: 80%;
                padding-top: 50px;
                padding-bottom: 50px;

                h1 {
                    font-size: 35px;
                    line-height: 40px;
                    font-weight: 700;
                }

                h3 {
                    font-size: 28px;
                    line-height: 34px;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 20px;
                    line-height: 25px;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1024px) {

    body {
        font-size: 18px;
        line-height: 32px;
    }

    .content {
        .container {
            h2 {
                font-size: 30px;
                line-height: 36px;
            }
        }
    }

}

@media (max-width: 992px) {
    li.level-1-list {
        width: 100%;
    }

    .content {
        padding-top: 50px;

        .container,
        .container-fluid {

            .content-left {
                width: 50%;
                padding: 0 15px;
            }

            .content-right {
                width: 50%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h2 {
                font-size: 28px;
                line-height: 32px;
            }

            h3 {
                font-size: 28px;
                line-height: 32px;
            }
        }
    }

    .pagebanner {
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: auto !important;
        margin-top: 80px;
        background: var(--primary-color) !important;
        display: block;

        .container {
            position: relative;
            bottom: 0;
        }

        .banner-img {
            display: none;
        }

        .mobile-banner-img {
            display: block;
            width: 100%;
            object-fit: unset;
        }

        &.policy-banner {
            background: var(--white) !important;

            div,
            p,
            small {
                text-align: left;
            }
        }
    }

    .d-none-img {
        display: block !important;
    }

    ul.navbar-nav {
        max-height: 80vh;
        overflow-y: scroll;
    }

    .toggleMenu {
        display: block;
        font-size: 0;
    }

    .pagebanner img.banner-img {
        width: 100%;
        position: relative;
        object-fit: unset;
    }

    .mobilebannerimg {
        display: block;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .container {
        width: var(--container-width-md);
        max-width: 1000px;
    }

    .innerbanner {
        min-height: 40vh;

        .container {
            gap: 50px;
            margin: 60px 30px;

            .banner-left {
                width: 60%;

                h1 {
                    font-size: 40px;
                    line-height: 40px;
                    margin: 20px 0 10px;
                }
            }

            .banner-right {
                text-align: center;
                width: 40%;
            }
        }
    }
}

@media (max-width: 767px) {
    footer .container .row .col.address .address-wrapper {
        text-align: left;

        svg {
            font-size: 20px;
            margin-top: 6px;
        }
    }

    .spacer-01 {
        max-width: 400px;
        margin: 0 auto !important;
    }

    .pl-60 {
        padding-left: 0px !important;
    }

    .ml--60 {
        margin-left: 0px !important;
    }

    .pr-60 {
        padding-right: 0px !important;
    }

    .h-270 {
        height: auto;
        overflow-y: hidden;
        direction: ltr;
    }

    body {
        font-size: 16px;
        line-height: 28px;
    }

    .innerbanner {
        min-height: 40vh;

        .container {
            gap: 50px;
            margin: 60px 10px;

            .banner-left {
                width: 60%;

                h1 {
                    font-size: 40px;
                    line-height: 40px;
                    margin: 20px 0 10px;
                }
            }

            .banner-right {
                text-align: center;
                width: 40%;
            }
        }
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 100%;
                padding: 0 15px;
            }

            .content-right {
                width: 100%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 15px;
            }

            h2 {
                font-size: 24px;
                line-height: 30px;
                text-align: center;
            }

            h3 {
                font-size: 24px;
                line-height: 30px;
                text-align: center;
            }

            p {
                font-size: 1rem;
                line-height: 28px;
            }
        }
    }

    .pagebanner {
        padding-top: 0px;
        padding-bottom: 0;
        min-height: auto;
        flex-direction: column;
        margin-top: 80px;

        .container,
        .container-fluid {
            position: relative;
            bottom: 0;

            .pagebanner-left {
                width: 100%;
                padding-bottom: 50px;
                padding-top: 50px;

                h1 {
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                }

                h3 {
                    font-size: 36px;
                    line-height: 40px;
                    text-align: center;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 26px;
                    text-align: center;
                }

                a {
                    font-size: 14px;
                    display: block;
                    text-align: center;
                }
            }
        }

        .mobile-banner {
            // display: block;
            width: 100%;
        }
    }

    section.content.introduction {

        div.text-center,
        p.text-center {
            text-align: left !important;
        }
    }

    // section.content {
    //     div.text-center, p.text-center {
    //         text-align: left !important;
    //     }
    // }
    .pagebanner img.banner-img {
        width: 100%;
        position: relative;
    }
}

@media (min-width: 571px) and (max-width: 767px) {

}

@media (max-width: 600px) {
    form .form-floating>label {
        display: inline-table;
    }

    .h-300 {
        height: 200px;
    }

    .cookie-consent {
        max-width: 75%;
        flex-direction: column;
        bottom: 5px;
        left: 5px;

        div {
            width: 100%;
            text-align: right;
        }
    }

    .spacer-01 {
        min-height: 350px;
    }

    header {
        top: 0px;
    }

    .content {

        .container,
        .container-fluid {

            .content-left {
                width: 100%;
                padding: 0 15px;
            }

            .content-right {
                width: 100%;
                padding: 0 15px;
            }

            .content-full {
                width: 100%;
                padding: 0 0px;
            }

            h3 {
                font-size: 24px;
                line-height: 30px;
            }

            h2 {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 0;
            }

            p {
                margin-top: 20px;
                font-size: 1rem;
                line-height: 28px;
            }
        }
    }

    .pagebanner {
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: auto;
        margin-top: 80px;
        background: var(--primary-color) !important;

        .container,
        .container-fluid {
            .pagebanner-left {
                width: 100%;
                padding-bottom: 40px;
                padding-top: 40px;

                h1 {
                    font-size: 30px;
                    line-height: 34px;
                }

                h3 {
                    font-size: 24px;
                    line-height: 30px;
                }

                p {
                    font-size: 16px;
                    line-height: 28px;
                    margin-bottom: 10px;
                    text-align: center;
                }

                a {
                    font-size: 14px;
                }
            }
        }

        &.policy-banner {

            .container,
            .container-fluid {
                .pagebanner-left {

                    div,
                    p,
                    small {
                        text-align: left;
                    }
                }
            }
        }
    }

    form {

        .form-control {
            margin-bottom: 15px;
        }

        button {
            width: auto;
            display: block;
            margin: 0 auto;
        }

        .col {
            flex: unset;
        }
    }

    .content.introduction {
        .container {
            .content-left a {
                display: block;
                text-align: center;
            }

            .spacer-01 {
                width: 100% !important;
            }
        }
    }

    .scroll-to-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
        bottom: 10px;
        right: 10px;

        svg {
            width: 40px;
            height: 40px;
            line-height: 40px;
            padding: 10px;
            font-size: 20px;
        }
    }
    
    .slick-slider .slick-track .slick-slide {
        box-shadow: inset 0px 0px 10px 10px #cccccc2b;
        padding: 20px 20px 30px;
        border-radius: 20px;
    }

}

@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(15px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media screen and (min-width: 992px) {
    .megamenu {

        &.nav-item.dropdown.hasdropdown {
            position: unset;

            &:hover>.dropdown-menu {
                display: flex !important;
            }

            .dropdown-menu.dropdown-menu-level-1 {
                width: 100%;
                left: unset;
                right: 0;
                position: absolute;
                justify-content: space-around;
                align-items: start;
                align-content: center;
                // background: var(--bg-menu-level-1);
                background: var(--primary-color);
                flex-wrap: nowrap;
                border-radius: 0px;
                padding: 0px 40px;
                box-shadow: 0px 10px 25px -25px #fff;

                .nav-item.dropend.hasdropdown {
                    position: relative;

                    a.nav-link.dropdown-toggle {
                        padding: 20px 0 5px;
                        font-weight: 600;

                        .link {
                            display: flex;
                            color: #fff;

                            img {
                                display: none;
                                width: 30px;
                                margin: 0 5px;
                                filter: grayscale(1) brightness(1);
                            }
                        }

                        &:hover {
                            color: var(--secondary-color);

                            .link {
                                color: var(--secondary-color);

                                img {
                                    filter: grayscale(1) invert(1) brightness(25);
                                }
                            }
                        }
                    }

                    // &:hover>.dropdown-menu {
                    //     display: flex !important;
                    // }

                    // &.level-2-list:hover>.dropdown-menu {
                    //     display: none !important;
                    // }

                    ul.dropdown-menu.dropdown-menu-level-2 {
                        position: relative;
                        left: 0;
                        top: 0px;
                        flex-direction: column;
                        background-color: transparent;
                        border-radius: 0;
                        overflow: hidden;
                        display: flex !important;

                        li.nav-item {
                            padding-right: 0rem !important;
                            padding-left: 0rem !important;

                            .dropdown-item {
                                color: var(--white);
                                margin: 0;
                                text-transform: capitalize;
                                transition: var(--transition);
                                padding-right: 0.5rem !important;
                                padding-left: 0rem !important;
                                padding-top: 0px;
                                padding-bottom: 5px;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-image: linear-gradient(to right, var(--secondary-color), var(--secondary-color) 50%, #fff 50%);
                                background-size: 200% 100%;
                                background-position: -100%;
                                transition: all 0.3s ease-in-out;
                                position: relative;
                                font-weight: 600;
                                width: auto;
                                font-size: 15px;
                                text-wrap: wrap;
                                line-height: 20px;
                                white-space: initial;

                                &:before {
                                    display: block;
                                    content: '';
                                    width: 0;
                                    height: 1px;
                                    bottom: 5px;
                                    left: 0;
                                    bottom: 4px;
                                    z-index: 0;
                                    position: absolute;
                                    background: var(--secondary-color);
                                    transition: all 0.3s ease-in-out;
                                }

                                &:hover {
                                    color: var(--secondary-color) !important;
                                    // background-image: linear-gradient( to right, var(--secondary-color), var(--secondary-color) 50%, #fff 50%);
                                    background-position: 0%;

                                    &:before {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dropdown-menu.dropdown-menu-level-1>.nav-item.dropend.hasdropdown:hover>.dropdown-menu {
                display: flex !important;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .megamenu {
        .dropend:hover>.dropdown-menu {
            top: 65px;
        }
    }
}

@media screen and (max-width: 992px) {
    .megamenu {
        &.nav-item.dropdown.hasdropdown {
            .dropdown-menu.dropdown-menu-level-1 {
                .nav-item.dropend.hasdropdown {
                    a.nav-link.dropdown-toggle {
                        .link {
                            img {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}







nav.nnn {
    background-color: var(--primary-color);
    height: 80px;
    padding: 0 16px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    /* fade in checked menu */
}

nav.nnn .m-menu__checkbox {
    display: none;
}

nav.nnn label.m-menu__toggle {
    cursor: pointer;
}

nav.nnn .m-menu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 450px;
    width: calc(100vw - 30px);
    height: 100vh;
    -moz-transform: translate3d(-450px, 0, 0);
    -o-transform: translate3d(-450px, 0, 0);
    -ms-transform: translate3d(-450px, 0, 0);
    -webkit-transform: translate3d(-450px, 0, 0);
    transform: translate3d(-450px, 0, 0);
    -moz-transition: transform 0.35s;
    -o-transition: transform 0.35s;
    -webkit-transition: transform 0.35s;
    transition: transform 0.35s;
    z-index: 1;
    overflow: hidden;
    background-color: #fff;
}

nav.nnn .m-menu__overlay {
    background-color: rgba(103, 103, 103, 0.5);
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
    display: none;
}

nav.nnn .m-menu__header {
    padding: 0 16px;
    height: 81px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: space-around;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
}

nav.nnn .m-menu__header span {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    line-height: 20px;
}

nav.nnn .m-menu .m-menu {
    -moz-transform: translate3d(480px, 0, 0);
    -o-transform: translate3d(480px, 0, 0);
    -ms-transform: translate3d(480px, 0, 0);
    -webkit-transform: translate3d(480px, 0, 0);
    transform: translate3d(480px, 0, 0);
}

nav.nnn .m-menu ul {
    height: 100%;
    overflow-y: auto;
    padding-left: 0px;
}

nav.nnn .m-menu ul li,
nav.nnn .m-menu ul li a,
nav.nnn .m-menu ul li label {
    display: block;
    text-align: left;
    padding: 0 15px;
    line-height: 47px;
    text-decoration: none;
    color: #333;
    cursor: pointer;
    font-size: 1rem;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
}

nav.nnn .m-menu ul li {
    display: flex;
    justify-content: space-between;
}

nav.nnn .m-menu ul li a {
    border-bottom: 0px solid #e8e8e8;
}

nav.nnn .m-menu ul li label.a-label__chevron::after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-color: #333;
    border-style: solid;
    border-width: 1px 1px 0 0;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    margin-top: -5px;
    right: 16px;
}

nav.nnn .m-menu ul li .-invisible {
    border-bottom: 0;
}

nav.nnn .m-menu .m-menu label.m-menu__toggle {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    border-bottom: 0;
    padding: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

nav.nnn .m-menu__checkbox:checked~.m-menu__overlay {
    display: block;
}

nav.nnn .m-menu__checkbox:checked~.m-menu {
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.wrappernnn {
    display: none;
}

@media (max-width: 992px) {
    nav.navbar.navbar-expand-lg.navbar-dark {
        display: none !important;
    }

    .wrappernnn {
        display: block;
        width: 100%;
        margin: 0 auto;
        overflow: unset;
        position: fixed;
        z-index: 99999;
        top: 0;

        a.navbar-brand.fw-bold {
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0%);

            img {
                height: 60px;
                width: auto;
            }
        }
    }
}