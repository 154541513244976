.content.driven-by.corevalues {
    .container, .container-fluid {
        .content-left {
            .slick-list {
                padding-bottom: 90px;
            }
            .custom-prev-arrow {
                left: 50%;
                right: unset;
                transform: translate(-120%, 0%);
            }
            .custom-next-arrow {
                right: 50%;
                left: unset;
                transform: translate(120%, 0%);
            }
        }
    }
}

.content.our-story.about-our-story {
    padding-bottom: 100px;
}

.content.about-banner {
    padding-top: 170px;
    padding-bottom: 80px;
}



@media (min-width: 1367px) and (max-width: 1600px) {
}

@media (min-width: 1201px) and (max-width: 1600px) {
    .content.about-banner {
        padding-top: 170px;
        padding-bottom: 80px;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
    .content.about-banner {
        padding-top: 150px;
        padding-bottom: 80px;
    }
    .content.our-story .container .content-left p {
        line-height: 28px;
        margin-top: 30px;
        font-size: 17px;
    }
}

@media screen and (max-width: 992px) {
}

@media (max-width: 767px) {
    .content.our-story {
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .content.our-story.about-our-story {
        padding-bottom: 40px;
    }
    .content.about-banner {
        padding-top: 60px;
        padding-bottom: 50px;
        margin-top: 70px;
    }
    .content.our-story .container .content-left p {
        line-height: 28px;
        margin-top: 30px;
        font-size: 17px;
    }
}

@media screen and (max-width: 600px) {
}