.pagebanner {
    .pagebanner-left {
        .link {
            color: var(--black);
            transition: var(--transition);

            &:hover {
                color: var(--secondary-color);
            }
        }
    }
}