.industries {
    padding: 80px 0;

    .container {
        .services-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 35px 10px;
            margin-top: 30px;
            width: 60%;

            article {
                // border-radius: 0 0 25px 0;
                text-align: center;
                transition: var(--transition);
                // background: url(../../images/btn-bg.webp) no-repeat center center;
                background-color: transparent;
                // background-size: contain;
                border: 0;

                button {
                    height: 100%;
                }

                img {
                    width: 70px; 
                    height: 70px;
                    border-radius: 100px;
                    display: inline-block;
                }

                h4 {
                    margin-top: 10px;
                    color: var(--white);
                }

                small {
                    width: 100%;
                    display: block;
                    line-height: 24px;
                    margin: 10px 0;
                    color: var(--white);
                }

                .link {
                    color: var(--secondary-color);
                    font-size: 25px;
                    text-decoration: none;
                    padding: 30px 15px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 24px;
                    transition: var(--transition);
                    cursor: pointer;
                    font-weight: 600;

                    small {
                        color: var(--white);
                    }

                    &:hover {
                        color: var(--white);
                    }
                }

                &:hover {
                    // background: var(--secondary-color);
                    // border: 1px solid var(--secondary-color);

                    img {
                        border: 2px solid var(--primary-color);
                    }

                    h4 {
                        color: var(--black);
                    }

                    small {
                        color: var(--white);
                    }

                    link {
                        color: var(--white);

                        &:hover {
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }
}

.content {
    .industry-features.container-fluid, .industry-features.container {
        padding: 80px 100px 10px;

        .content-left {
            width: 62%;
        }  

        .content-right {
            width: 38%;
        }

        article.card {
            background: transparent;
            border: 0;
        }

        .custom-prev-arrow {
            position: absolute;
            top: unset;
            left: 50%;
            background: var(--white);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            right: unset;
            transition: var(--transition);
            transform: translate(-120%, 0%);

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                background: var(--white);

                svg {
                    color: #000;
                    box-shadow: unset;
                }
            }
        }

        .custom-next-arrow {
            position: absolute;
            top: unset;
            right: 50%;
            background: var(--white);
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
            color: #b6b6b6;
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            left: unset;
            transition: var(--transition);
            transform: translate(120%, 0%);

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                background: var(--white);

                svg {
                    color: #000;
                    box-shadow: unset;
                }
            }
        }

        .slick-list {
            margin: 0 auto;
            padding-bottom: 150px;

            .slick-slide>div {
                background: var(--white);
                border-radius: 30px;
                padding: 90px 55px 50px;
                margin: 0px 40px 0;
                transition: var(--transition);
                box-shadow: inset -5px -2px 30px 1px transparent, 5px 0px 20px -5px #c1c1c1;

                h4 {
                    font-size: 25px;
                    line-height: 32px;
                    font-weight: 600;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0;
                    font-weight: 500;
                    // max-height: 250px;
                    // overflow-y: scroll;

                    &:after {
                    }
                }

                &:hover {
                    box-shadow: inset 5px 5px 20px 1px #d9d9d9, 0px 0px 0px 0px #c1c1c1;
                }
            }
        }
    }
    .features-with-border.container-fluid, .features-with-border.container {
        padding: 80px 100px 150px;

        .content-left {
            width: 62%;
        }  

        .content-right {
            width: 38%;
        }

        article.card {
            background: transparent;
            border: 0;
        }

        .custom-prev-arrow {
            position: absolute;
            top: unset;
            left: 50%;
            background: transparent;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            color: var(--white);
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            right: unset;
            transition: var(--transition);
            transform: translate(-120%, 0%);
            border: 1px solid var(--secondary-color);

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                background: var(--secondary-color);

                svg {
                    color: var(--white);
                }
            }
        }

        .custom-next-arrow {
            position: absolute;
            top: unset;
            right: 50%;
            background: transparent;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100px;
            color: var(--white);
            font-size: 30px;
            cursor: pointer;
            z-index: 9;
            bottom: 0px;
            left: unset;
            transition: var(--transition);
            transform: translate(120%, 0%);
            border: 1px solid var(--secondary-color);

            svg {
                width: 60px;
                height: 60px;
                padding: 15px;
                border-radius: 100px;
                transition: var(--transition);
            }

            &:hover {
                background: var(--secondary-color);

                svg {
                    color: var(--white);
                }
            }
        }

        .slick-list {
            margin: 0 auto;

            .slick-slide>div {
                background: transparent;
                border-radius: 30px;
                padding: 90px 55px 40px;
                margin: 0px 40px 0;
                transition: var(--transition);
                border: 1px solid var(--secondary-color);

                h4 {
                    font-size: 25px;
                    line-height: 55px;
                }

                p {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
    }
    .slider-center-arrow.container-fluid, .slider-center-arrow.container {

        .custom-prev-arrow {
            left: 50%;
            bottom: 0px;
            right: unset;
            transform: translate(-120%, 0%);
        }

        .custom-next-arrow {
            top: unset;
            right: 50%;
            bottom: 0px;
            left: unset;
            transform: translate(120%, 0%);
        }

        .slick-list {
            padding-bottom: 130px;
            // 130px
            .slick-slide > div {
                padding: 60px 20px 0px;
            }
        }
    }
}

.content {
    .why-us.container-fluid {

        .slick-slider {

            .custom-prev-arrow {
                background: var(--primary-color);
                box-shadow: unset;
                color: #353535;

                svg {
                    box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                }

                &:hover {
                    background: var(--primary-color);
                    box-shadow: unset;

                    svg {
                        color: var(--white);
                        box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                    }
                }
            }

            .custom-next-arrow {
                background: var(--primary-color);
                box-shadow: unset;
                color: #353535;

                svg {
                    box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                }

                &:hover {
                    background: var(--primary-color);
                    box-shadow: unset;

                    svg {
                        color: var(--white);
                        box-shadow: inset 0px 3px 5px 1px var(--primary-box-shadow);
                    }
                }
            }
        }

        a {
            padding-left: 160px;
            position: relative;
            top: -120px;

            &:hover {
                color: var(--secondary-color) !important;
            }
        }
    }
}

.content.enquiry-form {
    padding: 100px 0;

    .container {
        max-width: 70%;

        .content-left {
            width: 60%;

            h2, h3 {
                margin-bottom: 0;
            }

            form {
                margin-top: 20px;

                .col {
                    width: 50%;

                    input, textarea, select {
                        background: transparent;
                        border: 1px solid #919191;

                        &:focus {
                            background-color: transparent;
                            border-color: var(--secondary-color);
                            outline: 0;
                            box-shadow: unset;
                        }
                    }
                }
            }
        }

        .content-right {
            width: 40%;

            h2 {
                font-size: 28px;
                margin-bottom: 20px;
            }

            .contact-box {
                font-family: 'Poppins';
                font-weight: 400;
                small {
                    display: block;
                }
                a {
                    color: var(--black);
                    transition: var(--transition);
            
                    &:hover {
                        color: var(--secondary-color);
                    }
                }
                p {
                    margin-top: 0 !important;
                }
            }
        }
    }
}

.content {
    .why-us.container-fluid.why-us-industry-details {
        a {
            padding-left: 0;
            top: 0;
        }
    }
}

.content.introduction {
    .content-left {
        h3 {
            span {
                font-weight: 700;
                color: var(--secondary-color);
            }
        }
    }
}

.after {
    border: solid var(--secondary-color);
    padding: 25px 15px;
    background: transparent;
    border-radius: 0;
    color: var(--secondary-color);
    position: relative;
    border-width: 1px 0px 1px 1px;
    max-width: 275px;
    text-align: left;
    svg {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translate(-50%, -50%);
    }
    &:before {
        content: "";
        position: absolute;
        right: -16px;
        top: -1px;
        bottom: 16px;
        width: 16px;
        background: transparent;
        border: solid var(--secondary-color);
        border-width: 1px 1px 0px 0px;
    }
    &:after {
        content: "";
        position: absolute;
        right: -20px;
        bottom: 8px;
        width: 24px;
        height: 1.4px;
        transform: rotate(-50deg);
        background: var(--secondary-color);
    }
    &:hover {
        color: var(--white);
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {

    .industries {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(3, 1fr);
                gap: 20px 30px;
                margin-top: 30px;
                width: 55% !important;
    
                article {
                    background: transparent !important;
    
                    .link {
                        font-size: 16px;
                        padding: 25px 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .after {
        max-width: 190px;
    }
}

@media (min-width: 1201px) and (max-width: 1600px) {

    .industries {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(3, 1fr);
                gap: 15px 20px;
                margin-top: 30px;
                width: 65%;
    
                article {
                    background: transparent !important;

                    .after {
                        padding: 20px 10px 20px 15px;
                        &:before {
                            right: -16px;
                            top: -1px;
                            bottom: 18px;
                            width: 16px;
                        }
                        &:after {
                            right: -20px;
                            bottom: 8px;
                            width: 24px;
                            height: 1.4px;
                            transform: rotate(-50deg);
                        }
                    }
    
                    .link {
                        font-size: 16px;
                        padding: 25px 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .content {
        .industry-features.container-fluid, .industry-features.container {
            padding: 30px 40px 30px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
                padding-bottom: 80px;
    
                .slick-slide>div {
                    padding: 40px 20px 40px;
                    margin: 0px 20px 0;
                    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 30px;
                    }
                    
                    &:hover {
                        box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
                    }
                }
            }
        }
        .features-with-border.container-fluid, .features-with-border.container {
            padding: 30px 40px 30px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .custom-next-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
    
                svg {
                    width: 50px;
                    height: 50px;
                    padding: 15px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    padding: 60px 20px 50px;
                    margin: 0px 20px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 30px;
                    }
                }
            }
        }
        .slider-center-arrow.container-fluid, .slider-center-arrow.container {
            padding: 30px 50px 40px;
    
            .custom-prev-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }
    
            .custom-next-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }

            .slick-list {
                padding-bottom: 130px;
            }
        }
    }

    .after {
        // max-width: 185px;
    }

}

@media (min-width: 1025px) and (max-width: 1200px) {

    .industries {
        padding: 80px 0;
    
        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                gap: 15px 30px;
                margin-top: 30px;
                width: 50%;
    
                article {
                    background: transparent;

                    .after {
                        padding: 15px 10px;
                        font-size: 16px;
                        max-width: 175px;
                        &:before {
                            right: -16px;
                            top: -1px;
                            bottom: 16px;
                            width: 16px;
                        }
                        &:after {
                            right: -20px;
                            bottom: 8px;
                            width: 24px;
                            height: 1.4px;
                            transform: rotate(-50deg);
                        }
                    }
    
                    .link {
                        font-size: 18px;
                        padding: 25px 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .content {
        .industry-features.container-fluid, .industry-features.container {
            padding: 0px 0px 0px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .content-full {
                h2 {
                    margin-bottom: 40px;
                }
            }
    
            .custom-prev-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .custom-next-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .slick-list {
                padding-bottom: 75px;
    
                .slick-slide>div {
                    padding: 40px 20px 40px;
                    margin: 0px 20px 0;
                    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 30px;
                    }
                    
                    &:hover {
                        box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
                    }
                }
            }
        }
        .features-with-border.container-fluid, .features-with-border.container {
            padding: 0px 40px 0px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .custom-next-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .slick-list {
    
                .slick-slide>div {
                    padding: 40px 20px 40px;
                    margin: 0px 20px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 30px;
                    }
                }
            }
        }
        .slider-center-arrow.container-fluid, .slider-center-arrow.container {
            padding: 30px 0px 40px;
    
            .custom-prev-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }
    
            .custom-next-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }

            .slick-list {
                padding-bottom: 120px;
            }
        }
        .why-us.container-fluid {
            padding: 0px 40px 0px;
    
            .slick-slider {
    
                .custom-prev-arrow {
                    background: var(--white);
                    box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                    color: #353535;
    
                    svg {
                        box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    }
    
                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        background: var(--white);
    
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }
    
                .custom-next-arrow {
                    background: var(--white);
                    box-shadow: 0px 14px 14px -4px rgba(219, 219, 219, 0.6196078431);
                    color: #353535;
    
                    svg {
                        box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    }
    
                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        background: var(--white);
    
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }
            }
        }
    }

    .content.enquiry-form {
        padding: 90px 0;

        .container {
            max-width: 80%;

            .content-left {
                width: 60%;

                form {
                    margin-top: 20px;
                    
                    .col {
                        width: 50%;
                    }
                }
            }

            .content-right {
                width: 40%;

                h2 {
                    font-size: 26px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .industries {
        padding: 50px 0;

        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 30px;
                gap: 10px 25px;
                width: 50%;

                article {

                    .after {
                        padding: 15px 10px;
                        font-size: 16px;
                        &:before {
                            right: -16px;
                            top: -1px;
                            bottom: 16px;
                            width: 16px;
                        }
                        &:after {
                            right: -20px;
                            bottom: 8px;
                            width: 24px;
                            height: 1.4px;
                            transform: rotate(-50deg);
                        }
                    }

                    span {
                        font-size: 30px;
                        width: 50px;
                        height: 50px;
                        line-height: 55px;
                    }

                    h4 {
                        margin-top: 10px;
                    }

                    small {
                        line-height: 24px;
                        margin: 10px 0;
                    }
    
                    .link {
                        font-size: 20px;
                        padding: 25px 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .content {
        .industry-features.container-fluid, .industry-features.container {
            padding: 0px 0px 0px;
    
            .content-left {
                width: 62%;
            }
    
            .content-right {
                width: 38%;

                p {
                    margin-top: 30px;
                }
            }
    
            .content-full {
                h2 {
                    margin-bottom: 40px;
                }
            }
    
            .custom-prev-arrow, .custom-next-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .slick-list {
                padding-bottom: 75px;
    
                .slick-slide>div {
                    padding: 40px 20px 40px;
                    margin: 0px 20px 0;
                    box-shadow: inset -5px -2px 30px 1px transparent, 5px 10px 25px -5px #dddddd;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 15px;
                        line-height: 28px;
                    }
                    
                    &:hover {
                        box-shadow: inset 5px 5px 20px 0px #d9d9d9, 0px 0px 0px 0px #dddddd;
                    }
                }
            }
        }
        .features-with-border.container-fluid, .features-with-border.container {
            padding: 0px 40px 30px;
    
            .content-left {
                width: 50%;
            }
    
            .content-right {
                width: 50%;

                p {
                    margin-top: 30px;
                }
            }
    
            .custom-prev-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .custom-next-arrow {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 30px;
    
                svg {
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                }
            }
    
            .slick-list {
                padding-bottom: 70px;

                .slick-slide>div {
                    padding: 40px 20px 40px;
                    margin: 0px 20px 0;
    
                    h4 {
                        font-size: 18px;
                        line-height: 25px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-size: 17px;
                        line-height: 30px;
                    }
                }
            }
        }
        .slider-center-arrow.container-fluid, .slider-center-arrow.container {
    
            .custom-prev-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }
    
            .custom-next-arrow {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
            }

            .slick-list {
                padding-bottom: 120px;
            }
        }
        .ind.why-us.container-fluid {
            padding: 0px 0px 0px;
    
            .slick-slider {
    
                .custom-prev-arrow {
                    background: var(--white);
                    box-shadow: 0px 8px 14px -4px rgba(219, 219, 219, 0.6196078431);
                    color: #353535;
                    line-height: 35px;
    
                    svg {
                        box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    }
    
                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        background: var(--white);
    
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }
    
                .custom-next-arrow {
                    background: var(--white);
                    box-shadow: 0px 8px 14px -4px rgba(219, 219, 219, 0.6196078431);
                    color: #353535;
                    line-height: 35px;
    
                    svg {
                        box-shadow: inset 0px 3px 5px 1px #c7c7c7;
                    }
    
                    &:hover {
                        box-shadow: 5px 14px 14px -4px #dbdbdb9e;
                        background: var(--white);
    
                        svg {
                            color: #000;
                            box-shadow: unset;
                        }
                    }
                }
            }
        }
    }

    .content.enquiry-form {
        padding: 80px 0;

        .container {
            max-width: 90%;

            .content-left {
                width: 60%;

                form {
                    margin-top: 20px;
                    
                    .col {
                        width: 50%;
                    }
                }
            }

            .content-right {
                width: 40%;

                h2 {
                    font-size: 26px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .after {
        max-width: 175px;
    }
}

@media (min-width: 993px) and (max-width: 1024px) {}

@media (max-width: 992px) {
    .industries {

        .container {
            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 10px;
                gap: 10px 0px;
                width: 68%;

                article {
                    .after {
                        padding: 15px 10px;
                        font-size: 16px;
                        line-height: 20px;
                        &:before {
                            right: -16px;
                            top: -1px;
                            bottom: 16px;
                            width: 16px;
                        }
                        &:after {
                            right: -20px;
                            bottom: 8px;
                            width: 24px;
                            height: 1.4px;
                            transform: rotate(-50deg);
                        }
                    }
                }
            }
        }
    }
    .content.enquiry-form {
        padding: 70px 0;

        .container {
            max-width: 90%;

            .content-left {
                width: 60%;

                form {
                    
                    .col {
                        width: 50%;
                    }
                }
            }

            .content-right {
                width: 40%;

                h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .content {
        .why-us.container-fluid {
            .slick-slider {
                .slick-list {
                    padding-bottom: 60px;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {}

@media (max-width: 767px) {
    .industries {
        padding: 40px 0;
        position: relative;

        &:before {
            content: '';
            background: #000;
            opacity: .5;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .container {
            flex-wrap: wrap;
            position: relative;
            z-index: 9;
            gap: 10px 10px !important;

            p {
                text-align: center !important;
                line-height: 28px;
            }

            .services-wrapper {
                grid-template-columns: repeat(2, 1fr);
                margin-top: 10px;
                width: 100%;

                article {
                    span {
                        font-size: 30px;
                        width: 50px;
                        height: 50px;
                        line-height: 55px;
                    }
                    
                    .link {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .content.enquiry-form {
        padding: 60px 0;
        .container {
            max-width: 100%;
            flex-direction: column;
            .content-left {
                width: 100%;
                form {
                    margin-bottom: 50px;                    
                    .col {
                        width: 50%;
                    }
                    .button-wrap {
                        text-align: center;
                    }
                }
            }
            .content-right {
                width: 100%;
                h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .content {
        .industry-features.container-fluid, .industry-features.container {
            padding: 0px 0px 0px;
            flex-direction: column-reverse;
    
            .content-left {
                width: 100%;
            }
    
            .content-right {
                width: 100%;

                a.button {
                    display: block;
                    text-align: center;
                }
            }
    
            .slick-list {
                padding-bottom: 60px;
    
                .slick-slide>div {
                    // padding: 40px 20px 30px;
                    // margin: 0px 15px 0;
                    padding: 20px 0;
                    margin: 0;
                    box-shadow: unset;
                }
            }
            .custom-prev-arrow, .custom-next-arrow {
                width: 40px;
                height: 40px;
                line-height: 40px;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
        }
        .features-with-border.container-fluid, .features-with-border.container {
            gap: 0;
            padding: 0px 10px 30px;

            .content-right {
                h2, h3 {
                    text-align: left;
                }
            }

            .custom-prev-arrow, .custom-next-arrow {
                width: 40px;
                height: 40px;
                line-height: 40px;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
            .slick-list {
                .slick-slide>div {
                    margin: 0px 10px 0;
                    height: 100% !important;
                }
            }
        }
        .case-studies.container-fluid, .case-studies.container {
            padding: 20px 0px 20px;
            // margin-bottom: 60px;
            margin-bottom: 0px;
            flex-direction: column-reverse;
            
            .slick-list {
                padding-bottom: 60px;

                .slick-slide > div {
                    // padding: 50px 20px 30px;
                    padding: 0;
                    margin: 0;
                    box-shadow: unset;
                }
            }

            .custom-prev-arrow {
                top: unset;
                left: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                bottom: 0;
                transform: translate(-100%, 0%);
                right: unset;
                z-index: 9;

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
            .custom-next-arrow {
                top: unset;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                left: 50%;
                bottom: 0%;
                transform: translate(10%, 0%);

                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px;
                }
            }
        }
    }
}

@media (min-width: 571px) and (max-width: 767px) {}

@media (max-width: 600px) {
    .industries {
        padding: 40px 0;

        .container {
            flex-wrap: wrap;

            p {
                line-height: 24px;
            }

            .content-full {
                padding: 0 15px 0 0 !important;

                .services-wrapper {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 30px;
                    width: 100%;

                    article {
                        display: inline-grid;
                        .after {
                            font-size: 14px;
                            line-height: 20px;
                            min-height: 55px;
                            padding: 0 10px;
                            text-align: left;
                        }
                        
                        .link {
                            font-size: 14px;
                            padding: 20px 10px;
                        }
                    }
                }
            }
        }
    }
    .content.enquiry-form {
        padding: 60px 0 40px;
        
        .container {
            .content-left {
                form {                    
                    .col {
                        width: 100%;
                    }
                }
            }
            .content-right {
                h2 {
                    font-size: 20px;
                }
                .contact-box {
                    text-align: center;

                    p {
                        text-align: center;
                    }
                }
            }
        }
    }

    .content {
        .features-with-border.container-fluid, .features-with-border.container {
            flex-direction: column-reverse;
            padding: 0px 10px 30px;

            .content-left {
                width: 100%;
            }
            .content-right {
                width: 100%;
                margin-bottom: 30px;

                h2, h3 {
                    text-align: center;
                }

                a {
                    display: block;
                    text-align: center;
                }
            }
        }
        .why-us.container-fluid {
            padding: 0px 10px 0px;

            a.button {
                display: block;
                text-align: center;
            }
        }
    }
    
    .industries .container .services-wrapper {
        gap: 10px 25px;
    }
}