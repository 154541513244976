section.blog, section.related-blog {
  padding: 60px 0 100px;
}
section.blog .container, section.related-blog .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 20px;
  background: #fff;
  border-radius: 35px;
  padding-top: 20px;
}
section.blog .container .item, section.related-blog .container .item {
  height: 100%;
}
section.blog .container .item .link-whole, section.related-blog .container .item .link-whole {
  text-decoration: none;
  color: #000 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
  transition: var(--transition);
  padding: 40px 25px 90px;
  border-radius: 20px;
  box-shadow: -10px -10px 30px -10px #c1c1c1;
  position: relative;
}
section.blog .container .item .link-whole .post-img, section.related-blog .container .item .link-whole .post-img {
  position: relative;
  width: 100%;
  height: 275px;
  margin-bottom: 10px;
  border-radius: 15px;
}
section.blog .container .item .link-whole h4, section.related-blog .container .item .link-whole h4 {
  font-size: 22px;
  line-height: 32px;
  color: var(--primary-color) !important;
}
section.blog .container .item .link-whole p, section.related-blog .container .item .link-whole p {
  margin-top: 20px !important;
  margin-bottom: 0;
}
section.blog .container .item .link-whole span, section.related-blog .container .item .link-whole span {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: var(--primary-color) !important;
  padding: 0px !important;
  border-radius: 10px !important;
  position: absolute;
  bottom: 40px;
  left: 25px;
  width: auto;
}
section.blog .container .item .link-whole span:hover, section.related-blog .container .item .link-whole span:hover {
  color: var(--secondary-color) !important;
}
section.blog .container .item .link-whole:hover, section.related-blog .container .item .link-whole:hover {
  box-shadow: inset -5px -2px 20px 1px #d9d9d9, -20px 20px 35px -25px #c1c1c1;
}
section.blog .container .item .link, section.related-blog .container .item .link {
  position: relative;
  border-radius: 30px;
  padding: 60px 25px 60px;
  margin: 0px 20px 0;
  box-shadow: -10px -10px 30px -10px #c1c1c1;
  height: 100%;
  display: table;
  transition: var(--transition);
  background: #fff;
}
section.blog .container .item .link .post-img, section.related-blog .container .item .link .post-img {
  position: relative;
  width: 100%;
  height: 275px;
  margin-bottom: 10px;
  border-radius: 15px;
}
section.blog .container .item .link h4, section.related-blog .container .item .link h4 {
  font-size: 22px;
  line-height: 32px;
  color: var(--primary-color) !important;
}
section.blog .container .item .link p, section.related-blog .container .item .link p {
  margin-top: 20px !important;
  margin-bottom: 0;
}
section.blog .container .item .link span, section.related-blog .container .item .link span {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: var(--primary-color) !important;
  padding: 0px !important;
  border-radius: 10px !important;
  position: absolute;
  bottom: 40px;
  left: 25px;
  width: auto;
}
section.blog .container .item .link span:hover, section.related-blog .container .item .link span:hover {
  color: var(--secondary-color) !important;
}
section.blog .container .item .link:hover, section.related-blog .container .item .link:hover {
  box-shadow: inset -5px -2px 20px 1px #d9d9d9, -20px 20px 35px -25px #c1c1c1;
}
section.related-blog .container {
  margin-top: 0px;
  background: transparent;
  padding-top: 0;
}
section.related-blog .container-fluid {
  display: block;
}
section.related-blog .container-fluid h5 {
  text-align: center;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-family: var(--raleway);
}
section.single-blog-details {
  padding: 150px 0 60px;
}
section.single-blog-details .container .content-full img, section.single-blog-details .container-fluid .content-full img {
  width: 100%;
  max-width: 60%;
}
section.single-blog-details .container .content-full h2, section.single-blog-details .container-fluid .content-full h2 {
  text-align: center;
}
@media (min-width: 1367px) and (max-width: 1600px) {
  section.blog {
    padding: 60px 0 100px;
  }
  section.blog .container {
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 20px;
  }
  section.blog .container .item {
    padding: 25px 0px 30px;
    margin: 0px 0px 0;
  }
  section.blog .container .item img {
    position: relative;
  }
  section.blog .container .item h4 {
    font-size: 20px;
    line-height: 28px;
  }
  section.blog .container .item p {
    margin-top: 15px !important;
  }
  section.blog .container .item span {
    font-size: 14px;
    bottom: 40px;
    left: 30px;
  }
}
@media (min-width: 1201px) and (max-width: 1366px) {
  section.blog .container, section.related-blog .container {
    gap: 60px 0px;
    margin-top: 0px;
    border-radius: 35px;
    padding-top: 50px;
  }
  section.blog .container .item .link, section.related-blog .container .item .link {
    padding: 40px 30px 70px;
    margin: 0px 20px 0;
  }
  section.blog .container .item .link img, section.related-blog .container .item .link img {
    position: relative;
  }
  section.blog .container .item .link h4, section.related-blog .container .item .link h4 {
    font-size: 22px;
    line-height: 32px;
  }
  section.blog .container .item .link p, section.related-blog .container .item .link p {
    margin-top: 20px !important;
  }
  section.blog .container .item .link span, section.related-blog .container .item .link span {
    font-size: 14px;
    bottom: 30px;
    left: 20px;
  }
  section.related-blog .container {
    margin-top: 0px;
  }
  section.related-blog .container-fluid h5 {
    font-size: 30px;
  }
  section.single-blog-details {
    padding: 150px 0 60px;
  }
  section.single-blog-details .container .content-full h2, section.single-blog-details .container-fluid .content-full h2 {
    text-align: center;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  section.blog .container {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 20px;
  }
  section.blog .container .item .link {
    margin: 0px 5px 0;
    padding: 40px 20px 50px;
  }
}
@media screen and (max-width: 1024px) {
  section.blog .container, section.related-blog .container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0px;
    padding-top: 0px;
    gap: 30px 0px;
  }
  section.blog .container .item .link, section.related-blog .container .item .link {
    padding: 30px 20px 30px;
    margin: 0px 10px 0px;
  }
  section.blog .container .item .link h4, section.related-blog .container .item .link h4 {
    font-size: 20px;
    line-height: 28px;
  }
  section.blog .container .item .link p, section.related-blog .container .item .link p {
    font-size: 17px;
    line-height: 28px;
  }
  section.blog .container .item .link span, section.related-blog .container .item .link span {
    bottom: 25px;
    left: 10px;
  }
  section.single-blog-details {
    padding: 100px 0 60px;
  }
  section.single-blog-details .container .content-full img, section.single-blog-details .container-fluid .content-full img {
    width: 100% !important;
    height: auto !important;
  }
}
@media (max-width: 992px) {
  section.blog .container, section.related-blog .container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0px;
    padding-top: 0px;
    gap: 30px 0px;
  }
  section.blog .container .item .link, section.related-blog .container .item .link {
    padding: 60px 20px 60px;
    margin: 0px 10px 0px;
  }
  section.blog .container .item .link h4, section.related-blog .container .item .link h4 {
    font-size: 20px;
    line-height: 28px;
  }
  section.blog .container .item .link p, section.related-blog .container .item .link p {
    line-height: 28px;
  }
  section.blog .container .item .link span, section.related-blog .container .item .link span {
    bottom: 55px;
    left: 20px;
  }
  section.single-blog-details {
    padding: 150px 0 60px;
  }
  section.single-blog-details .container .content-full img, section.single-blog-details .container-fluid .content-full img {
    width: 100% !important;
    height: auto !important;
    max-width: 90%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  section.blog .container, section.related-blog .container {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 20px;
  }
  section.blog .container .item .link, section.related-blog .container .item .link {
    padding: 60px 20px 60px;
    margin: 0;
  }
  section.blog .container .item .link h4, section.related-blog .container .item .link h4 {
    font-size: 18px;
    line-height: 24px;
  }
  section.blog .container .item .link p, section.related-blog .container .item .link p {
    font-size: 17px;
    line-height: 28px;
  }
  section.blog .container .item .link span, section.related-blog .container .item .link span {
    bottom: 60px;
    left: 20px;
  }
  section.single-blog-details .container .content-full img, section.single-blog-details .container-fluid .content-full img {
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  section.blog .container .item p, section.related-blog .container .item p {
    font-size: 16px;
    line-height: 28px;
  }
}/*# sourceMappingURL=blog.css.map */